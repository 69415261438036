import { Flex, Loader } from "@mantine/core";
import { PropsWithChildren, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserDetails } from "src/hooks/useUserDetails";
import { postUserEmailVerified } from "src/lib/api";
import { auth } from "src/lib/firebase";

export const AuthRequired: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, userIsLoading] = useAuthState(auth);

  const [userDetails, userDetailsLoading] = useUserDetails();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userIsLoading && !user?.uid) {
      if (location.pathname === "/") {
        navigate("/tasks");
      } else {
        navigate("/signup");
      }
    }
  }, [user, userIsLoading]);

  useEffect(() => {
    if (user?.emailVerified && !userDetails?.data()?.emailVerified) {
      postUserEmailVerified();
    }
  }, [user, userDetails]);

  if (userIsLoading || userDetailsLoading) {
    return (
      <Flex align="center" justify="center">
        <Loader />
      </Flex>
    );
  }

  return <>{children}</>;
};

export const AuthRequiredHoc = (component: any) => (
  <AuthRequired>{component}</AuthRequired>
);
