import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { postTaskReleasePayment } from "src/lib/api";
import { ModalType, useModalState } from "src/state/modal";

export const TaskReleasePaymentModal: React.FC = () => {
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.TaskReleasePayment]?.data;

  const { taskId } = data || {};

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    if (!taskId) {
      return;
    }
    setIsSubmitting(true);
    try {
      await postTaskReleasePayment({
        taskId,
      });
      closeModal(ModalType.TaskReleasePayment);
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      opened={!!modals[ModalType.TaskReleasePayment]?.opened}
      onClose={() => closeModal(ModalType.TaskReleasePayment)}
    >
      <Stack>
        <Text fw="bold">Confirm Payment Release </Text>
        <Text></Text>
        <Group spacing="xs">
          <Button loading={isSubmitting} color="teal" onClick={onSubmit}>
            Release Payment
          </Button>
          <Button
            disabled={isSubmitting}
            color="dark"
            variant="outline"
            onClick={() => closeModal(ModalType.TaskAcceptOffer)}
          >
            Cancel
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
