import { Badge } from "@mantine/core";
import { TaskStatus } from "@voluntasker/common";

type TaskStatusColor = {
  [key in TaskStatus]: string;
};

const taskStatus: TaskStatusColor = {
  [TaskStatus.Pending]: "cyan",
  [TaskStatus.Unassigned]: "blue",
  [TaskStatus.Assigned]: "blue",
  [TaskStatus.Completed]: "green",
  [TaskStatus.CancelRequest]: "red",
  [TaskStatus.Cancelled]: "red",
  [TaskStatus.Disputed]: "red",
  [TaskStatus.DisputeResolved]: "green",
  [TaskStatus.Deleted]: "red",
};

interface TaskStatusBadgeProps {
  status: TaskStatus;
}

export const TaskStatusBadge: React.FC<TaskStatusBadgeProps> = ({ status }) => (
  <Badge color={taskStatus[status]}>{status}</Badge>
);
