import { Loader, Table } from "@mantine/core";
import { FirestoreCollections, Payout } from "@voluntasker/common";
import {
  CollectionReference,
  collection,
  orderBy,
  query,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { DateText } from "src/components/DateText";
import { firestore } from "src/lib/firebase";

export const AdminPayoutList: React.FC = () => {
  const payoutsCol = collection(
    firestore,
    FirestoreCollections.Payouts
  ) as CollectionReference<Payout>;

  const payoutsQuery = query(payoutsCol, orderBy("createdOn", "desc"));

  const [payouts, payoutsLoading] = useCollectionData(payoutsQuery);

  if (payoutsLoading) {
    return <Loader />;
  }

  return (
    <Table withBorder striped>
      <thead>
        <tr>
          <th>Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {payouts?.map((payout) => {
          const amount = payout.stripePayout?.amount
            ? payout.stripePayout.amount / 100
            : 0;
          return (
            <tr key={payout.id}>
              <td>
                <DateText date={payout.createdOn} />
              </td>
              <td>${amount}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
