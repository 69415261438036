import { Button } from "@mantine/core";
import { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useUserDetails } from "src/hooks/useUserDetails";
import { postUserEmailVerify } from "src/lib/api";

interface UserEmailVerifyButtonProps {
  label?: string;
}

export const UserEmailVerifyButton: React.FC<UserEmailVerifyButtonProps> = ({
  label,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userDetails, userDetailsLoading] = useUserDetails();

  const [emailSent, setEmailSent] = useState<boolean>(false);

  const verifyEmail = async () => {
    setLoading(true);
    try {
      await postUserEmailVerify();
      setEmailSent(true);
    } catch (e) {
      console.log("err", e);
    }
    setLoading(false);
  };

  if (userDetails?.data()?.emailVerified === true) {
    return (
      <Button
        color="teal"
        style={{ pointerEvents: "none" }}
        variant="outline"
        leftIcon={<FaRegCheckCircle />}
      >
        Email Verified
      </Button>
    );
  }

  if (emailSent) {
    return (
      <Button leftIcon={<FaRegCheckCircle />} disabled variant="outline">
        Verification Email Sent
      </Button>
    );
  }

  return (
    <Button
      loading={loading || userDetailsLoading}
      onClick={verifyEmail}
      leftIcon={<FaRegCheckCircle />}
    >
      {label || "Verify Email"}
    </Button>
  );
};
