import { Flex, Group, Loader, Stack, Text } from "@mantine/core";
import { Task, TaskOffer } from "@voluntasker/common";
import {
  query,
  collection,
  orderBy,
  CollectionReference,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { FaSadCry } from "react-icons/fa";
import { TaskOfferListItem } from "src/components/task/TaskOfferListItem";
import { firestore } from "src/lib/firebase";

interface TaskOffersListProps {
  task: Task;
}

export const TaskOffersList: React.FC<TaskOffersListProps> = ({ task }) => {
  const taskOffersRef = query(
    collection(firestore, `/tasks/${task.id}/task-offers`),
    orderBy("createdBy", "asc")
  ) as CollectionReference<TaskOffer>;
  const [data, isLoading] = useCollection(taskOffersRef);

  if (isLoading) {
    return (
      <Flex justify="center" align="center">
        <Group spacing="xs">
          <Loader /> <Text>Loading Offers</Text>
        </Group>
      </Flex>
    );
  }

  if (!data?.size) {
    return (
      <Stack align="center" justify="center">
        <FaSadCry />
        <Text>No Offers Yet</Text>
      </Stack>
    );
  }

  return (
    <Stack>
      {data?.docs.map((offer) => (
        <TaskOfferListItem key={offer.id} task={task} offer={offer.data()} />
      ))}
    </Stack>
  );
};
