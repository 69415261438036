export const readBlobAsync = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Set up the onload and onerror event handlers
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);

    // Read the Blob as a data URL
    reader.readAsDataURL(blob);
  });
};
