import { collection, query, where } from "firebase/firestore";
import { TaskTable } from "src/components/task/TaskTable";
import { firestore } from "src/lib/firebase";

import { ActionIcon, Group, TableProps } from "@mantine/core";
import { Task, TaskStatus } from "@voluntasker/common";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";
import { ModalType, useModalState } from "src/state/modal";

interface OwnerTaskTableProps extends TableProps {
  userId: string;
}

export const OwnerTaskTable: React.FC<OwnerTaskTableProps> = ({
  userId,
  ...props
}) => {
  const { openModal } = useModalState();
  const tasksRef = collection(firestore, "tasks");
  const tasksQuery = query(tasksRef, where("ownerId", "==", userId));

  const [tasks, isLoading, error] = useCollection(tasksQuery);

  return (
    <TaskTable
      tasks={tasks?.docs.map((task) => task.data()) as Task[]}
      isLoading={isLoading}
      error={error ? String(error) : undefined}
      actions={(task) => (
        <Group spacing={"xs"}>
          <Link to={`/tasks/${task.id}`}>
            <ActionIcon color="aop" variant="outline">
              <FaEye />
            </ActionIcon>
          </Link>
          <ActionIcon
            disabled={task.status !== TaskStatus.Unassigned}
            color="red"
            variant="outline"
            onClick={() => openModal(ModalType.TaskDelete, { taskId: task.id })}
          >
            <FaTrashAlt />
          </ActionIcon>
        </Group>
      )}
      {...props}
    />
  );
};
