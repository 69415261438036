import {
  Card,
  Divider,
  Flex,
  Grid,
  Skeleton,
  Stack,
  Text,
} from "@mantine/core";
import { Task } from "@voluntasker/common";
import React, { useMemo } from "react";
import { FaDollarSign } from "react-icons/fa";
import { Link } from "react-router-dom";
import { UserProfileImage } from "src/components/user/UserProfileImage";
import { useProfile } from "src/hooks/useProfile";

interface TaskListItemProps {
  task: Task;
  selected?: boolean;
  onClick?: (task: Task) => void;
}

export const TaskListItem: React.FC<TaskListItemProps> = ({
  task,
  selected,
  onClick,
}) => {
  const [profile, profileLoading] = useProfile(task.createdBy);

  const profileData = useMemo(() => {
    return profile?.data();
  }, [profile]);

  return (
    <Card
      component={Link}
      to={`/tasks/${task.id}`}
      withBorder
      padding="lg"
      sx={(theme) => ({
        cursor: "pointer",
        transition: "100ms linear",
        borderColor: selected ? `${theme.colors.aop[6]} !important` : undefined,
        "&[data-with-border]:hover": {
          borderColor: `${theme.colors.aop[3]} !important`,
        },
      })}
      onClick={(e) => {
        e.preventDefault();
        onClick?.(task);
      }}
    >
      <Flex gap="md" mb="sm">
        <UserProfileImage profile={profileData} loading={profileLoading} />
        <Stack style={{ flex: 1 }} spacing={0}>
          <Text weight={500}>{task.title}</Text>
          <Skeleton animate visible={profileLoading} height={12}>
            <Text size="sm" color="dimmed">
              {profileData?.name}
            </Text>
          </Skeleton>
        </Stack>
      </Flex>
      <Stack>{task.desc}</Stack>
      <Divider my="md" />
      <Grid>
        <Grid.Col xs={4}>
          <Text size="sm" color="dimmed">
            {task.remote ? "Remote" : "In Person"}
          </Text>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col xs={4}>
          <Flex color="green" align="center">
            <Text size="xl" color="green">
              <FaDollarSign />
            </Text>
            <Text size="xl" color="green" pb="6px">
              {task.price}
            </Text>
          </Flex>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
