import { create } from "zustand";

export enum ModalType {
  AddCompanyUser = "AddCompanyUser",
  Verify = "Verify",
  ImageGallery = "ImageGallery",
  TaskOffer = "TaskOffer",
  TaskAcceptOffer = "TaskAcceptOffer",
  TaskCancel = "TaskCancel",
  TaskCancelConfirm = "TaskCancelConfirm",
  TaskRequestPayment = "TaskRequestPayment",
  TaskReleasePayment = "TaskReleasePayment",
  OnboardingVolunteer = "OnboardingVolunteer",
  TaskDelete = "TaskDelete",
  Messages = "Messages",
  DisputeResolve = "DisputeResolve",
  TermsModal = "TermsModal",
  CocModal = "CocModal",
}

type ModalOpenState = {
  [key in keyof typeof ModalType]?: {
    opened: boolean;
    data: any;
  };
};

type ModalState = {
  modals: ModalOpenState;
  openModal: (id: ModalType, data?: any) => void;
  closeModal: (id: ModalType, data?: any) => void;
};

export const useModalState = create<ModalState>((set) => ({
  modals: {},
  openModal: (id: ModalType, data?: any) =>
    set(() => ({ modals: { [id]: { opened: true, data } } })),
  closeModal: (id: ModalType, data?: any) =>
    set(() => ({ modals: { [id]: { opened: false, data } } })),
}));
