import React, { useCallback, useState } from "react";
import { Stepper, Button, Card, Container } from "@mantine/core";
import {
  PostTaskForm1Values,
  PostTaskForm2Values,
  PostTaskForm3Values,
  PostTaskFullArgs,
  TaskEndType,
  postTaskForm1Schema,
  postTaskForm2Schema,
  postTaskForm3Schema,
} from "@voluntasker/common";
import { useGetBreakpointValue } from "src/hooks/useBreakpoint";
import { postTaskFull } from "src/lib/api";
import { TaskCreateForm1 } from "src/components/task/TaskCreateForm1";
import { TaskCreateForm2 } from "src/components/task/TaskCreateForm2";
import { TaskCreateForm3 } from "src/components/task/TaskCreateForm3";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

interface TaskCreatePageProps {
  stripeIntentSecret?: string;
}

export const TaskCreatePage: React.FC<TaskCreatePageProps> = ({
  stripeIntentSecret,
}) => {
  const navigate = useNavigate();

  const [error, setError] = useState<Error | string | null | undefined>(null);
  const [loading, setLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const getBreakpointValue = useGetBreakpointValue();

  const stripe = useStripe();
  const elements = useElements();

  const form1 = useForm<PostTaskForm1Values>({
    defaultValues: {
      endType: TaskEndType.OnDate,
      endDate: 0,
    },
    resolver: yupResolver(postTaskForm1Schema),
  });

  const form2 = useForm<PostTaskForm2Values>({
    resolver: yupResolver(postTaskForm2Schema),
  });

  const form3 = useForm<PostTaskForm3Values>({
    resolver: yupResolver(postTaskForm3Schema),
  });

  const onSubmitPage = useCallback(async () => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const onSubmit = useCallback(
    async (form3Values: PostTaskForm3Values) => {
      setError(undefined);
      setLoading(true);
      const form1Values = form1.getValues();
      const form2Values = form2.getValues();
      try {
        const card = elements?.getElement(CardElement);

        let paymentMethodId;
        if (!form3Values.userPaymenMethodId) {
          if (card && stripe && stripeIntentSecret) {
            try {
              const setupResult = await stripe.confirmCardSetup(
                stripeIntentSecret,
                {
                  payment_method: {
                    card,
                  },
                }
              );
              paymentMethodId = setupResult?.setupIntent
                ?.payment_method as string;
            } catch (e) {
              setError(String(e));
            }
          }
        }

        const postTaskFullData: PostTaskFullArgs = {
          task: {
            ...form1Values,
            desc: form2Values.desc,
            endDate: form1Values.endDate,
            price: Number(form3Values.price),
          },
          payment: {
            name: form3Values.name,
          },
          stripePaymentMethodId: paymentMethodId,
          userPaymenMethodId: form3Values.userPaymenMethodId,
          imageRefs: form2Values.imageRefs,
        };
        const res = await postTaskFull(postTaskFullData);
        const taskId = res.data.id;

        navigate(`/tasks/${taskId}`);
      } catch (e) {
        const error = e as AxiosError;
        console.log(error);
        setError(String(error.response?.data) || error);
        setLoading(false);
      }
    },
    [currentStep]
  );

  return (
    <Container size="md">
      <Card p={getBreakpointValue([undefined, undefined, 48])} withBorder>
        <Stepper
          active={currentStep}
          styles={{
            root: {
              display: "flex",
              flexDirection: "column",
              "@media(min-width: 768px)": {
                alignItems: "center",
              },
            },
            steps: {
              "@media(min-width: 768px)": {
                minWidth: 600,
              },
            },
            step: {
              flexDirection: "column",
            },
            stepBody: {
              margin: 0,
              paddingTop: 8,
            },
            content: {
              width: "100%",
            },
          }}
        >
          <Stepper.Step
            label={getBreakpointValue([undefined, "Requirements"])}
            // description={getBreakpointValue([
            //   undefined,
            //   "Enter the task details",
            // ])}
          >
            <FormProvider {...form1}>
              <TaskCreateForm1
                onSubmit={onSubmitPage}
                submitText="Next"
                submitButtonProps={{
                  rightIcon: <FaChevronRight />,
                }}
                submitting={loading}
                error={error}
              />
            </FormProvider>
          </Stepper.Step>
          <Stepper.Step
            label={getBreakpointValue([undefined, "Details"])}
            // description={getBreakpointValue([
            //   undefined,
            //   "Submit payment for task",
            // ])}
          >
            <FormProvider {...form2}>
              <TaskCreateForm2
                onSubmit={onSubmitPage}
                leftButton={
                  <Button
                    size="md"
                    color="gray"
                    variant="light"
                    leftIcon={<FaChevronLeft />}
                    onClick={() => setCurrentStep(currentStep - 1)}
                  >
                    Back
                  </Button>
                }
                submitButtonProps={{
                  rightIcon: <FaChevronRight />,
                }}
                submitting={loading}
                error={error}
              />
            </FormProvider>
          </Stepper.Step>
          <Stepper.Step
            label={getBreakpointValue([undefined, "Budget"])}
            // description={getBreakpointValue([undefined, "Publish the task live"])}
          >
            <FormProvider {...form3}>
              <TaskCreateForm3
                onSubmit={onSubmit}
                leftButton={
                  <Button
                    size="md"
                    color="gray"
                    variant="light"
                    leftIcon={<FaChevronLeft />}
                    onClick={() => setCurrentStep(currentStep - 1)}
                  >
                    Back
                  </Button>
                }
                submitButtonProps={{
                  rightIcon: <FaChevronRight />,
                }}
                submitting={loading}
                error={error}
              />
            </FormProvider>
          </Stepper.Step>
        </Stepper>
      </Card>
    </Container>
  );
};
