import { Text, TextProps } from "@mantine/core";
import { dayjs } from "src/lib/dayjs";

interface DateTextProps extends TextProps {
  date: number | Date | undefined;
  format?: "dmy" | "dmyhms" | "humanize";
  fallback?: string;
}

export const DateText: React.FC<DateTextProps> = ({
  date,
  format,
  fallback = "Unknown",
  ...props
}) => {
  const dateText = date
    ? format === "humanize"
      ? dayjs.duration(dayjs(date).diff(), "milliseconds").humanize(true)
      : format === "dmyhms"
      ? dayjs(date).format("DD/MM/YYYY HH:mm:ss A")
      : dayjs(date).format("DD/MM/YYYY")
    : fallback;

  return <Text {...props}>{dateText}</Text>;
};
