import { Alert, Button, Stack, TextInput } from "@mantine/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { postUserResetPassword } from "src/lib/api";

export interface ResetPasswordFormValues {
  email: string;
}

export const ResetPasswordForm: React.FC = () => {
  const { register, handleSubmit } = useForm<ResetPasswordFormValues>();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [resetSent, setResetSent] = useState<boolean>(false);

  const _onSubmit = handleSubmit(async (values) => {
    const { email } = values;

    setSubmitting(true);
    try {
      await postUserResetPassword({ email });
      setResetSent(true);
    } catch (e) {
      setError(String(e));
    }
    setSubmitting(false);
  });

  return (
    <form onSubmit={_onSubmit}>
      <Stack>
        <TextInput
          label="Email"
          type="email"
          {...register("email")}
          disabled={submitting || resetSent}
        />
        {error && <Alert color="red">{String(error)}</Alert>}

        <Button
          loading={submitting}
          type="submit"
          disabled={resetSent}
          leftIcon={resetSent ? <FaCheck /> : undefined}
        >
          {resetSent ? "Email Sent" : "Reset Password"}
        </Button>
      </Stack>
    </form>
  );
};
