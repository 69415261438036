import { Query, collection, query, where } from "firebase/firestore";
import { firestore } from "src/lib/firebase";

import { Button, Card, Loader, Table, Text } from "@mantine/core";
import { useCollection } from "react-firebase-hooks/firestore";
import { User, UserRole, UserVolunteerStatus } from "@voluntasker/common";
import { AdminRoleTags } from "src/components/admin/AdminRoleTags";
import { useMap } from "react-use";
import { postAdminApproveVolunteer } from "src/lib/api";

export const AdminVolunteerTable: React.FC = () => {
  const userRef = collection(firestore, "users");
  const usersQuery = query(
    userRef,
    where("roles", "array-contains", "volunteer"),
    where("volunteerStatus", "==", UserVolunteerStatus.Pending)
  );

  const [users, isLoading] = useCollection(usersQuery as Query<User>);

  const [updatingUsers, { set: setUpdatingUsers }] = useMap();
  const onApproveUser = async (userId: string) => {
    setUpdatingUsers(userId, true);
    try {
      await postAdminApproveVolunteer({ userId });
    } catch (e) {
      console.log(e);
    }
    setUpdatingUsers(userId, false);
  };

  if (isLoading) {
    return <Loader />;
  }

  const filteredUsers = users?.docs.filter((user) => !!user.data()?.wwcNumber);

  return (
    <Card shadow="lg" padding={0}>
      <Table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Roles</th>
            <th>WWC State</th>
            <th>WWC Number</th>
            <th align="right">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers?.length ? (
            filteredUsers?.map((user) => (
              <tr key={user.id}>
                <td>{user.data()?.partialEmail}</td>
                <td>
                  {(user.data()?.firstName || "") +
                    " " +
                    (user.data()?.lastName || "")}
                </td>
                <td>
                  <AdminRoleTags roles={[UserRole.Volunteer]} />
                </td>
                <td>
                  {user.data()?.wwcState || <Text color="red">Unset</Text>}
                </td>
                <td>
                  {user.data()?.wwcNumber || <Text color="red">Unset</Text>}
                </td>
                <td>
                  <Button
                    loading={updatingUsers[user.id]}
                    color="teal"
                    size="xs"
                    disabled={
                      user.data()?.volunteerStatus ===
                      UserVolunteerStatus.Approved
                    }
                    onClick={() => onApproveUser(user.id)}
                  >
                    {user.data()?.volunteerStatus ===
                    UserVolunteerStatus.Approved
                      ? "Approved"
                      : "Approve"}
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <Text align="center">No pending volunteers found</Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
  );
};
