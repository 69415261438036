import { ActionIcon, Tooltip } from "@mantine/core";
import { User } from "@voluntasker/common";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { FaIntercom } from "react-icons/fa";
import { Link } from "react-router-dom";
import { firestore } from "src/lib/firebase";

interface AdminIntercomActionIconProps {
  userId?: string;
  intercomContactId?: string;
  conversationId?: string;
}

export const AdminIntercomActionIcon: React.FC<
  AdminIntercomActionIconProps
> = ({ userId, intercomContactId, conversationId }) => {
  const [url, setUrl] = useState<string>(
    `https://app.intercom.com/a/inbox/l5x2g68f/inbox/shared/all/conversation/`
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    (async () => {
      let _intercomContactId;
      if (conversationId) {
        setUrl(
          `https://app.intercom.com/a/inbox/l5x2g68f/inbox/admin/6988757/conversation/${conversationId}`
        );
      } else if (intercomContactId) {
        _intercomContactId = intercomContactId;
      } else if (userId) {
        const userRef = doc(firestore, "users", userId);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data() as User;
        _intercomContactId = userData.intercomContactId;
      }
      if (_intercomContactId) {
        setUrl(
          `https://app.intercom.com/a/apps/l5x2g68f/users/${_intercomContactId}/all-conversations`
        );
      }
      setLoading(false);
    })();
  }, [userId, intercomContactId]);

  return (
    <Tooltip withArrow label="Open In Intercom">
      <ActionIcon
        loading={loading}
        component={Link}
        color="aop"
        variant="outline"
        to={url}
        target="_blank"
      >
        <FaIntercom />
      </ActionIcon>
    </Tooltip>
  );
};
