import { AllocationCategory } from "@voluntasker/common";

export const allocationCategories: AllocationCategory[] = [
  {
    id: "hawb",
    title: "Health and Well-being",
    color: "#cd6441",
  },
  {
    id: "lae",
    title: "Learning and Empowerment",
    color: "#2a3768",
  },
  {
    id: "sl",
    title: "Sustainable Living",
    color: "#2b2962",
  },
];

export const getAllocationTitle = (id: string) => {
  return allocationCategories.find((c) => c.id === id)?.title;
};
