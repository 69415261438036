import { MessageList } from "src/components/message/MessageList";

import { Flex, Group, Loader, Stack, Text } from "@mantine/core";
import { PostTaskMessageValues, ThreadMessage } from "@voluntasker/common";
import {
  CollectionReference,
  collection,
  orderBy,
  query,
} from "firebase/firestore";
import { firestore } from "src/lib/firebase";
import { MessageForm } from "src/components/message/MessageForm";
import { useState } from "react";
import { postThreadMessage } from "src/lib/api";
import { useCollection } from "react-firebase-hooks/firestore";
import { AxiosError } from "axios";

interface MessageBoxProps {
  showName?: boolean;
  threadId: string;
  taskId?: string;
  showForm?: boolean;
  primaryUserId?: string;
}

export const MessageBox: React.FC<MessageBoxProps> = ({
  threadId,
  taskId,
  showForm = true,
  primaryUserId,
  showName,
}) => {
  const threadMessagesRef = query(
    collection(firestore, `/threads/${threadId}/thread-messages`),
    orderBy("createdOn", "asc")
  ) as CollectionReference<ThreadMessage>;
  const [data, isLoading] = useCollection(threadMessagesRef);

  const [error, setError] = useState<Error | string | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (message: PostTaskMessageValues) => {
    setLoading(true);
    try {
      await postThreadMessage({ message, threadId, taskId });
      setError(null);
    } catch (e) {
      const error = e as AxiosError;
      const errorMessage = error.response?.data
        ? String(error.response?.data)
        : error;
      setError(errorMessage);
    }
    setLoading(false);
  };

  return (
    <Stack sx={{ overflow: "hidden" }}>
      {isLoading ? (
        <Flex justify="center" align="center">
          <Group spacing="xs">
            <Loader /> <Text>Loading Messages</Text>
          </Group>
        </Flex>
      ) : data?.docs.length ? (
        <MessageList
          showName={showName}
          messages={data?.docs.map((x) => x.data())}
          primaryUserId={primaryUserId}
        />
      ) : (
        <Text>No Messages Found</Text>
      )}
      {showForm && (
        <MessageForm error={error} submitting={loading} onSubmit={onSubmit} />
      )}
    </Stack>
  );
};
