import {
  Card,
  Group,
  Anchor,
  Divider,
  Text,
  useMantineTheme,
  Input,
  Stack,
} from "@mantine/core";
import { TaskListFilterValues } from "@voluntasker/common";
import { Controller, useForm } from "react-hook-form";
import { TaskFilterSelect } from "src/components/task/TaskFilterLocationSelect";

interface TaskFiltersCardProps {
  onChange: (values: TaskListFilterValues) => void;
}

export const TaskFiltersCard: React.FC<TaskFiltersCardProps> = ({
  onChange,
}) => {
  const { register, watch, control, setValue } =
    useForm<TaskListFilterValues>();

  // Update parent with filters
  watch(onChange);

  const theme = useMantineTheme();
  return (
    <Card withBorder>
      <Group>
        <Text sx={{ flex: 1 }} fw="bold">
          Filters
        </Text>
        <Anchor color="red" size="sm">
          Reset Filters
        </Anchor>
      </Group>
      <Card.Section my="md">
        <Divider color={theme.colors.gray[2]} />
      </Card.Section>
      <Stack>
        <Text fw={500}>Locations</Text>
        <Controller
          control={control}
          name="location"
          render={({ field: { value, name } }) => (
            <TaskFilterSelect
              value={value}
              onChange={(value) => value && setValue(name, value)}
            />
          )}
        />
      </Stack>
      <Card.Section my="md">
        <Divider color={theme.colors.gray[2]} />
      </Card.Section>
      <Stack>
        <Text fw={500}>Task Price</Text>
        <Group>
          <Input sx={{ flex: 1 }} placeholder="Min" {...register("minPrice")} />
          <Text>-</Text>
          <Input sx={{ flex: 1 }} placeholder="Max" {...register("maxPrice")} />
        </Group>
      </Stack>
      <Card.Section my="md">
        <Divider color={theme.colors.gray[2]} />
      </Card.Section>
      {/* <Stack>
        <Text fw={500}>Preferences</Text>
        <Stack spacing="xs">
          <Checkbox label="Remote" {...register("remote")} />
          <Checkbox label="In person" {...register("inPerson")} />
        </Stack>
      </Stack> */}
    </Card>
  );
};
