import {
  Badge,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Skeleton,
  Stack,
  Text,
} from "@mantine/core";
import {
  Task,
  TaskOffer,
  TaskOfferStatus,
  TaskStatus,
} from "@voluntasker/common";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaCheck } from "react-icons/fa";
import { UserProfileImage } from "src/components/user/UserProfileImage";
import { useProfile } from "src/hooks/useProfile";
import { auth } from "src/lib/firebase";
import { ModalType, useModalState } from "src/state/modal";

interface TaskOfferListItemProps {
  task: Task;
  offer: TaskOffer;
}

export const TaskOfferListItem: React.FC<TaskOfferListItemProps> = ({
  task,
  offer,
}) => {
  const [user] = useAuthState(auth);
  const [profile, profileLoading] = useProfile(offer.createdBy);

  const profileData = profile?.data();

  const { openModal } = useModalState();

  return (
    <Card shadow="sm">
      <Stack>
        <Group position="apart" align="start">
          <Flex gap="xl">
            <Skeleton visible={profileLoading} circle height={38}>
              {profileData && (
                <UserProfileImage
                  profile={profileData}
                  loading={profileLoading}
                />
              )}
            </Skeleton>
            <Stack style={{ flex: 1 }} spacing={0}>
              <Text size="md" fw="bold">
                {profileData?.name}
              </Text>
              {offer.message || "No Message"}
            </Stack>
          </Flex>
          {offer.status === TaskOfferStatus.Accepted && (
            <Badge color="teal" variant="filled">
              Assigned
            </Badge>
          )}
        </Group>
        {task.createdBy === user?.uid && (
          <Stack>
            <Divider />
            <Text size="sm" fw="bold">
              Offer:
            </Text>
            <Group>
              <Text size="xl" color="aop" fw="bold">
                ${offer.price}
              </Text>
              <Button
                disabled={task.status !== TaskStatus.Unassigned}
                color={
                  offer.status === TaskOfferStatus.Accepted ? "teal" : "aop"
                }
                variant={
                  offer.status === TaskOfferStatus.Accepted
                    ? "outline"
                    : "filled"
                }
                leftIcon={<FaCheck />}
                onClick={() =>
                  offer.status !== TaskOfferStatus.Accepted &&
                  openModal(ModalType.TaskAcceptOffer, { offer, task })
                }
              >
                Accept Offer
              </Button>
            </Group>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
