import { useFormContext } from "react-hook-form";
import {
  TextInput,
  Button,
  Stack,
  Alert,
  Input,
  Group,
  Box,
  Text,
  Grid,
  Checkbox,
  Select,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

import {
  PostTaskForm1Values,
  PostTaskLocationArgs,
  TaskEndType,
  postTaskSchema,
  schemaFieldRequired,
} from "@voluntasker/common";
import { FormComponentProps } from "src/lib/types";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LocationAutocomplete } from "src/components/LocationAutocomplete";

export interface TaskCreateForm1Props
  extends FormComponentProps<PostTaskForm1Values> {
  leftButton?: React.ReactNode;
}

export const TaskCreateForm1: React.FC<TaskCreateForm1Props> = ({
  leftButton,
  submitButtonProps,
  submitting,
  error,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useFormContext<PostTaskForm1Values>();

  const formData = watch();

  const _onSubmit = handleSubmit((values) => {
    onSubmit?.(values);
  });

  const onPlaceSelected = (place: google.maps.places.PlaceResult) => {
    if (!place) {
      return;
    }
    const address = place.formatted_address;
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    if (lat && lng) {
      setValue("lat", lat);
      setValue("lng", lng);
    }
    if (address) {
      setValue("address", address);
    }
    let location: PostTaskLocationArgs = {};
    for (const component of place.address_components || []) {
      if (
        component.types.includes("political") ||
        component.types.includes("postal_code")
      ) {
        location[component.types[0]] = {
          long_name: component.long_name,
          short_name: component.short_name,
        };
      }
    }
    setValue("location", location);
    setError("location", {});
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={_onSubmit} onKeyDown={onKeyDown}>
      <Grid gutter="xl">
        <Grid.Col xs={12} md={7}>
          <Stack py="xl">
            <Text weight={700} size={24}>
              Let's get your task posted
            </Text>
            <Text color="dimmed">
              Ignite change by posting a task! Pen a catchy 'Task Title',
              pinpoint your 'Completion Dates', and map out the 'Task Location'.
              Will kids be involved? A valid Working With Children Check is
              vital for volunteers. It's not just a task; it's a call to
              kind-hearted adventurers ready to turn goodwill into action. Ready
              to spark a benevolent quest?
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col xs={12} md={7}>
          <Input.Wrapper
            size="lg"
            label="Task Title"
            description="Describe your task"
            withAsterisk={schemaFieldRequired(postTaskSchema, "title")}
          >
            <TextInput
              disabled={submitting}
              error={errors.title?.message}
              placeholder="Enter a task title"
              {...register("title")}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col xs={12} md={7}>
          <Input.Wrapper
            size="lg"
            label="Task Location"
            description="Where does your task need to be completed?"
            error={errors.location?.message}
            withAsterisk={schemaFieldRequired(postTaskSchema, "location")}
          >
            <LocationAutocomplete
              error={errors.location?.message}
              disabled={formData?.remote}
              onChange={onPlaceSelected}
            />
          </Input.Wrapper>
        </Grid.Col>

        {/* <Grid.Col xs={12}>
          <Checkbox
            disabled={submitting}
            size="md"
            label="This task can be completed remotely"
            error={errors.remote?.message}
            {...register("remote")}
          />
        </Grid.Col> */}

        <Grid.Col xs={12}>
          <Checkbox
            size="md"
            label="This task involves working with children"
            {...register("wwcRequired")}
          />
        </Grid.Col>

        <Grid.Col xs={12}>
          <Input.Wrapper
            size="lg"
            label="Completion Date"
            description="When does your task need to be done by?"
            withAsterisk={schemaFieldRequired(postTaskSchema, "endType")}
          >
            <Grid>
              <Grid.Col xs={12} md={6}>
                <Select
                  size="md"
                  defaultValue={TaskEndType.OnDate}
                  data={[
                    { value: TaskEndType.Anytime, label: "Anytime" },
                    { value: TaskEndType.OnDate, label: "On Date" },
                    { value: TaskEndType.BeforeDate, label: "Before Date" },
                  ]}
                  disabled={submitting}
                  error={errors.endType?.message}
                  placeholder="Enter a completion type"
                  {...register("endType")}
                  onChange={(value: TaskEndType) => {
                    value && setValue("endType", value);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={6}>
                <DatePickerInput
                  clearable
                  size="md"
                  icon={<FaRegCalendarAlt />}
                  disabled={
                    submitting || formData.endType === TaskEndType.Anytime
                  }
                  minDate={new Date()}
                  placeholder="Enter a completion date"
                  error={errors.endDate?.message}
                  {...register("endDate")}
                  onChange={(date) => {
                    date && setValue("endDate", date.getTime());
                  }}
                />
              </Grid.Col>
            </Grid>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col xs={12}>
          {error && (
            <Alert color="red" variant="filled">
              {String(error)}
            </Alert>
          )}
        </Grid.Col>

        <Grid.Col xs={12}>
          <Group position="apart">
            {leftButton || <Box />}
            <Button
              size="md"
              loading={submitting}
              type="submit"
              {...submitButtonProps}
            >
              Next
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  );
};
