import { Skeleton, Stack } from "@mantine/core";
import { FaUserCircle } from "react-icons/fa";
import { UserProfileImage } from "src/components/user/UserProfileImage";
import { useProfile } from "src/hooks/useProfile";

const ICON_HEIGHT = 40;

interface UserProfileAvatarProps {
  userId?: string;
}

export const UserProfileAvatar: React.FC<UserProfileAvatarProps> = ({
  userId,
}) => {
  const [profile, profileLoading] = useProfile(userId || "unknown");

  return (
    <Skeleton circle animate height={ICON_HEIGHT} visible={profileLoading}>
      {profile?.data()?.imageRef ? (
        <UserProfileImage
          profile={profile.data()}
          size={ICON_HEIGHT}
          loading={profileLoading}
        />
      ) : profile?.data()?.profileInitials ? (
        <Stack
          align="center"
          justify="center"
          h={ICON_HEIGHT}
          w={ICON_HEIGHT}
          sx={{ borderRadius: ICON_HEIGHT }}
          bg="aop"
          c="white"
        >
          {profile?.data()?.profileInitials}
        </Stack>
      ) : (
        <FaUserCircle size={ICON_HEIGHT} color="aop" />
      )}
    </Skeleton>
  );
};
