import { Alert, Button, Stack, TextInput } from "@mantine/core";
import { useForm } from "react-hook-form";
import { prepareFirebaseError } from "src/lib/firebase";
import { FormComponentProps } from "src/lib/types";

export interface EmailPasswordFormValues {
  email: string;
  password: string;
}

interface EmailPasswordFormProps
  extends FormComponentProps<EmailPasswordFormValues> {}

export const EmailPasswordForm: React.FC<EmailPasswordFormProps> = ({
  error,
  submitText,
  submitting,
  onSubmit,
}) => {
  const { register, handleSubmit } = useForm<EmailPasswordFormValues>();

  const _onSubmit = handleSubmit((values) => {
    onSubmit?.(values);
  });

  return (
    <form onSubmit={_onSubmit}>
      <Stack>
        <TextInput
          label="Email"
          type="email"
          {...register("email")}
          disabled={submitting}
        />
        <TextInput
          label="Password"
          type="password"
          {...register("password")}
          disabled={submitting}
        />
        {error && <Alert color="red">{prepareFirebaseError(error)}</Alert>}

        <Button loading={submitting} type="submit">
          {submitText || "Submit"}
        </Button>
      </Stack>
    </form>
  );
};
