import {
  Group,
  Input,
  MultiSelect,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { PostOnboardingValues } from "@voluntasker/common";
import { Controller, UseFormReturn } from "react-hook-form";

interface OnboardingIndividualFormProps {
  form: UseFormReturn<PostOnboardingValues>;
  disabled?: boolean;
}

export const OnboardingIndividualForm: React.FC<
  OnboardingIndividualFormProps
> = ({ form, disabled }) => {
  return (
    <Stack>
      <Stack spacing={0} pb={24}>
        <Title>Complete your profile</Title>
        <Text color="dimmed">
          Tell us a little bit about yourself and add a profile picture
        </Text>
      </Stack>

      <Group grow>
        <Input.Wrapper
          label="First Name"
          error={form.formState.errors.firstName?.message}
          withAsterisk
        >
          <Input
            disabled={disabled}
            error={form.formState.errors.firstName?.message}
            {...form.register("firstName")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          label="Last Name"
          error={form.formState.errors.lastName?.message}
          withAsterisk
        >
          <Input
            disabled={disabled}
            error={form.formState.errors.lastName?.message}
            {...form.register("lastName")}
          />
        </Input.Wrapper>
      </Group>
      <Input.Wrapper label="Location" withAsterisk>
        <Controller
          control={form.control}
          name="location"
          render={({ field: { onChange, ...fieldProps } }) => (
            <Select
              onChange={(value) => value && onChange(value)}
              error={form.formState.errors.location?.message}
              {...fieldProps}
              data={[
                {
                  value: "qld",
                  label: "Queensland",
                },
                {
                  value: "nsw",
                  label: "New South Wales",
                },
                {
                  value: "vic",
                  label: "Victoria",
                },
                {
                  value: "sa",
                  label: "South Australia",
                },
                {
                  value: "wa",
                  label: "Western Australia",
                },
                {
                  value: "tas",
                  label: "Tasmania",
                },
                {
                  value: "nt",
                  label: "Northern Territory",
                },
                {
                  value: "act",
                  label: "Australian Capital Territory",
                },
              ]}
            />
          )}
        />
      </Input.Wrapper>

      <Input.Wrapper label="What skills do you have?" withAsterisk>
        <Controller
          control={form.control}
          name="skills"
          render={({ field }) => (
            <MultiSelect
              searchable
              placeholder="Pick tag from list"
              data={["Programming", "Painting", "Maths", "History"]}
              {...field}
            />
          )}
        />
      </Input.Wrapper>
    </Stack>
  );
};
