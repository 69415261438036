import { Group, Loader, Stack, Text } from "@mantine/core";
import { FirestoreCollections, UserPaymentMethod } from "@voluntasker/common";
import {
  CollectionReference,
  collection,
  orderBy,
  query,
} from "firebase/firestore";
import { useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { UserPaymentMethodCard } from "src/components/user/UserPaymentMethodCard";
import { firestore } from "src/lib/firebase";

interface UserPaymentMethodsProps {
  userId: string;
  paymentMethodId?: string;
  selectable?: boolean;
  onChange?: (userPaymentMethodId: string) => void | Promise<void>;
}

export const UserPaymentMethods: React.FC<UserPaymentMethodsProps> = ({
  userId,
  paymentMethodId,
  selectable,
  onChange,
}) => {
  const userPaymentsRef = collection(
    firestore,
    `${FirestoreCollections.Users}/${userId}/${FirestoreCollections.UserPaymentMethods}`
  ) as CollectionReference<UserPaymentMethod>;

  const queryRef = query(userPaymentsRef, orderBy("createdOn", "desc"));
  const [userPaymentMethods, userPaymentMethodsLoading] =
    useCollectionData(queryRef);

  useEffect(() => {
    if (userPaymentMethods && userPaymentMethods.length > 0) {
      onChange?.(userPaymentMethods[0].paymentMethodId);
    }
  }, [userPaymentMethods]);

  if (userPaymentMethodsLoading) {
    return (
      <Group>
        <Loader />
        <Text>Loading Payment Methods</Text>
      </Group>
    );
  }

  return (
    <Stack spacing="xs">
      {userPaymentMethods?.map((userPaymentMethod) => (
        <Stack>
          <UserPaymentMethodCard
            key={userPaymentMethod.id}
            onClick={onChange}
            selectable={selectable}
            userPaymentMethod={userPaymentMethod}
            selected={paymentMethodId === userPaymentMethod.paymentMethodId}
          />
        </Stack>
      ))}
    </Stack>
  );
};
