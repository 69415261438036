import { Flex, Group, Loader, Stack, Text } from "@mantine/core";
import { query, collection, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { FaSadCry } from "react-icons/fa";
import { firestore } from "src/lib/firebase";

interface TaskMessageThreadsProps {
  taskId: string;
}

export const TaskMessageThreads: React.FC<TaskMessageThreadsProps> = ({
  taskId,
}) => {
  const taskThreadsRef = query(
    collection(firestore, `/tasks/${taskId}/task-threads`),
    orderBy("createdBy", "asc")
  );
  const [data, isLoading] = useCollection(taskThreadsRef);

  if (isLoading) {
    return (
      <Flex justify="center" align="center">
        <Group spacing="xs">
          <Loader /> <Text>Loading Threads</Text>
        </Group>
      </Flex>
    );
  }

  if (!data?.size) {
    return (
      <Stack align="center" justify="center">
        <FaSadCry />
        <Text>No Threads Yet</Text>
      </Stack>
    );
  }

  return (
    <Stack>
      {data?.docs.map((thread) => (
        <Stack key={thread.id}>{thread.id}</Stack>
      ))}
    </Stack>
  );
};
