import { Modal, Stack, Text } from "@mantine/core";
import { ModalType, useModalState } from "src/state/modal";

export const CocModal: React.FC = () => {
  const { modals, closeModal } = useModalState();

  return (
    <Modal
      title="Code of Conduct"
      opened={!!modals[ModalType.CocModal]?.opened}
      onClose={() => closeModal(ModalType.CocModal)}
      size="lg"
    >
      <Stack>
        <Text>
          Welcome to VolunTasker! We appreciate your commitment to making a
          positive impact through volunteering. To ensure a respectful and
          professional environment, please adhere to the following code of
          conduct:
        </Text>

        <Text fw="bold">Respect for Rights and Dignity:</Text>
        <ul>
          <li>
            Treat all individuals with respect and dignity, regardless of their
            background, beliefs, or circumstances.
          </li>
          <li>
            Acknowledge and value the diverse perspectives and experiences of
            others.
          </li>
        </ul>

        <Text fw="bold">Honesty and Professionalism:</Text>

        <ul>
          <li>Act honestly and professionally in all interactions.</li>
          <li>
            Be transparent about your skills, availability, and limitations when
            taking on tasks.
          </li>
        </ul>

        <Text fw="bold">Confidentiality and Privacy:</Text>
        <ul>
          <li>
            Maintain the confidentiality and privacy of information shared
            during tasks.
          </li>
          <li>
            Respect the privacy of individuals involved and refrain from
            disclosing sensitive details without explicit consent.
          </li>
        </ul>

        <Text fw="bold">Compliance with Laws and Regulations:</Text>
        <ul>
          <li>
            Abide by all applicable laws and regulations while performing tasks.
          </li>
          <li>
            Ensure that your actions align with legal requirements and ethical
            standards.
          </li>
        </ul>

        <Text fw="bold">Safeguarding Children and Vulnerable Adults:</Text>
        <ul>
          <li>
            Prioritise the safety and well-being of children and vulnerable
            adults.
          </li>
          <li>
            Always ensure another adult is present when working in the presence
            of children.
          </li>
          <li>
            Refrain from engaging in any behaviour that may pose a risk to the
            safety of children or vulnerable adults.
          </li>
        </ul>

        <Text fw="bold">Professional Communication:</Text>
        <ul>
          <li>Communicate in a respectful and non-abusive manner.</li>
          <li>
            Avoid using offensive language or engaging in behaviour that could
            be perceived as harassment.
          </li>
          <li>Speak in a professional manner during all interactions.</li>
          <li>
            Use clear and constructive language when communicating with other
            volunteers and task posters.
          </li>
        </ul>

        <Text fw="bold">Consent for Photos and Videos:</Text>
        <ul>
          <li>
            Do not take photos and videos that are not directly related to the
            work being conducted.
          </li>
          <li>
            Obtain explicit consent before taking photos or videos of
            individuals, private property, or any identifiable details.
          </li>
          <li>
            Never capture photos or videos of children without the explicit
            consent of their parents or guardians
          </li>
        </ul>

        <Text fw="bold">Reporting Breaches:</Text>
        <ul>
          <li>
            Promptly report any breaches of this code of conduct or any
            inappropriate behaviour to the VolunTasker administrators.
          </li>
          <li>
            By participating in VolunTasker, you agree to uphold this code of
            conduct. Thank you for your dedication to making a positive impact
            in our community!
          </li>
        </ul>
      </Stack>
    </Modal>
  );
};
