import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Input,
  Modal,
  MultiSelect,
  Stack,
  TextInput,
} from "@mantine/core";
import {
  PostAdminCompanyValues,
  postAdminCompanySchema,
  domainRegex,
} from "@voluntasker/common";
import { AxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaUserPlus } from "react-icons/fa";
import { postAdminCompany } from "src/lib/api";
import { ModalType, useModalState } from "src/state/modal";

export const AdminCompanyUserModal = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<PostAdminCompanyValues>({
    resolver: yupResolver(postAdminCompanySchema),
  });

  const { modals, closeModal } = useModalState();
  const onClose = () => closeModal(ModalType.AddCompanyUser);

  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();

  const _onSubmit = handleSubmit(async (values) => {
    setSaving(true);
    try {
      await postAdminCompany({ ...values });
      setError(undefined);
      reset();
      onClose();
    } catch (e) {
      const err = e as AxiosError;
      setError(err);
    }
    setSaving(false);
  });

  return (
    <Modal
      opened={!!modals[ModalType.AddCompanyUser]?.opened}
      onClose={onClose}
      title="Add Company User"
    >
      <form onSubmit={_onSubmit}>
        <Stack spacing="sm">
          <Input.Wrapper required label="Company Name">
            <TextInput
              disabled={saving}
              error={errors["name"]?.message}
              placeholder="Enter Name of the Company"
              {...register("name")}
            />
          </Input.Wrapper>

          <Input.Wrapper required label="Admin User Email">
            <TextInput
              disabled={saving}
              error={errors["email"]?.message}
              placeholder="Enter User's Email"
              {...register("email")}
            />
          </Input.Wrapper>

          <Input.Wrapper required label="Whitelist Domains">
            <MultiSelect
              disabled={saving}
              error={errors["domains"]?.message}
              withinPortal
              searchable
              creatable
              data={[]}
              getCreateLabel={(query) => `+ Add ${query}`}
              shouldCreate={(query) => domainRegex.test(query)}
              placeholder="Enter one or more domains"
              {...register("domains")}
              onChange={(value) => setValue("domains", value)}
            />
          </Input.Wrapper>

          {error && <Alert color="red">{String(error)}</Alert>}

          <Button loading={saving} leftIcon={<FaUserPlus />} type="submit">
            Add User
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};
