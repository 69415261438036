import { Alert, LoadingOverlay, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { ElementsProvider } from "src/components/ElementsProvider";
import { TaskCreatePage } from "src/components/task/TaskCreatePage";
import { useUserDetails } from "src/hooks/useUserDetails";
import { getStripeIntent } from "src/lib/api";

export const TaskCreateWrapper: React.FC = () => {
  const [userDetails, userLoading] = useUserDetails();
  const [stripeIntent, setStripeIntent] = useState<string>();
  const [stripeIntentLoading, setStripeIntentLoading] =
    useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    (async () => {
      setStripeIntentLoading(true);
      try {
        const response = await getStripeIntent();
        setStripeIntent(response.data);
        setError(undefined);
      } catch (e) {
        console.log(e);
        setError(e as Error);
      }
      setStripeIntentLoading(false);
    })();
  }, []);

  const canCreateTask = userDetails?.data()?.identityVerified === "verified";
  // && userDetails?.data()?.emailVerified === true;

  return (
    <ElementsProvider
      options={{
        clientSecret: stripeIntent,
      }}
    >
      <Stack>
        {error && (
          <Alert color="red" variant="filled">
            {String(error)}
          </Alert>
        )}
        {!canCreateTask ? (
          <Stack>
            <Text>User not verified</Text>
          </Stack>
        ) : (
          <TaskCreatePage stripeIntentSecret={stripeIntent} />
        )}
        <LoadingOverlay visible={stripeIntentLoading || userLoading} />
      </Stack>
    </ElementsProvider>
  );
};
