import { PropsWithChildren } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PK);

type ElementsProviderProps = PropsWithChildren<{
  options?: StripeElementsOptions;
}>;

export const ElementsProvider: React.FC<ElementsProviderProps> = ({
  children,
}) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};
