import { Box, Loader, Stack, Tabs } from "@mantine/core";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { AdminDisputeTable } from "src/components/admin/AdminDisputeTable";
import { AdminFinancialsTab } from "src/components/admin/AdminFinancialsTab";
import { AdminTaskTable } from "src/components/admin/AdminTaskTable";
import { AdminUsersTab } from "./AdminUsersTab";
import { AdminVolunteerTable } from "src/components/admin/AdminVolunteerTable";
import { useIsAdmin } from "src/hooks/useIsAdmin";
import { auth } from "src/lib/firebase";
import { AdminCompaniesTab } from "src/components/admin/AdminCompaniesTab";

export const AdminPage: React.FC = () => {
  const [_, isLoading] = useAuthState(auth);

  const { isAdmin, isAdminLoading } = useIsAdmin();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!isAdmin && !isAdminLoading) {
        navigate("/");
      }
    })();
  }, [isAdmin, isAdminLoading]);

  if (isLoading || isAdminLoading) {
    return <Loader />;
  }

  return (
    <>
      <Stack>
        <Tabs defaultValue="volunteers">
          <Tabs.List>
            <Tabs.Tab value="volunteers">Volunteer Approvals</Tabs.Tab>
            <Tabs.Tab value="tasks">Task Management</Tabs.Tab>
            <Tabs.Tab value="disputes">Dispute Management</Tabs.Tab>
            <Tabs.Tab value="company">Company Management</Tabs.Tab>
            <Tabs.Tab value="users">User Management</Tabs.Tab>
            <Tabs.Tab value="financials">Financial Management</Tabs.Tab>
          </Tabs.List>
          <Box py="md">
            <Tabs.Panel value="volunteers">
              <AdminVolunteerTable />
            </Tabs.Panel>
            <Tabs.Panel value="tasks">
              <AdminTaskTable />
            </Tabs.Panel>
            <Tabs.Panel value="disputes">
              <AdminDisputeTable />
            </Tabs.Panel>
            <Tabs.Panel value="company">
              <AdminCompaniesTab />
            </Tabs.Panel>
            <Tabs.Panel value="users">
              <AdminUsersTab />
            </Tabs.Panel>
            <Tabs.Panel value="financials">
              <AdminFinancialsTab />
            </Tabs.Panel>
          </Box>
        </Tabs>
      </Stack>
    </>
  );
};
