import { Card, Loader, Stack } from "@mantine/core";
import { ref } from "firebase/storage";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { storage } from "src/lib/firebase";

interface StorageImageProps extends React.HTMLAttributes<HTMLImageElement> {
  storageRef: string;
  height?: number | string;
  width?: number | string;
}

export const StorageImage: React.FC<StorageImageProps> = ({
  height = 48,
  width = 48,
  storageRef,
  style,
  ...props
}) => {
  const [url, loading] = useDownloadURL(ref(storage, storageRef));
  if (loading) {
    return (
      <Card h={height} w={width} withBorder p="md" radius="50%">
        <Stack align="center" justify="center" h="100%">
          <Loader size={16} />
        </Stack>
      </Card>
    );
  }
  return (
    <img
      {...props}
      height={height}
      width={width}
      src={url}
      style={{ objectFit: "cover", ...style }}
    />
  );
};
