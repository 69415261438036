import { FirestoreCollections, GlobalStats } from "@voluntasker/common";
import { DocumentReference, collection, doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firestore } from "src/lib/firebase";

export const useGlobalStats = <T = GlobalStats>(statsId: string = "latest") => {
  const taskRef = doc(
    collection(firestore, FirestoreCollections.GlobalStats),
    statsId
  ) as DocumentReference<T>;
  return useDocumentData(taskRef);
};
