import { Query, collection, query } from "firebase/firestore";
import { firestore } from "src/lib/firebase";

import {
  ActionIcon,
  Badge,
  Card,
  Group,
  Loader,
  Table,
  Tooltip,
} from "@mantine/core";
import { useCollection } from "react-firebase-hooks/firestore";
import { Company, CompanyStatus } from "@voluntasker/common";
import { DateText } from "src/components/DateText";
import { FaPause, FaPlay, FaTrashAlt } from "react-icons/fa";
import { postAdminCompanyStatus } from "src/lib/api";
import { useMap } from "react-use";

import { notifications } from "@mantine/notifications";
import { AxiosError } from "axios";
export const AdminCompaniesTable: React.FC = () => {
  const companiesRef = collection(firestore, "companies");
  const companiesQuery = query(companiesRef);
  const [updatingCompanies, { set: setUpdatingCompanies }] = useMap();

  const [companies, isLoading] = useCollection(
    companiesQuery as Query<Company>
  );

  const onCompanyStatus = async (companyId: string, status: CompanyStatus) => {
    setUpdatingCompanies(companyId, true);
    try {
      await postAdminCompanyStatus({
        companyId,
        status,
      });
    } catch (e) {
      const err = e as AxiosError<any>;
      notifications.show({
        title: "Error",
        message: err?.response?.data?.message || err.message,
        color: "red",
      });
    }
    setUpdatingCompanies(companyId, false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Card shadow="lg" padding={0}>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Domains</th>
            <th>Admins</th>
            <th>Volunteers</th>
            <th>Created</th>
            <th>Status</th>
            <th align="right">Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies?.docs?.map((company) => (
            <tr key={company.id}>
              <td>{company.id}</td>
              <td>{company?.data()?.name}</td>
              <td>{company?.data()?.domains.join(", ")}</td>
              <td>{company?.data().admins?.length || "0"}</td>
              <td>{company?.data().volunteers?.length || "0"}</td>
              <td>
                <DateText date={company.data()?.createdOn} format="dmyhms" />
              </td>
              <td>
                <Badge
                  color={
                    company.data()?.status === CompanyStatus.Inactive ||
                    company.data()?.status === CompanyStatus.Removed
                      ? "red"
                      : "green"
                  }
                >
                  {company.data()?.status || "Active"}
                </Badge>
              </td>
              <td>
                <Group spacing="xs">
                  {company?.data().status === CompanyStatus.Inactive ||
                  company?.data().status === CompanyStatus.Removed ? (
                    <Tooltip withArrow label="Reactivate Company">
                      <ActionIcon
                        variant="outline"
                        color="aop"
                        loading={updatingCompanies[company.id]}
                        onClick={() =>
                          onCompanyStatus(company.id, CompanyStatus.Active)
                        }
                      >
                        <FaPlay />
                      </ActionIcon>
                    </Tooltip>
                  ) : (
                    <Tooltip withArrow label="Inactivate Company">
                      <ActionIcon
                        variant="outline"
                        color="aop"
                        loading={updatingCompanies[company.id]}
                        onClick={() =>
                          onCompanyStatus(company.id, CompanyStatus.Inactive)
                        }
                      >
                        <FaPause />
                      </ActionIcon>
                    </Tooltip>
                  )}

                  <Tooltip withArrow label="Delete Company">
                    <ActionIcon
                      variant="outline"
                      color="red"
                      loading={updatingCompanies[company.id]}
                      onClick={() =>
                        onCompanyStatus(company.id, CompanyStatus.Removed)
                      }
                    >
                      <FaTrashAlt />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};
