import { ActionIcon, Button, Group, Popover, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { postAdminDeleteUser } from "src/lib/api";

interface AdminDeleteUserActionIconProps {
  userId: string;
}

export const AdminDeleteUserActionIcon: React.FC<
  AdminDeleteUserActionIconProps
> = ({ userId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async () => {
    setIsDeleting(true);
    await postAdminDeleteUser({ userId });
    setIsDeleting(false);
    setIsOpen(false);
  };

  return (
    <Popover withArrow position="bottom" opened={isOpen}>
      <Popover.Target>
        <ActionIcon
          color="red"
          variant="outline"
          onClick={() => setIsOpen(true)}
        >
          <FaTrashAlt />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <Text fw="bold">Are you sure?</Text>
          <Group spacing="xs">
            <Button
              loading={isDeleting}
              color="red"
              onClick={onDelete}
              disabled={isDeleting}
              size="sm"
              sx={{
                borderRadius: 4,
              }}
            >
              Yes
            </Button>
            <Button
              color="gray"
              variant="outline"
              size="sm"
              onClick={() => setIsOpen(false)}
              disabled={isDeleting}
              sx={{
                borderRadius: 4,
              }}
            >
              Cancel
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
