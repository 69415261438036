import React, { useMemo } from "react";
import {
  Text,
  Loader,
  Stack,
  Box,
  Group,
  Skeleton,
  Anchor,
  Alert,
  StackProps,
} from "@mantine/core";
import { doc } from "firebase/firestore";
import { firestore } from "src/lib/firebase";
import { Task, TaskEndType } from "@voluntasker/common";
import { TaskMakeOfferButton } from "src/components/task/TaskMakeOfferButton";
import { MapBox } from "src/components/MapBox";
import { TaskViewButton } from "src/components/task/TaskViewButton";
import { useDocument } from "react-firebase-hooks/firestore";
import { DateText } from "src/components/DateText";
import { StorageImage } from "src/components/StorageImage";
import { useProfile } from "src/hooks/useProfile";
import { ModalType, useModalState } from "src/state/modal";
import {
  FaChild,
  FaDollarSign,
  FaMapMarkerAlt,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { UserProfileAvatar } from "src/components/user/UserAvatar";
import { Link } from "react-router-dom";

interface TaskDetailsProps extends StackProps {
  taskId: string;
  cta?: "apply" | "view";
  showAbout?: boolean;
  showMap?: boolean;
  showDetailsRow?: boolean;
}

export const TaskDetails: React.FC<TaskDetailsProps> = ({
  taskId,
  cta,
  showAbout = true,
  showMap = true,
  showDetailsRow = true,
  ...props
}) => {
  const ref = doc(firestore, "tasks", taskId || "unknown");
  const [data, isLoading] = useDocument(ref);

  const task = useMemo(() => data?.data() as Task, [data]);
  const [profile, profileLoading] = useProfile(task?.createdBy);

  const { openModal } = useModalState();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Stack {...props}>
      <Stack spacing="xl">
        <Group grow>
          <Group>
            <Anchor
              component={Link}
              to={`/profile/${task?.createdBy}`}
              target="_blank"
            >
              <UserProfileAvatar userId={task?.createdBy} />
            </Anchor>
            <Stack spacing={0}>
              <Anchor
                color="dark"
                component={Link}
                to={`/tasks/${taskId}`}
                weight={500}
                size="xl"
              >
                {task?.title}
              </Anchor>
              <Skeleton visible={profileLoading}>
                <Anchor
                  component={Link}
                  to={`/profile/${task?.createdBy}`}
                  target="_blank"
                >
                  <Text color="dimmed">{profile?.data()?.name}</Text>
                </Anchor>
              </Skeleton>
            </Stack>
          </Group>
          <Stack spacing={0}>
            <Text align="right" color="dimmed" size="xs">
              Posted On:
            </Text>
            <Text align="right" color="dimmed">
              <DateText date={task?.createdOn} />
            </Text>
          </Stack>
        </Group>

        {showAbout && (
          <Stack spacing="sm">
            <Text fw={500} size="lg">
              About this task
            </Text>
            {task.wwcRequired && (
              <Alert variant="outline" color="violet" icon={<FaChild />}>
                Working with Children check required.
              </Alert>
            )}
            {!!task.imagesRefs?.length && (
              <Group>
                {task.imagesRefs.map((imageRef) => (
                  <Anchor
                    key={imageRef}
                    onClick={() =>
                      openModal(ModalType.ImageGallery, {
                        imageRefs: task.imagesRefs,
                        selected: imageRef,
                      })
                    }
                  >
                    <StorageImage storageRef={imageRef} />
                  </Anchor>
                ))}
              </Group>
            )}
            <Text size="sm">{task?.desc}</Text>
          </Stack>
        )}
      </Stack>

      {showDetailsRow && (
        <Stack>
          <Group spacing="xl" grow>
            <Group>
              <FaRegCalendarAlt size={32} />
              <Stack spacing={0}>
                <Text>Due Date</Text>
                {task?.endType === TaskEndType.Anytime && (
                  <Text fw="bold" size="lg">
                    Anytime
                  </Text>
                )}
                {task?.endType === TaskEndType.OnDate && (
                  <DateText fw="bold" date={task.endDate} size="lg" />
                )}
                {task?.endType === TaskEndType.BeforeDate && (
                  <DateText fw="bold" date={task.endDate} size="lg" />
                )}
              </Stack>
            </Group>
            <Group>
              <FaDollarSign size={32} />
              <Stack spacing={0}>
                <Text>Task Price</Text>
                <Text fw="bold" size="lg">
                  ${task.price}
                </Text>
              </Stack>
            </Group>
          </Group>
          <Group>
            <FaMapMarkerAlt size={32} />
            <Stack spacing={0}>
              <Text>Location</Text>
              <Text fw="bold" size="lg">
                {task.shortAddress}
              </Text>
            </Stack>
          </Group>
        </Stack>
      )}

      {task?.lat && task?.lng && showMap && (
        <Box pos="relative" h={400}>
          <MapBox lat={task.lat} lng={task.lng} />
        </Box>
      )}

      {cta === "apply" && <TaskMakeOfferButton task={task} size="lg" />}
      {cta === "view" && <TaskViewButton taskId={taskId} />}
    </Stack>
  );
};
