import { UserRole } from "@voluntasker/common";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUserDetails } from "src/hooks/useUserDetails";
import { useModalState, ModalType } from "src/state/modal";

export const OnboardingCheck: React.FC = () => {
  const { openModal, closeModal } = useModalState();
  const [userDetails, userDetailsLoading] = useUserDetails();

  const location = useLocation();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked || location.pathname === "/account") {
      return;
    }
    if (
      userDetails?.data()?.roles?.includes(UserRole.Volunteer) &&
      userDetails.data()?.onboardingComplete !== true
    ) {
      openModal(ModalType.OnboardingVolunteer);
      setChecked(true);
    } else {
      closeModal(ModalType.OnboardingVolunteer);
    }
  }, [userDetails, userDetailsLoading, checked, location]);

  return <></>;
};
