import { List, Stack, Text, Title } from "@mantine/core";

export const AllocationPage: React.FC = () => (
  <Stack>
    <Title>
      Volunteering Matters: Shaping Lives Through Learning, Health, and
      Sustainability Initiatives
    </Title>
    <Text>
      Your volunteering support has the power to transform lives and create
      lasting change. By supporting our carefully crafted programs, you become a
      catalyst for empowerment, nourishment, and sustainable growth. Choose to
      support Learning and Empowerment, and you'll help provide quality
      education and promote gender equality, igniting the potential within every
      individual. Opt to contribute to Health and Well-being, and your
      volunteering will ensure access to essential healthcare, clean water, and
      nutritious food, building the foundation for thriving communities. Or,
      make a difference by supporting our Sustainable Living initiatives, which
      protect the environment, create sustainable livelihoods, and foster social
      accountability. No matter which path you choose, your generosity will have
      a profound impact, shaping a world where everyone has the opportunity to
      learn, grow, and prosper. Together, we can unlock the limitless
      possibilities that await when we invest in the power of change.
    </Text>
    <Text fw="bold">Learning and Empowerment:</Text>
    <List>
      <List.Item>
        <strong>Education:</strong> This area focuses on providing knowledge and
        skills through schools and other learning platforms. The goal is to make
        sure everyone has the chance to learn, from children to adults, to build
        a brighter future for themselves and their communities.
      </List.Item>
      <List.Item>
        <strong>Gender Equality:</strong> Gender equality is all about ensuring
        that men and women, boys and girls, have the same opportunities to
        succeed in all areas of life, whether it's in school, at work, or at
        home. It is about breaking down barriers and making sure that everyone's
        rights are respected.
      </List.Item>
    </List>
    <Text fw="bold">Health and Well-being:</Text>
    <List>
      <List.Item>
        <strong>Health:</strong> This is about making sure people have access to
        medical care, nutritious food, and the information they need to stay
        healthy. It includes preventing and treating diseases and addressing
        mental health alongside physical health.
      </List.Item>
      <List.Item>
        <strong>Water and Sanitation:</strong> Clean water and proper sanitation
        are critical for preventing disease and maintaining good health. This
        theme includes making sure that communities have clean water to drink,
        and safe ways to manage waste.
      </List.Item>
      <List.Item>
        <strong>Food Security:</strong> Food security means making sure that
        everyone has enough nutritious food to lead a healthy life. This
        involves growing food in ways that are sustainable and ensuring that it
        is available to everyone, especially those in need.
      </List.Item>
    </List>
    Sustainable Living:
    <List>
      <List.Item>
        <strong>Environment and Climate Change:</strong> This encompasses eEorts
        to protect natural resources and ensure that our environment stays
        healthy. It includes addressing the impacts of climate change and
        finding ways to live in harmony with our planet.
      </List.Item>
      <List.Item>
        <strong>Livelihoods:</strong> Livelihoods are about creating
        opportunities for work that are fair and sustainable. This theme focuses
        on helping people earn a living, whether it is through farming,
        business, or other forms of employment, so they can support themselves
        and their families.
      </List.Item>
      <List.Item>
        <strong>Social Accountability and Governance:</strong> This area deals
        with making sure that governments and organizations are serving the
        public eEectively and honestly. It includes eEorts to increase
        participation in decision-making and holding leaders accountable for
        their actions
      </List.Item>
    </List>
  </Stack>
);
