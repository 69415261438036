import { Box, Image } from "@mantine/core";
import { PostOnboardingValues } from "@voluntasker/common";
import { useState } from "react";
import { HalfPage } from "src/components/HalfPage";
import { OnboardingForm } from "src/components/onboarding/OnboardingForm";
import { postOnboarding } from "src/lib/api";
import LogoPng from "src/assets/img/Logo.png";
import OnboardingPng from "src/assets/img/Onboarding.png";

export const OnboardingPage: React.FC = () => {
  const [error, setError] = useState<Error | null | undefined>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (onboarding: PostOnboardingValues) => {
    setLoading(true);
    try {
      await postOnboarding({ onboarding });
    } catch (e) {
      const error = e as Error;
      setError(error);
    }
    setLoading(false);
  };

  return (
    <HalfPage
      leftSide={
        <Box>
          <Image src={OnboardingPng} p={48} />
        </Box>
      }
      align="stretch"
    >
      <Box p="xl">
        <Image src={LogoPng} width={156} />

        <OnboardingForm
          onSubmit={onSubmit}
          submitting={loading}
          error={error}
        />
      </Box>
    </HalfPage>
  );
};
