import { Group, Loader, Stack, Text } from "@mantine/core";
import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { FaCheckCircle } from "react-icons/fa";
import { firestore } from "src/lib/firebase";

interface TaskPaymentProps {
  taskId: string;
  taskPaymentId: string;
}

export const TaskPayment: React.FC<TaskPaymentProps> = ({
  taskId,
  taskPaymentId,
}) => {
  const taskPaymentRef = doc(
    firestore,
    `tasks/${taskId}/task-payments/${taskPaymentId}`
  );
  const [taskPayment, taskPaymentLoading] = useDocumentDataOnce(taskPaymentRef);
  return (
    <Stack>
      {taskPaymentLoading ? (
        <Loader />
      ) : (
        <Group>
          <Stack sx={{ flex: 1 }} spacing={0}>
            <Text color="dimmed" size="xs">
              PAYMENT RECEIVED
            </Text>
            <Text>${taskPayment?.details?.amount_received / 100}</Text>
          </Stack>
          <Text color="teal" size="xl">
            <FaCheckCircle />
          </Text>
        </Group>
      )}
    </Stack>
  );
};
