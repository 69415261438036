// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//* MVPBEE DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyApV7vWv2qogutSHj2NoZusLKA1ezfvv7k",
//   authDomain: "voluntasker-dev.firebaseapp.com",
//   projectId: "voluntasker-dev",
//   storageBucket: "voluntasker-dev.appspot.com",
//   messagingSenderId: "167115568181",
//   appId: "1:167115568181:web:bb2c0406535a2b49926d7c",
//   measurementId: "G-D7NMQG03Z8",
// };

//* VOLUNTASKER PROD
const firebaseConfig = {
  apiKey: "AIzaSyDUXA-FBmJMGiZJpeoRSTC8Q5s63O70sdc",
  authDomain: "voluntasker.firebaseapp.com",
  projectId: "voluntasker",
  storageBucket: "voluntasker.appspot.com",
  messagingSenderId: "618057975087",
  appId: "1:618057975087:web:09c59bf73f49c22b104dda",
  measurementId: "G-7XWY1LC4C0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

if (import.meta.env.VITE_USE_EMULATORS !== "false") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
}

export const prepareFirebaseError = (
  error: string | Error | undefined | null
) => {
  return String(error).replace("FirebaseError: Firebase: ", "");
};
