import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import { ElementsProvider } from "src/components/ElementsProvider";
import { UserIdentityVerifyButton } from "src/components/user/UserIdentityVerifyButton";
import { useUserDetails } from "src/hooks/useUserDetails";
import { ModalType, useModalState } from "src/state/modal";

export const UserVerifyModal: React.FC = () => {
  const [userDetails, userDetailsLoading] = useUserDetails();
  const { modals, closeModal } = useModalState();

  const renderLoading = () => (
    <Stack m={24}>
      <Text size="xl">Loading...</Text>
      <Text>Just a moment while we check your account status.</Text>
    </Stack>
  );

  const renderVerify = () => (
    <Stack m={24}>
      <Text size="xl">Almost There! Let's Verify Your Account First!</Text>
      <Text>
        Eager to make a difference? Complete your account verification in
        profile settings to start participating on Voluntasker!
      </Text>
      <Group>
        <ElementsProvider>
          <UserIdentityVerifyButton label="Verify Now" />
        </ElementsProvider>
        <Button variant="light" onClick={() => closeModal(ModalType.Verify)}>
          Later
        </Button>
      </Group>
    </Stack>
  );

  const renderPending = () => (
    <Stack m={24}>
      <Text size="xl">Your Account is Pending Verification</Text>
      <Text>
        We are currently reviewing your account. Please check back later.
      </Text>
      <Button variant="light" onClick={() => closeModal(ModalType.Verify)}>
        Ok
      </Button>
    </Stack>
  );

  const renderVerified = () => (
    <Stack m={24}>
      <Text size="xl">Your Account is Verified!</Text>
      <Text>You can now start applying for jobs and making a difference!</Text>
      <Button variant="light" onClick={() => closeModal(ModalType.Verify)}>
        Ok
      </Button>
    </Stack>
  );

  return (
    <Modal
      opened={!!modals[ModalType.Verify]?.opened}
      onClose={() => closeModal(ModalType.Verify)}
    >
      {userDetailsLoading
        ? renderLoading()
        : userDetails?.data()?.identityVerified === "verified"
        ? renderVerified()
        : userDetails?.data()?.identityVerified === "processing"
        ? renderPending()
        : renderVerify()}
    </Modal>
  );
};
