import { collection, orderBy, query, where } from "firebase/firestore";
import { TaskTable } from "src/components/task/TaskTable";
import { firestore } from "src/lib/firebase";

import { ActionIcon, Anchor, Card, Group, Tooltip } from "@mantine/core";
import { Task, TaskStatus } from "@voluntasker/common";
import { FaCheckDouble, FaComments } from "react-icons/fa";
import { useCollection } from "react-firebase-hooks/firestore";
import { ModalType, useModalState } from "src/state/modal";
import { Link } from "react-router-dom";
import { AdminIntercomActionIcon } from "src/components/admin/AdminIntercomActionIcon";

export const AdminDisputeTable: React.FC = () => {
  const tasksRef = collection(firestore, "tasks");
  const tasksQuery = query(
    tasksRef,
    where("status", "in", [TaskStatus.Disputed, TaskStatus.DisputeResolved]),
    orderBy("updatedOn", "desc")
  );

  const { openModal } = useModalState();

  const [tasks, isLoading, error] = useCollection(tasksQuery);

  return (
    <Card shadow="lg" padding={0}>
      <TaskTable
        striped
        highlightOnHover
        horizontalSpacing="sm"
        verticalSpacing="sm"
        tasks={tasks?.docs.map((task) => task.data()) as Task[]}
        isLoading={isLoading}
        error={error ? String(error) : undefined}
        extra={(task) =>
          task ? (
            <td>
              <Anchor component={Link} to={`/tasks/${task.id}`} target="_blank">
                {task.id}
              </Anchor>
            </td>
          ) : (
            <th>Task Link</th>
          )
        }
        actions={(task) => (
          <Group spacing="xs">
            <Tooltip withArrow label="View Chat History">
              <ActionIcon
                color="aop"
                variant="outline"
                onClick={() =>
                  openModal(ModalType.Messages, {
                    threadId: `${task.id}-${task.acceptedOfferUserId}`,
                    primaryUserId: task.createdBy,
                  })
                }
              >
                <FaComments />
              </ActionIcon>
            </Tooltip>
            <AdminIntercomActionIcon
              userId={task.disputeRaisedBy}
              conversationId={task.disputeConvoId}
            />

            <Tooltip withArrow label="Mark as Resolved">
              <ActionIcon
                disabled={task.status === TaskStatus.DisputeResolved}
                color="teal"
                variant="outline"
                onClick={() =>
                  openModal(ModalType.DisputeResolve, { taskId: task.id })
                }
              >
                <FaCheckDouble />
              </ActionIcon>
            </Tooltip>
          </Group>
        )}
      />
    </Card>
  );
};
