import React, { useState } from "react";
import { Anchor, Box, Button, Card, Group, Stack, Title } from "@mantine/core";
import { signOut } from "firebase/auth";
import { auth } from "src/lib/firebase";
import { UserDetailsForm } from "src/components/user/UserDetailsForm";
import { FaSignOutAlt, FaUserAlt } from "react-icons/fa";
import { postUserDetails } from "src/lib/api";
import { PostUserDetailsValues } from "@voluntasker/common";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";

export const AccountPage: React.FC = () => {
  const [user] = useAuthState(auth);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<Error | string | null>();

  const onSubmit = async (values: PostUserDetailsValues) => {
    setIsSaving(true);
    try {
      await postUserDetails(values);
    } catch (e) {
      const error = e as AxiosError;
      const errorMessage = error.response?.data
        ? String(error.response?.data)
        : error;
      setError(errorMessage);
    }
    setIsSaving(false);
  };

  return (
    <Card shadow="xs" padding="lg">
      <Stack>
        <Group position="apart">
          <Title>Account Details</Title>
          <Box>
            <Stack>
              <Anchor component={Link} to={`/profile/${user?.uid}`}>
                <Button disabled={!user?.uid} leftIcon={<FaUserAlt />}>
                  View Profile Page
                </Button>
              </Anchor>
              <Button
                onClick={() => signOut(auth)}
                leftIcon={<FaSignOutAlt />}
                variant="outline"
              >
                Logout
              </Button>
            </Stack>
          </Box>
        </Group>
        <Stack align="flex-start">
          <UserDetailsForm
            onSubmit={onSubmit}
            submitting={isSaving}
            error={error}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
