import { OwnerTaskTable } from "src/components/task/OwnerTaskTable";

import { Box, Card, Loader, Stack, Tabs, Text, Title } from "@mantine/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "src/lib/firebase";
import { VolunteerTaskTable } from "src/components/task/VolunteerTaskTable";
import { useIsVolunteer } from "src/hooks/useUserDetails";

enum TabName {
  MyTasks = "my-tasks",
  VolunteerTasks = "volunteer-tasks",
  Payments = "payments",
}

export const DashboardPage: React.FC = () => {
  const [user, isLoading] = useAuthState(auth);
  const isVolunteer = useIsVolunteer();

  if (isLoading) {
    return <Loader />;
  }

  if (!user?.uid) {
    return <Text>You are not logged in</Text>;
  }

  return (
    <Stack>
      <Title>Dashboard</Title>
      <Tabs defaultValue={TabName.MyTasks}>
        <Tabs.List>
          <Tabs.Tab value={TabName.MyTasks}>Tasks Created</Tabs.Tab>
          {isVolunteer && (
            <Tabs.Tab value={TabName.VolunteerTasks}>
              Tasks Volunteered
            </Tabs.Tab>
          )}
          {/* <Tabs.Tab value="payments">Payments</Tabs.Tab> */}
        </Tabs.List>
        <Box py="md">
          <Tabs.Panel value={TabName.MyTasks}>
            {user?.uid && (
              <Card withBorder shadow="sm" padding={0}>
                <OwnerTaskTable
                  striped
                  highlightOnHover
                  horizontalSpacing="sm"
                  verticalSpacing="sm"
                  userId={user?.uid}
                />
              </Card>
            )}
          </Tabs.Panel>
          {isVolunteer && (
            <Tabs.Panel value={TabName.VolunteerTasks}>
              {user?.uid && (
                <Card withBorder shadow="sm" padding={0}>
                  <VolunteerTaskTable
                    striped
                    highlightOnHover
                    horizontalSpacing="sm"
                    verticalSpacing="sm"
                    userId={user?.uid}
                  />
                </Card>
              )}
            </Tabs.Panel>
          )}
          {/* <Tabs.Panel value="payments">Payments</Tabs.Panel> */}
        </Box>
      </Tabs>
    </Stack>
  );
};
