import {
  Button,
  Card,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import { Task, TaskOffer } from "@voluntasker/common";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { DateText } from "src/components/DateText";
import { UserPaymentMethods } from "src/components/user/UserPaymentMethods";
import { UserProfileImage } from "src/components/user/UserProfileImage";
import { useProfile } from "src/hooks/useProfile";
import { postTaskAccept } from "src/lib/api";
import { auth } from "src/lib/firebase";
import { ModalType, useModalState } from "src/state/modal";

export interface TaskAcceptOfferModalData {
  task: Task;
  offer: TaskOffer;
}

export const TaskAcceptOfferModal: React.FC = () => {
  const [user, userLoading] = useAuthState(auth);
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.TaskAcceptOffer]?.data;
  const { offer, task } = (data as TaskAcceptOfferModalData) || {};

  const [profile, profileLoading] = useProfile(offer?.createdBy);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [userPaymentMethodId, setUserPaymentMethodId] = useState<string>();

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (task?.id && offer?.id && userPaymentMethodId) {
        await postTaskAccept({
          taskId: task?.id,
          offerId: offer?.id,
          paymentMethodId: userPaymentMethodId,
        });
        closeModal(ModalType.TaskAcceptOffer);
      }
    } catch (e) {}
    setIsSubmitting(false);
  };

  return (
    <Modal
      opened={!!modals[ModalType.TaskAcceptOffer]?.opened}
      onClose={() => closeModal(ModalType.TaskAcceptOffer)}
    >
      <Stack>
        <Text fw="bold">Accept Task Offer: Are You Sure?</Text>

        <Text>
          Hey there, task master! You're about to assign a volunteer to complete
          your task, but before you do, we just want to check a few things 😅.
        </Text>

        <Text>
          Before we make it official, we want to make sure you've got everything
          you need. The card you entered earlier will be charged to securely
          hold the funds but they won't be released until you confirm the
          volunteer has performed the job.
        </Text>

        <Text>
          Don't worry, if they do not perform your task you will be entitled to
          a complete refund. For more information, check out our Terms and
          Conditions. Please also note that there may be a cancellation fee.
        </Text>

        <Card withBorder>
          <Stack>
            <Group>
              <UserProfileImage profile={profile} loading={profileLoading} />
              <Stack spacing={0}>
                <Text>{profile?.data()?.name}</Text>
                <Text>{task?.title}</Text>
              </Stack>
            </Group>
            <Card.Section>
              <Divider />
            </Card.Section>
            <Stack spacing={0}>
              <Text fw="bold">Details</Text>
              <Group position="apart">
                <Text size="sm" color="dimmed">
                  Task price
                </Text>
                <Text fw="bold">${offer?.price}</Text>
              </Group>
              <Group position="apart">
                <Text size="sm" color="dimmed">
                  Platform fees
                </Text>
                <Text fw="bold">$0</Text>
              </Group>
              <Group position="apart">
                <Text size="sm" color="dimmed">
                  Due Date
                </Text>
                <DateText fw="bold" date={task?.endDate} fallback="Anytime" />
              </Group>
            </Stack>
          </Stack>
        </Card>

        {user?.uid && (
          <Card withBorder>
            <UserPaymentMethods
              userId={user.uid}
              paymentMethodId={userPaymentMethodId}
              onChange={setUserPaymentMethodId}
            />
          </Card>
        )}

        <Stack spacing="xs">
          <Button
            loading={isSubmitting || userLoading}
            disabled={!userPaymentMethodId}
            type="submit"
            size="md"
            onClick={onSubmit}
          >
            Accept Offer
          </Button>
          <Button
            variant="subtle"
            onClick={() => closeModal(ModalType.TaskAcceptOffer)}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
