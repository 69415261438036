import React, { useState } from "react";
import { Alert, Button, Image, Stack, Text, Title } from "@mantine/core";
import {
  EmailPasswordForm,
  EmailPasswordFormValues,
} from "src/components/auth/EmailPasswordForm";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthError, signInWithEmailAndPassword } from "firebase/auth";
import { auth, prepareFirebaseError } from "src/lib/firebase";
import { HalfPage } from "src/components/HalfPage";
import LogoPng from "src/assets/img/Logo.png";
import SignInJpg from "src/assets/img/SignIn.jpg";

export const SignInPage: React.FC = () => {
  // firebase sign in function

  const [searchParams] = useSearchParams("returnTo");

  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const _onSubmit = async (values: EmailPasswordFormValues) => {
    setLoading(true);
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      navigate(searchParams.get("returnTo") || "/");
    } catch (e) {
      const error = e as AuthError;
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <HalfPage leftSide={<img src={SignInJpg} />}>
      <Stack spacing="xl">
        <Image src={LogoPng} width={156} />
        <Stack spacing="xs">
          <Title order={3}>Sign In</Title>
          <Text color="dimmed">
            Enter your credentials to access your account
          </Text>
        </Stack>
        <EmailPasswordForm
          submitting={loading}
          submitText="Sign In"
          onSubmit={_onSubmit}
        />
        {error && <Alert color="red">{prepareFirebaseError(error)}</Alert>}
        <Stack spacing="xs">
          <Text>
            Don't have an account?{" "}
            <Link to="/signup">
              <Button size="xs" variant="outline">
                Sign Up
              </Button>
            </Link>
          </Text>
          <Text>
            Forgot password?{" "}
            <Link to="/reset-password">
              <Button size="xs" variant="outline">
                Reset Password
              </Button>
            </Link>
          </Text>
        </Stack>
      </Stack>
    </HalfPage>
  );
};
