import { Alert, Loader, Stack } from "@mantine/core";
import { applyActionCode } from "firebase/auth";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postUserEmailVerified } from "src/lib/api";
import { auth } from "src/lib/firebase";

interface ActionEmailVerifiedProps {
  actionCode?: string;
  continueUrl?: string;
}

export const ActionEmailVerified: React.FC<ActionEmailVerifiedProps> = ({
  actionCode,
  continueUrl,
}) => {
  const handling = useRef(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    handleVerifyEmail(actionCode || undefined);
  }, []);

  const handleVerifyEmail = useCallback(
    async (actionCode?: string) => {
      if (handling.current) {
        return;
      }
      if (!actionCode || handling.current) {
        return;
      }
      handling.current = true;
      try {
        setLoading(true);
        await applyActionCode(auth, actionCode);
        await postUserEmailVerified();
      } catch (e) {
        console.error(e);
        setError(String(e));
      }

      if (continueUrl) {
        navigate(continueUrl);
      } else {
        setLoading(false);
      }
    },
    [handling]
  );

  return loading ? (
    <Loader />
  ) : error ? (
    <Alert color="red" variant="filled">
      {error}
    </Alert>
  ) : (
    <Stack>Congratulations your email has been verified</Stack>
  );
};
