import { useMemo } from "react";
import { MessageListItem } from "src/components/message/MessageListItem";

import { ScrollArea, Stack } from "@mantine/core";
import { ThreadMessage } from "@voluntasker/common";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "src/lib/firebase";

interface MessageListProps {
  showName?: boolean;
  messages: ThreadMessage[];
  primaryUserId?: string;
}

export const MessageList: React.FC<MessageListProps> = ({
  messages,
  primaryUserId,
  showName,
}) => {
  const [user] = useAuthState(auth);
  const userId = useMemo(() => user?.uid, [user]);
  return (
    <ScrollArea offsetScrollbars>
      <Stack>
        {messages.map((message) => (
          <MessageListItem
            key={message.id}
            message={message}
            showName={showName}
            isSender={
              primaryUserId
                ? message.senderId === primaryUserId
                : message.senderId === userId
            }
          />
        ))}
      </Stack>
    </ScrollArea>
  );
};
