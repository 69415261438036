import React from "react";
import { Image, Stack, Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import { HalfPage } from "src/components/HalfPage";
import LogoPng from "src/assets/img/Logo.png";
import SignInJpg from "src/assets/img/SignIn.jpg";
import { ResetPasswordForm } from "src/components/auth/ResetPasswordForm";

export const ResetPasswordPage: React.FC = () => (
  <HalfPage leftSide={<img src={SignInJpg} />}>
    <Stack spacing="xl">
      <Image src={LogoPng} width={156} />
      <Stack spacing="xs">
        <Title order={3}>Reset Password</Title>
        <Text color="dimmed">Enter your email to reset your password</Text>
      </Stack>
      <ResetPasswordForm />
      <Stack spacing="xs">
        <Text>
          Don't have an account? <Link to="/signup">Sign Up</Link>.
        </Text>
        <Text>
          Forgot password? <Link to="/signup">Reset Password</Link>.
        </Text>
      </Stack>
    </Stack>
  </HalfPage>
);
