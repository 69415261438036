import { Anchor } from "@mantine/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { ShellHeaderNewTaskButton } from "src/components/shell/ShellHeaderNewTaskButton";
import { useIsAdmin } from "src/hooks/useIsAdmin";
import { useUserDetails } from "src/hooks/useUserDetails";
import { auth } from "src/lib/firebase";

export const ShellNavItems = () => {
  const { boot, showMessages } = useIntercom();
  const [user] = useAuthState(auth);
  const [userDetails] = useUserDetails();
  const location = useLocation();
  const onContactSupport = async () => {
    boot({
      userId: user?.uid,
      name: user?.displayName || undefined,
    });
    showMessages();
  };

  const { isAdmin } = useIsAdmin();

  return (
    <>
      {user?.uid && (
        <Anchor
          component={Link}
          to="/dashboard"
          color={location.pathname === "/dashboard" ? "aop" : "gray"}
        >
          My Tasks
        </Anchor>
      )}
      <Anchor
        component={Link}
        to="/tasks"
        color={location.pathname === "/tasks" ? "aop" : "gray"}
      >
        Browse Tasks
      </Anchor>
      {user?.uid && (
        <Anchor
          component={Link}
          to="/messages"
          color={location.pathname === "/messages" ? "aop" : "gray"}
        >
          Messages
        </Anchor>
      )}
      {isAdmin && (
        <Anchor
          component={Link}
          to="/admin"
          color={location.pathname === "/admin" ? "aop" : "gray"}
        >
          Admin
        </Anchor>
      )}
      {userDetails?.data()?.identityVerified === "verified" && (
        <Anchor onClick={onContactSupport} target="_blank" color="gray">
          Contact Support
        </Anchor>
      )}
      <Anchor
        component={Link}
        to="https://intercom.help/action-on-poverty/en"
        target="_blank"
        color="gray"
      >
        FAQ
      </Anchor>
      {user?.uid && <ShellHeaderNewTaskButton />}
    </>
  );
};
