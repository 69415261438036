import { Container } from "@mantine/core";
import { useCallback } from "react";
import { useSearchParam } from "react-use";
import { ActionEmailVerified } from "src/components/action/ActionEmailVerified";
import { ActionResetPassword } from "src/components/action/ActionResetPassword";

export const ActionPage: React.FC = () => {
  const mode = useSearchParam("mode");
  const actionCode = useSearchParam("oobCode");

  const renderAction = useCallback(() => {
    // Handle the user management action.
    switch (mode) {
      case "resetPassword":
        // Display reset password handler and UI.
        // handleResetPassword(auth, actionCode, continueUrl, "en");
        return <ActionResetPassword actionCode={actionCode || undefined} />;
        break;
      case "recoverEmail":
        // Display email recovery handler and UI.
        // handleRecoverEmail(auth, actionCode, "en");
        break;
      case "verifyEmail":
        return <ActionEmailVerified actionCode={actionCode || undefined} />;
      // Display email verification handler and UI.
      default:
      // Error: invalid mode.
    }
  }, [mode, actionCode]);

  return <Container size="sm">{renderAction()}</Container>;
};
