import { Badge, Card, Grid, Group, Loader, Stack, Text } from "@mantine/core";
import { ProfileStatus } from "@voluntasker/common";
import { useMemo } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { ResponsiveContainer, PieChart, Pie, Legend } from "recharts";
import { UserProfileImage } from "src/components/user/UserProfileImage";
import { UserProfileName } from "src/components/user/UserProfileName";
import { useProfile } from "src/hooks/useProfile";
import { allocationCategories } from "src/lib/allocations";
import GivingIconJpg from "src/assets/img/GivingIcon.jpg";

export const UserProfilePage: React.FC = () => {
  const { id: userId } = useParams<{ id: string }>();
  const [profile, profileLoading] = useProfile(userId);

  const profileData = profile?.data() || {};
  const raised = profileData?.fundsCleared
    ? Number(profileData?.fundsCleared).toLocaleString()
    : 0;

  const pieData = useMemo(() => {
    const fundsAllocatedKeys = Object.keys(profileData?.fundsAllocated || {});
    if (profileData.fundsAllocated && fundsAllocatedKeys.length) {
      return fundsAllocatedKeys.map((key) => {
        const allocationCat = allocationCategories.find(
          (cat) => cat.id === key
        );
        return {
          name: allocationCat?.title || key,
          value: profileData.fundsAllocated?.[key],
          fill: allocationCat?.color,
        };
      });
    }
    return [];
  }, [profileData]);

  if (profileLoading) {
    return (
      <Stack align="center">
        <Group>
          <Loader /> <Text>Loading Profile</Text>
        </Group>
      </Stack>
    );
  }

  if (profileData.status === ProfileStatus.Removed) {
    return (
      <Stack align="center">
        <Group>
          <Text>Profile has been removed</Text>
        </Group>
      </Stack>
    );
  }

  return (
    <Stack mb={64}>
      <Card withBorder shadow="sm">
        <Group position="apart">
          <Stack>
            <UserProfileImage
              profile={profile?.data()}
              loading={profileLoading}
              size={128}
            />
            {userId && <UserProfileName size="xl" userId={userId} />}
            {profileData?.region && (
              <Group spacing="xs">
                <FaMapMarkerAlt color="#868e96" />{" "}
                <Text color="dimmed">{profileData?.region}</Text>
              </Group>
            )}
          </Stack>
          <Stack h="100%" justify="flex-end">
            <iframe
              src={`https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fvoluntasker-dev.web.app%2Fprofile%2F${userId}&layout&size&width=77&height=20&appId`}
              width="77"
              height="20"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </Stack>
        </Group>
      </Card>
      <Grid>
        <Grid.Col xs={12} md={8}>
          <Card withBorder shadow="sm">
            <Stack>
              <Stack>
                <Text fw="bold">Bio</Text>
                <Text>{profileData.about || "No bio set"}</Text>
              </Stack>
              <Stack>
                <Text fw="bold">Skills</Text>

                {profileData?.skills ? (
                  <Group>
                    {profileData.skills.map((skill) => (
                      <Badge key={skill}>{skill}</Badge>
                    ))}
                  </Group>
                ) : (
                  <Text>No skills set</Text>
                )}
              </Stack>
            </Stack>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} md={4}>
          <Stack>
            <Card withBorder shadow="sm">
              <Stack>
                <Stack spacing={0}>
                  <Text size="xl" color="aop" fw="bold">
                    ${raised}
                  </Text>
                  <Text color="dimmed">Total Raised</Text>
                </Stack>
                <Stack>
                  <Text>
                    {profileData?.name}'s generous contributions have made a
                    global impact, supporting vital initiatives in education,
                    health, and sustainability. Take a look at how they've split
                    up their generosity:
                  </Text>
                  {pieData.length ? (
                    <ResponsiveContainer height={256} width="100%">
                      <PieChart>
                        <Pie
                          label={({ value }) => `$${value} AUD`}
                          data={pieData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                        />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <Stack align="center" spacing={0}>
                      <img width="50%" src={GivingIconJpg} />
                      <Text color="dimmed" align="center">
                        This is where their incredible acts of kindness will
                        shine.
                      </Text>
                    </Stack>
                  )}
                  <Text>
                    Their gifts have opened doors to learning, shattered
                    barriers to equality, and improved access to healthcare and
                    nutritious food. Furthermore, their philanthropy has
                    protected our planet, tackled climate change, and created
                    fair employment opportunities for those in need. Through
                    their compassion, {profileData?.name} has illuminated the
                    world through Voluntasker.
                  </Text>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
