import { Button, Group, Modal, Skeleton, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { useTask } from "src/hooks/useTask";
import { postTaskRequestPayment } from "src/lib/api";
import { ModalType, useModalState } from "src/state/modal";

export const TaskRequestPaymentModal: React.FC = () => {
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.TaskRequestPayment]?.data;

  const { taskId } = data || {};

  const [task, taskLoading] = useTask(taskId);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    if (!taskId) {
      return;
    }
    setIsSubmitting(true);
    try {
      await postTaskRequestPayment({
        taskId,
      });
      closeModal(ModalType.TaskRequestPayment);
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      opened={!!modals[ModalType.TaskRequestPayment]?.opened}
      onClose={() => closeModal(ModalType.TaskRequestPayment)}
    >
      <Stack>
        <Text fw="bold">Confirm Payment Request </Text>
        <Text>
          You've requested to release payment for the task:{" "}
          <Skeleton visible={taskLoading}>{task?.data()?.title}</Skeleton>
        </Text>
        <Text>
          Before proceeding, please review the task details again and ensure
          that all requirements have been fulfilled and that the task is
          complete.
        </Text>
        <Text>
          If everything looks good, click 'Request Payment' below to request the
          payment to be released.
        </Text>
        <Text>
          If there are any issues or concerns, click 'Cancel' to go back to the
          previous page.
        </Text>
        <Group spacing="xs">
          <Button loading={isSubmitting} color="aop" onClick={onSubmit}>
            Request Payment
          </Button>
          <Button
            disabled={isSubmitting}
            color="dark"
            variant="outline"
            onClick={() => closeModal(ModalType.TaskAcceptOffer)}
          >
            Cancel
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
