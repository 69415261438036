import React, { useMemo } from "react";
import { Text, Loader, Card, Grid, Stack } from "@mantine/core";
import { doc } from "firebase/firestore";
import { auth, firestore } from "src/lib/firebase";
import { useParams } from "react-router-dom";
import { Task } from "@voluntasker/common";
import { TaskDetails } from "src/components/task/TaskDetails";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { TaskSidebar } from "src/components/task/TaskSidebar";

export const TaskPage: React.FC = () => {
  const [user] = useAuthState(auth);
  const { id: taskId } = useParams();
  const ref = doc(firestore, "tasks", taskId || "");
  const [data, isLoading] = useDocument(ref);

  const task = useMemo(() => data?.data() as Task, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Card withBorder>
      <Stack>
        <Grid>
          <Grid.Col xs={12} md={6}>
            {task?.id ? (
              <TaskDetails
                taskId={task.id}
                cta={user?.uid === task?.createdBy ? undefined : "apply"}
              />
            ) : (
              <Text>Task not found</Text>
            )}
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            {task?.id && <TaskSidebar task={task} />}
          </Grid.Col>
        </Grid>
      </Stack>
    </Card>
  );
};
