import {
  Anchor,
  Group,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { ThreadMessage, ThreadMessageType } from "@voluntasker/common";
import { useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DateText } from "src/components/DateText";
import { UserProfileName } from "src/components/user/UserProfileName";
import { auth } from "src/lib/firebase";

interface MessageListItemProps {
  showName?: boolean;
  message: ThreadMessage;
  isSender: boolean;
}

export const MessageListItem: React.FC<MessageListItemProps> = ({
  showName,
  message,
  isSender,
}) => {
  const [user] = useAuthState(auth);
  const { text, updatedOn, type } = message;
  const theme = useMantineTheme();

  const getBgColor = useCallback(() => {
    switch (type) {
      case ThreadMessageType.Dispute:
      case ThreadMessageType.CancelRequest:
      case ThreadMessageType.CancelConfirm:
        return theme.colors.red[0];
      case ThreadMessageType.DisputeResolved:
      case ThreadMessageType.PaymentRelease:
      case ThreadMessageType.TaskOfferAccept:
      case ThreadMessageType.TaskOffer:
        return theme.colors.teal[0];
      default:
        return isSender ? "aop" : "#eee";
    }
  }, [type, isSender, theme]);

  const getColor = useCallback(() => {
    switch (type) {
      case ThreadMessageType.Dispute:
      case ThreadMessageType.CancelRequest:
      case ThreadMessageType.CancelConfirm:
        return theme.colors.red[9];
      case ThreadMessageType.DisputeResolved:
      case ThreadMessageType.PaymentRelease:
      case ThreadMessageType.TaskOfferAccept:
      case ThreadMessageType.TaskOffer:
        return theme.colors.teal[9];
      default:
        return isSender ? "white" : "black";
    }
  }, [type, isSender, theme]);

  const getDateColor = useCallback(() => {
    switch (type) {
      case ThreadMessageType.Dispute:
      case ThreadMessageType.CancelRequest:
      case ThreadMessageType.CancelConfirm:
        return theme.colors.red[8];
      case ThreadMessageType.DisputeResolved:
      case ThreadMessageType.TaskOffer:
      case ThreadMessageType.TaskOfferAccept:
      case ThreadMessageType.PaymentRelease:
        return theme.colors.teal[8];
      default:
        return isSender ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)";
    }
  }, [type, isSender, theme]);

  const getBorderColor = useCallback(() => {
    switch (type) {
      case ThreadMessageType.Dispute:
      case ThreadMessageType.CancelRequest:
      case ThreadMessageType.CancelConfirm:
        return theme.colors.red[3];
      case ThreadMessageType.DisputeResolved:
      case ThreadMessageType.PaymentRelease:
      case ThreadMessageType.TaskOfferAccept:
      case ThreadMessageType.TaskOffer:
        return theme.colors.teal[3];
      default:
        return "transparent";
    }
  }, [theme, type]);

  return (
    <Paper
      p="sm"
      radius="sm"
      bg={getBgColor()}
      style={{
        maxWidth: "60%",
        alignSelf: isSender ? "flex-end" : "flex-start",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: getBorderColor(),
      }}
    >
      <Stack spacing={0}>
        {(type === ThreadMessageType.TaskOfferAccept ||
          type === ThreadMessageType.DisputeResolved) && (
          <Text color="teal" size="xl">
            <FaCheckCircle />
          </Text>
        )}

        {(type === ThreadMessageType.Dispute ||
          type === ThreadMessageType.CancelRequest ||
          type === ThreadMessageType.CancelConfirm) && (
          <Text color="red" size="xl">
            <FaExclamationTriangle />
          </Text>
        )}

        {showName && message.createdBy && (
          <Group>
            <UserProfileName
              fw="bold"
              color={getColor()}
              userId={message.createdBy}
            />
          </Group>
        )}

        <Text color={getColor()}>{text}</Text>

        {type === ThreadMessageType.TaskOffer &&
          message.senderId !== user?.uid && (
            <Anchor
              size="xs"
              color="teal"
              style={{ textDecoration: "underline" }}
              component={Link}
              to={`/tasks/${message.taskId}`}
            >
              Click here to view offers
            </Anchor>
          )}

        <Text size="xs" color={getDateColor()}>
          <DateText date={updatedOn} format="humanize" />
        </Text>
      </Stack>
    </Paper>
  );
};
