import { PropsWithChildren, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AppShell, Container } from "@mantine/core";
import { ShellHeader } from "src/components/shell/ShellHeader";
import { OnboardingCheck } from "src/components/OnboardingCheck";
import { ShellNavbar } from "src/components/shell/ShellNavbar";
import { Notifications } from "@mantine/notifications";

export const Shell: React.FC<PropsWithChildren> = ({ children }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setNavbarOpen(false);
  }, [location]);

  return (
    <>
      <Notifications />
      <AppShell
        header={
          <ShellHeader navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
        }
        styles={(theme) => ({
          main: {
            overflow: "hidden",
            background: theme.colors.gray[0],
            height: navbarOpen ? "100dvh" : undefined,
          },
        })}
      >
        <ShellNavbar navbarOpen={navbarOpen} />
        <OnboardingCheck />
        <Container size="xl">{children || <Outlet />}</Container>
      </AppShell>
    </>
  );
};
