import { Box, Container, Stack } from "@mantine/core";
import React, { CSSProperties, PropsWithChildren, ReactNode } from "react";

interface HalfPageProps extends PropsWithChildren {
  leftSide: ReactNode;
  align?: CSSProperties["alignItems"];
  justify?: CSSProperties["justifyContent"];
}

export const HalfPage: React.FC<HalfPageProps> = ({
  leftSide,
  children,
  align,
  justify,
}) => (
  <Box
    sx={{
      display: "flex",
      height: "100dvh",
      overflow: "hidden",
    }}
  >
    <Stack
      display={{ md: "flex" }}
      sx={{
        display: "none",
        flex: 1,
        background: "#f3f3f3",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {leftSide}
    </Stack>
    <Stack
      p="md"
      sx={{
        flex: 1,
        overflow: "auto",
        alignItems: align || "center",
        justifyContent: justify || "center",
      }}
    >
      <Container size="xs">{children}</Container>
    </Stack>
  </Box>
);
