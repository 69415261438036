import React, { useState, useCallback } from "react";

import {
  GoogleMap,
  GoogleMapProps,
  useJsApiLoader,
} from "@react-google-maps/api";

interface MapBoxProps extends GoogleMapProps {
  isStatic?: boolean;
  lat: number;
  lng: number;
}

export const MapBox: React.FC<MapBoxProps> = ({
  isStatic = false,
  lat,
  lng,
  ...props
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const [_, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat, lng }}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
      }}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      {...props}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};
