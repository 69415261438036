import { collection, orderBy, query } from "firebase/firestore";
import { TaskTable } from "src/components/task/TaskTable";
import { firestore } from "src/lib/firebase";

import { ActionIcon, Card, Group } from "@mantine/core";
import { Task } from "@voluntasker/common";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";
import { ModalType, useModalState } from "src/state/modal";

export const AdminTaskTable: React.FC = () => {
  const tasksRef = collection(firestore, "tasks");
  const tasksQuery = query(tasksRef, orderBy("updatedOn", "desc"));

  const { openModal } = useModalState();

  const [tasks, isLoading, error] = useCollection(tasksQuery);

  return (
    <Card shadow="lg" padding={0}>
      <TaskTable
        striped
        highlightOnHover
        horizontalSpacing="sm"
        verticalSpacing="sm"
        tasks={tasks?.docs.map((task) => task.data()) as Task[]}
        isLoading={isLoading}
        error={error ? String(error) : undefined}
        extra={(task) => (task ? <td>{task.id}</td> : <th>ID</th>)}
        actions={(task) => (
          <Group>
            <Link to={`/tasks/${task.id}`}>
              <ActionIcon color="aop" variant="outline">
                <FaEye />
              </ActionIcon>
            </Link>
            <ActionIcon
              color="red"
              variant="outline"
              onClick={() =>
                openModal(ModalType.TaskDelete, {
                  taskId: task.id,
                  adminDelete: true,
                })
              }
            >
              <FaTrashAlt />
            </ActionIcon>
          </Group>
        )}
      />
    </Card>
  );
};
