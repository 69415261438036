import {
  PostAdminApproveVolunteer,
  PostAdminCompanyBody,
  PostAdminCompanyStatusBody,
  PostAdminDeleteTaskBody,
  PostAdminDeleteUserBody,
  PostAdminDisputeResolveBody,
  PostAdminStripePayoutBody,
  PostOnboardingArgs,
  PostResetPasswordBody,
  PostStripeCheckoutBody,
  PostTaskAcceptOfferBody,
  PostTaskCancelBody,
  PostTaskDeleteBody,
  PostTaskDisputeBody,
  PostTaskFullArgs,
  PostTaskMessageValues,
  PostTaskReleasePaymentBody,
  PostTaskRequestPaymentBody,
  PostTaskValues,
  PostUserDetailsValues,
  Task,
  apiEndpoints,
} from "@voluntasker/common";
import axios from "axios";
import { getIdToken } from "firebase/auth";
import { auth } from "src/lib/firebase";

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

const getUserToken = async () => {
  let token;
  if (auth.currentUser) {
    token = await getIdToken(auth.currentUser);
  }
  return token;
};

api.interceptors.request.use(async (config) => {
  const token = await getUserToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

interface PostTaskArgs {
  task: PostTaskValues;
}

export const postTask = (args: PostTaskArgs) => {
  return api.post<Task>(apiEndpoints.task(), args);
};

interface PostTaskMessageArgs {
  taskId: string;
  threadId?: string;
  message: PostTaskMessageValues;
}

export const postTaskMessage = (args: PostTaskMessageArgs) => {
  return api.post(apiEndpoints.taskMessage(args.taskId), args);
};

interface PostThreadMessageArgs {
  taskId?: string;
  threadId: string;
  message: PostTaskMessageValues;
}

export const postThreadMessage = (args: PostThreadMessageArgs) => {
  return api.post(apiEndpoints.threadMessage(args.threadId), args);
};

interface PostTaskPaymentArgs {
  taskId: string;
  paymentMethodId: string;
}

export const postTaskPayment = (args: PostTaskPaymentArgs) => {
  return api.post(apiEndpoints.taskPayment(args.taskId), args);
};

export const postTaskFull = (args: PostTaskFullArgs) => {
  return api.post<Task>(apiEndpoints.taskFull(), args);
};

interface PostTaskOfferArgs {
  taskId: string;
}

export const postTaskOffer = (args: PostTaskOfferArgs) => {
  return api.post(apiEndpoints.taskOffer(args.taskId), args);
};

export const postOnboarding = (args: PostOnboardingArgs) => {
  return api.post(apiEndpoints.postOnboarding(), args);
};

export const postAdminCompany = (args: PostAdminCompanyBody) => {
  return api.post(apiEndpoints.adminCompany(), args);
};

export const postUserDetails = (args: Partial<PostUserDetailsValues>) => {
  return api.post(apiEndpoints.userDetails(), args);
};

export const postUserVerification = () => {
  return api.post(apiEndpoints.userVerification());
};

export const postTaskAccept = (args: PostTaskAcceptOfferBody) => {
  return api.post(apiEndpoints.taskAcceptOffer(args.taskId), args);
};

export const postTaskCancel = (args: PostTaskCancelBody) => {
  return api.post(apiEndpoints.taskCancel(args.taskId), args);
};

export const postTaskRequestPayment = (args: PostTaskRequestPaymentBody) => {
  return api.post(apiEndpoints.taskRequestPayment(args.taskId), args);
};

export const postTaskReleasePayment = (args: PostTaskReleasePaymentBody) => {
  return api.post(apiEndpoints.taskReleasePayment(args.taskId), args);
};

export const postStripeCheckout = (args: PostStripeCheckoutBody) => {
  return api.post(apiEndpoints.stripeCheckout(), args);
};

export const getStripeIntent = () => {
  return api.get(apiEndpoints.stripeIntent());
};

export const postAdminApproveVolunteer = (args: PostAdminApproveVolunteer) => {
  return api.post(apiEndpoints.adminApproveVolunteer(), args);
};

export const getAdminStripeBalance = () => {
  return api.get(apiEndpoints.adminStripeBalance());
};

export const postAdminStripePayout = (args: PostAdminStripePayoutBody) => {
  return api.post(apiEndpoints.adminStripePayout(), args);
};

export const postAdminDeleteTask = (args: PostAdminDeleteTaskBody) => {
  return api.post(apiEndpoints.adminDeleteTask(), args);
};

export const postAdminDeleteUser = (args: PostAdminDeleteUserBody) => {
  return api.post(apiEndpoints.adminDeleteUser(), args);
};

export const postTaskDispute = (args: PostTaskDisputeBody) => {
  return api.post(apiEndpoints.taskDispute(args.taskId), args);
};

export const postTaskDisputeResolve = (args: PostAdminDisputeResolveBody) => {
  return api.post(apiEndpoints.adminDisputeResolve(), args);
};

export const postUserResetPassword = (args: PostResetPasswordBody) => {
  return api.post(apiEndpoints.userResetPassword(), args);
};

export const postUserEmailVerify = () => {
  return api.post(apiEndpoints.userEmailVerify());
};

export const postUserEmailVerified = () => {
  return api.post(apiEndpoints.userEmailVerified());
};

export const postTaskDelete = (args: PostTaskDeleteBody) => {
  return api.post(apiEndpoints.taskDelete(args.taskId), args);
};

export const postAdminCompanyStatus = (args: PostAdminCompanyStatusBody) => {
  return api.post(apiEndpoints.adminCompanyStatus(), args);
};
