import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "src/lib/firebase";

export const useIsAdmin = () => {
  const [user] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isAdminLoading, setIsAdminLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsAdminLoading(true);
      const idToken = await user?.getIdTokenResult();
      const roles = idToken?.claims.roles as string[];
      if (Array.isArray(roles) && roles?.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
      setIsAdminLoading(false);
    })();
  }, [user]);

  return {
    isAdmin,
    isAdminLoading,
  };
};
