"use client";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "src/lib/routes";
import { ErrorBoundary } from "src/components/ErrorBoundary";

const router = createBrowserRouter(routes);

export const App: React.FC = () => (
  <ErrorBoundary>
    <RouterProvider router={router} />
  </ErrorBoundary>
);
