import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Group,
  Input,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { CardElement } from "@stripe/react-stripe-js";
import { PostTaskForm3Values } from "@voluntasker/common";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { FaCheckCircle } from "react-icons/fa";
import { UserPaymentMethods } from "src/components/user/UserPaymentMethods";
import { auth } from "src/lib/firebase";
import { FormComponentProps } from "src/lib/types";

export interface TaskCreateForm3Props extends FormComponentProps {
  leftButton?: React.ReactNode;
}

//regex to strip non price related characters
const priceRegex = /[^0-9]/g;

export const TaskCreateForm3: React.FC<TaskCreateForm3Props> = ({
  leftButton,
  submitButtonProps,
  submitting,
  error,
  onSubmit,
}) => {
  const [user] = useAuthState(auth);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm<PostTaskForm3Values>({});

  const _onSubmit = handleSubmit((values) => {
    const _values = {
      ...values,
      price: String(values.price).replace(priceRegex, ""),
    };
    onSubmit?.(_values);
  });

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
    useState<string>();

  useEffect(() => {
    setValue("userPaymenMethodId", selectedPaymentMethodId || "");
  }, [selectedPaymentMethodId]);

  const price = watch("price");
  const priceValue = Math.floor(Number(String(price).replace(priceRegex, "")));

  return (
    <form onSubmit={_onSubmit}>
      <Grid gutter="xl">
        <Grid.Col xs={12} md={7}>
          <Stack py="xl">
            <Text weight={700} size={24}>
              Provide your budget and payment details
            </Text>
            <Text color="dimmed">
              Provide a task budget to guide volunteers. Enter your billing
              details to set up payment. Rest assured, you won't be billed until
              a volunteer is assigned to your task.
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Input.Wrapper
            size="lg"
            label="Task budget"
            description="Enter your task budget"
            withAsterisk
          >
            <TextInput
              prefix="$"
              disabled={submitting}
              error={errors.price?.message}
              value={priceValue > 0 ? "$ " + priceValue : ""}
              placeholder="$"
              rightSection="AUD"
              rightSectionProps={{
                style: { marginRight: 8 },
              }}
              {...register("price")}
            />
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col xs={12}>
          <Input.Wrapper
            size="lg"
            label="Payment details"
            description="Enter your payment details below"
            withAsterisk
          >
            <Stack spacing="xs">
              {user?.uid && (
                <UserPaymentMethods
                  selectable
                  userId={user.uid}
                  paymentMethodId={selectedPaymentMethodId}
                  onChange={setSelectedPaymentMethodId}
                />
              )}

              <Card
                withBorder
                p="sm"
                sx={(style) => ({
                  borderColor:
                    selectedPaymentMethodId === undefined
                      ? `${style.colors.teal[5]} !important`
                      : undefined,
                })}
              >
                <Stack>
                  <Group position="apart">
                    <Text fw="bold">New Credit Card</Text>
                    {selectedPaymentMethodId === undefined && (
                      <Text color="teal" lh={0}>
                        <FaCheckCircle size={24} />
                      </Text>
                    )}
                  </Group>
                  <Input.Wrapper label="Name on Card">
                    <TextInput
                      disabled={submitting}
                      error={errors.name?.message}
                      placeholder="The name as it appears on your card"
                      {...register("name")}
                      onChange={(e) => {
                        setSelectedPaymentMethodId(undefined);
                        register("name").onChange(e);
                      }}
                    />
                  </Input.Wrapper>

                  <Input.Wrapper label="Card Number">
                    <Card withBorder>
                      <CardElement
                        options={{
                          hidePostalCode: true,
                        }}
                        onChange={() => {
                          setSelectedPaymentMethodId(undefined);
                        }}
                      />
                    </Card>
                  </Input.Wrapper>
                </Stack>
              </Card>
            </Stack>
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col xs={12}>
          {error && (
            <Alert color="red" variant="filled">
              {String(error)}
            </Alert>
          )}
          <Grid.Col xs={12}></Grid.Col>
          <Group position="apart">
            {leftButton || <Box />}
            <Button
              size="md"
              loading={submitting}
              type="submit"
              {...submitButtonProps}
            >
              Post Task
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  );
};
