import { Anchor, Button } from "@mantine/core";
import { Link } from "react-router-dom";
import { useUserDetails } from "src/hooks/useUserDetails";
import { ModalType, useModalState } from "src/state/modal";

export const ShellHeaderNewTaskButton: React.FC = () => {
  const { openModal } = useModalState();
  const [userDetails, userDetailsLoading] = useUserDetails();

  const canCreateTask = userDetails?.data()?.identityVerified === "verified";
  //&& userDetails?.data()?.emailVerified === true;

  return (
    <Anchor
      component={Link}
      to="/tasks/create"
      onClick={(e) => {
        if (!canCreateTask) {
          e.preventDefault();
          openModal(ModalType.Verify);
        }
      }}
    >
      <Button radius="xl" loading={userDetailsLoading}>
        New Task
      </Button>
    </Anchor>
  );
};
