import {
  Button,
  Group,
  Input,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { PostOnboardingValues } from "@voluntasker/common";
import { Controller, UseFormReturn } from "react-hook-form";

interface OnboardingCorporateFormProps {
  form: UseFormReturn<PostOnboardingValues>;
  disabled?: boolean;
}

export const OnboardingCorporateForm: React.FC<
  OnboardingCorporateFormProps
> = ({ form, disabled }) => {
  return (
    <Stack>
      <Stack spacing={0} pb={24}>
        <Title>Tell us about your company</Title>
        <Text color="dimmed">Give us some information about your company</Text>
      </Stack>

      <Input.Wrapper
        label="Your Company Name"
        error={form.formState.errors.companyName?.message}
        withAsterisk
      >
        <Input
          disabled={disabled}
          error={form.formState.errors.companyName?.message}
          size="md"
          {...form.register("companyName")}
        />
      </Input.Wrapper>
      <Input.Wrapper
        label="Your Company Description"
        error={form.formState.errors.about?.message}
        withAsterisk
      >
        <Input
          disabled={disabled}
          error={form.formState.errors.about?.message}
          size="md"
          {...form.register("about")}
        />
      </Input.Wrapper>
      <Input.Wrapper label="Location" withAsterisk>
        <Controller
          control={form.control}
          name="location"
          render={({ field: { onChange, ...fieldProps } }) => (
            <Select
              onChange={(value) => value && onChange(value)}
              error={form.formState.errors.location?.message}
              size="md"
              {...fieldProps}
              data={[
                {
                  value: "qld",
                  label: "Queensland",
                },
                {
                  value: "nsw",
                  label: "New South Wales",
                },
                {
                  value: "vic",
                  label: "Victoria",
                },
                {
                  value: "sa",
                  label: "South Australia",
                },
                {
                  value: "wa",
                  label: "Western Australia",
                },
                {
                  value: "tas",
                  label: "Tasmania",
                },
                {
                  value: "nt",
                  label: "Northern Territory",
                },
                {
                  value: "act",
                  label: "Australian Capital Territory",
                },
              ]}
            />
          )}
        />
      </Input.Wrapper>

      <Input.Wrapper label="How many employees?" withAsterisk>
        <Controller
          control={form.control}
          name="employeeCount"
          render={({ field }) => (
            <Group spacing="xs">
              {["1 - 10", "20 - 30", "50+"].map((value) => (
                <Button
                  key={value}
                  size="md"
                  variant="outline"
                  color={value === field.value ? "aop" : "gray"}
                  onClick={() => field.onChange(value)}
                >
                  {value}
                </Button>
              ))}
            </Group>
          )}
        />
      </Input.Wrapper>
    </Stack>
  );
};
