import { Group, Loader, Table, Text } from "@mantine/core";
import { AllocationStats } from "@voluntasker/common";
import { useGlobalStats } from "src/hooks/useGlobalStats";
import { getAllocationTitle } from "src/lib/allocations";

export const AdminAllocationTable: React.FC = () => {
  const [stats, statsLoading] = useGlobalStats<AllocationStats>("allocation");

  if (statsLoading) {
    return (
      <Group>
        <Loader />
        <Text>Loading Impact Stats</Text>
      </Group>
    );
  }

  return (
    <Table withBorder withColumnBorders>
      <thead>
        <tr>
          <th>Area of Impact</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(stats?.allocations || {}).map((allocation) => {
          const value = stats?.allocations[allocation];
          return (
            <tr>
              <td>{getAllocationTitle(allocation)}</td>
              <td>${(value || 0) / 100}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
