import { Query, collection, query } from "firebase/firestore";
import { firestore } from "src/lib/firebase";

import {
  ActionIcon,
  Badge,
  Card,
  Group,
  Loader,
  Table,
  Text,
} from "@mantine/core";
import { useCollection } from "react-firebase-hooks/firestore";
import { User, UserStatus } from "@voluntasker/common";
import { AdminRoleTags } from "src/components/admin/AdminRoleTags";
import { DateText } from "src/components/DateText";
import { AdminDeleteUserActionIcon } from "src/components/admin/AdminDeleteUserActionIcon";
import { FaUserTag } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AdminIntercomActionIcon } from "src/components/admin/AdminIntercomActionIcon";

export const AdminUserTable: React.FC = () => {
  const userRef = collection(firestore, "users");
  const usersQuery = query(userRef);

  const [users, isLoading] = useCollection(usersQuery as Query<User>);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Card shadow="lg" padding={0}>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th style={{ maxWidth: 50 }}>Email</th>
            <th>Name</th>
            <th>Roles</th>
            <th>Status</th>
            <th>Created</th>
            <th align="right">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users?.docs?.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td style={{ maxWidth: 150 }}>
                <Text truncate>{user.data()?.partialEmail}</Text>
              </td>
              <td>
                {user.data()?.firstName} {user.data()?.lastName?.slice(0, 1)}
              </td>
              <td>
                <AdminRoleTags roles={user.data()?.roles} />
              </td>
              <td>
                <DateText date={user.data()?.createdOn} format="dmyhms" />
              </td>
              <td>
                <Badge
                  color={
                    user.data()?.status === UserStatus.Removed ? "red" : "green"
                  }
                >
                  {user.data()?.status || "Active"}
                </Badge>
              </td>
              <td>
                <Group spacing="xs">
                  <ActionIcon
                    variant="outline"
                    color="aop"
                    component={Link}
                    to={`/profile/${user.id}`}
                    target="_blank"
                  >
                    <FaUserTag />
                  </ActionIcon>
                  <AdminIntercomActionIcon
                    userId={user.id}
                    intercomContactId={user.data()?.intercomContactId}
                  />
                  <AdminDeleteUserActionIcon userId={user.id} />
                </Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};
