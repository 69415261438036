import { Skeleton, Text, TextProps } from "@mantine/core";
import { useProfile } from "src/hooks/useProfile";

interface UserProfileNameProps extends TextProps {
  userId: string;
}

export const UserProfileName: React.FC<UserProfileNameProps> = ({
  userId,
  ...props
}) => {
  const [profile, profileLoading] = useProfile(userId);
  return (
    <Skeleton visible={profileLoading}>
      {profile?.data()?.name ? (
        <Text {...props}>{profile?.data()?.name}</Text>
      ) : (
        <Text {...props}>Not Set</Text>
      )}
    </Skeleton>
  );
};
