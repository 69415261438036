import { FirestoreCollections, Profile } from "@voluntasker/common";
import { collection, doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "src/lib/firebase";

export const useProfile = (userId?: string) => {
  const userRef = doc(
    collection(firestore, FirestoreCollections.Profiles),
    userId || "unknown"
  );
  return useDocument<Profile>(userRef);
};
