import { useFormContext } from "react-hook-form";
import {
  Button,
  Stack,
  Alert,
  Input,
  Group,
  Box,
  Text,
  Grid,
  Textarea,
  Progress,
  ActionIcon,
} from "@mantine/core";

import {
  PostTaskForm2Values,
  postTaskSchema,
  schemaFieldRequired,
} from "@voluntasker/common";
import { FormComponentProps } from "src/lib/types";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { FaCloudUploadAlt, FaTimes } from "react-icons/fa";
import { useUploadFile } from "react-firebase-hooks/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firestore, storage } from "src/lib/firebase";
import { useCallback, useEffect, useMemo } from "react";
import { useList } from "react-use";
import { collection, doc } from "firebase/firestore";
import { ref as storageRef, deleteObject } from "firebase/storage";
import { readBlobAsync } from "src/lib/toolt";

interface UploadedImage {
  ref: string;
  filename: string;
  data: string;
}

export interface TaskCreateForm2Props
  extends FormComponentProps<PostTaskForm2Values> {
  leftButton?: React.ReactNode;
}

export const TaskCreateForm2: React.FC<TaskCreateForm2Props> = ({
  leftButton,
  submitButtonProps,
  submitting,
  error,
  onSubmit,
}) => {
  const [user] = useAuthState(auth);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useFormContext<PostTaskForm2Values>();
  const [uploadImages, { push: pushUploadImage, removeAt: removeUploadImage }] =
    useList<UploadedImage>([]);

  const _onSubmit = handleSubmit((values) => {
    onSubmit?.(values);
  });

  const [uploadFile, uploading, snapshot, uploadError] = useUploadFile();

  useEffect(() => {
    setValue(
      "imageRefs",
      uploadImages.map((imageRef) => imageRef.ref)
    );
  }, [uploadImages]);

  const onDrop = useCallback(
    async (files: FileWithPath[]) => {
      if (!user?.uid) {
        return;
      }
      for (const file of files) {
        const imageId = doc(collection(firestore, "uuid")).id;
        const fileRef = storageRef(
          storage,
          `/users/${user.uid}/temp/${imageId}`
        );
        const result = await uploadFile(fileRef, file, {
          contentType: file.type,
        });
        if (result?.ref.fullPath) {
          const blobData = await readBlobAsync(file);
          pushUploadImage({
            ref: result.ref.fullPath,
            filename: file.name,
            data: blobData,
          });
        }
      }
    },
    [user]
  );

  const onImageRemove = useCallback(
    async (imageRef: string) => {
      const index = uploadImages.findIndex((image) => image.ref === imageRef);
      const fileRef = storageRef(storage, imageRef);
      removeUploadImage(index);
      await deleteObject(fileRef);
    },
    [uploadImages]
  );

  const progress = useMemo(() => {
    if (snapshot?.bytesTransferred && snapshot.totalBytes) {
      return (snapshot?.bytesTransferred / snapshot?.totalBytes) * 100;
    }
    return 0;
  }, [snapshot?.bytesTransferred]);

  return (
    <form onSubmit={_onSubmit}>
      <Grid gutter="xl">
        <Grid.Col xs={12} md={7}>
          <Stack py="xl">
            <Text weight={700} size={24}>
              Tell us about your task
            </Text>
            <Text color="dimmed">
              Write a clear paragraph detailing the task, outlining what needs
              to be done. Upload photos to provide a visual reference, helping
              volunteers understand what's expected, ensuring a good match for
              the task at hand.
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col xs={12} md={7}>
          <Input.Wrapper
            size="lg"
            label="Describe your task"
            description="Provide a description of your task and what is involved"
            withAsterisk={schemaFieldRequired(postTaskSchema, "desc")}
          >
            <Textarea
              disabled={submitting}
              error={errors.desc?.message}
              placeholder="Enter details..."
              {...register("desc")}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col xs={12} md={7}>
          <Stack>
            <Input.Wrapper size="lg" label="Upload image">
              <Dropzone
                onDrop={onDrop}
                accept={{
                  "image/*": [], // All images
                }}
              >
                <Stack align="center" spacing={0}>
                  <Text size="xl" fw="bold">
                    Drag or Tap
                  </Text>
                  <Text color="dimmed">To Upload Images</Text>
                  <FaCloudUploadAlt size={48} />
                </Stack>
              </Dropzone>
            </Input.Wrapper>
            <Stack spacing="xs">
              {/* {uploadImages.map((ref) => (
                <Progress key={ref.ref} color="indigo" value={100} />
              ))} */}
              {uploading && (
                <Progress animate color="indigo" value={progress} />
              )}
              {uploadError && <Alert color="red">{String(uploadError)}</Alert>}
            </Stack>
            {!!uploadImages.length && (
              <Stack>
                <Text fw="bold">Images</Text>
                <Group>
                  {uploadImages.map((image) => (
                    <Box pos="relative" key={image.ref}>
                      <ActionIcon
                        color="red"
                        radius="xl"
                        size="xs"
                        pos="absolute"
                        top={-8}
                        right={-8}
                        bg="white"
                        sx={{
                          "&:hover": {
                            background: "#eee",
                          },
                        }}
                        onClick={() => onImageRemove(image.ref)}
                      >
                        <FaTimes />
                      </ActionIcon>
                      <img width="64px" height="64px" src={image.data} />
                    </Box>
                  ))}
                </Group>
              </Stack>
            )}
          </Stack>
        </Grid.Col>

        <Grid.Col xs={12}>
          {error && (
            <Alert color="red" variant="filled">
              {String(error)}
            </Alert>
          )}
        </Grid.Col>

        <Grid.Col xs={12}>
          <Group position="apart">
            {leftButton || <Box />}
            <Button
              size="md"
              loading={submitting}
              type="submit"
              {...submitButtonProps}
            >
              Next
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  );
};
