import { Button, Group, Modal, Skeleton, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { useTask } from "src/hooks/useTask";
import { postAdminDeleteTask, postTaskDelete } from "src/lib/api";
import { ModalType, useModalState } from "src/state/modal";

export const TaskDeleteModal: React.FC = () => {
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.TaskDelete]?.data;

  const [deleting, setDeleting] = useState(false);

  const { taskId, adminDelete } = data || {};
  const [task, taskLoading] = useTask(taskId);

  const onDelete = async () => {
    setDeleting(true);
    try {
      if (adminDelete) {
        await postAdminDeleteTask({ taskId });
      } else {
        await postTaskDelete({ taskId });
      }
      setDeleting(false);
      closeModal(ModalType.TaskDelete, {});
    } catch (e) {
      setDeleting(false);
    }
  };

  return (
    <Modal
      opened={!!modals[ModalType.TaskDelete]?.opened}
      onClose={() => closeModal(ModalType.TaskDelete)}
    >
      <Stack>
        <Skeleton visible={taskLoading}>
          <Text>Task</Text>
          <Text fw="bold" size="xl">
            {task?.data()?.title}
          </Text>
        </Skeleton>
        <Text>Are you sure you want to delete this task?</Text>
        <Group>
          <Button
            onClick={() => closeModal(ModalType.TaskDelete)}
            sx={{ borderRadius: 4 }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            variant="outline"
            onClick={onDelete}
            loading={deleting}
            sx={{ borderRadius: 4 }}
          >
            Delete
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
