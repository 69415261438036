import { Loader, Table, TableProps, Text } from "@mantine/core";
import { Task } from "@voluntasker/common";
import { TaskStatusBadge } from "src/components/task/TaskStatusBadge";
import { UserProfileName } from "src/components/user/UserProfileName";

export interface TaskTableProps extends TableProps {
  tasks: Task[];
  isLoading?: boolean;
  error?: string;
  actions?: (task: Task) => React.ReactNode;
  extra?: (task?: Task) => React.ReactNode;
}

export const TaskTable: React.FC<TaskTableProps> = ({
  tasks,
  isLoading,
  error,
  extra,
  actions,
  ...props
}) => {
  const colSpan = actions ? 5 : 4;
  return (
    <Table {...props}>
      <thead>
        <tr style={{ cursor: "pointer" }}>
          <th>Title</th>
          <th>Poster</th>
          <th>Price</th>
          <th>Status</th>
          {extra?.()}
          {actions && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={colSpan}>
              <Loader />
            </td>
          </tr>
        ) : error ? (
          <tr>
            <td colSpan={colSpan}>
              <Text color="red">{error}</Text>
            </td>
          </tr>
        ) : tasks.length ? (
          tasks.map((task) => (
            <tr key={task.id}>
              <td>{task.title}</td>
              <td>
                {task.createdBy && <UserProfileName userId={task.createdBy} />}
              </td>
              <td>${task.price}</td>
              <td>
                <TaskStatusBadge status={task.status} />
              </td>
              {extra?.(task)}
              {actions && <td>{actions(task)}</td>}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={colSpan}>
              <Text color="red">No Tasks Found</Text>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
