import { Card, Loader, Stack, Text } from "@mantine/core";
import { collection, orderBy, query, where } from "firebase/firestore";
import { firestore } from "src/lib/firebase";
import { TaskListItem } from "src/components/task/TaskListItem";
import { Task, TaskListFilterValues, TaskStatus } from "@voluntasker/common";
import { useMemo } from "react";
import { FaSadCry } from "react-icons/fa";
import { useCollection } from "react-firebase-hooks/firestore";

interface TaskListProps {
  filters?: TaskListFilterValues;
  selectedTaskId?: string;
  setSelectedTaskId?: (taskId: string) => void;
}

export const TaskList: React.FC<TaskListProps> = ({
  filters,
  selectedTaskId,
  setSelectedTaskId,
}) => {
  const taskQuery = useMemo(() => {
    const ref = collection(firestore, "tasks");

    const customQuery = [];

    customQuery.push(where("status", "==", TaskStatus.Unassigned));

    if (filters?.location && filters.location !== "all") {
      customQuery.push(where("locations", "array-contains", filters?.location));
    }

    // if (filters?.remote) {
    //   customQuery.push(where("remote", "==", true));
    // }

    // cant filter by price and other fields
    if (
      !Object.keys(filters || {}).filter((filter) => filter !== "price").length
    ) {
      if (filters?.minPrice) {
        customQuery.push(where("price", ">=", filters.minPrice));
      }

      if (filters?.maxPrice) {
        customQuery.push(where("price", "<=", filters.maxPrice));
      }
    }

    customQuery.push(orderBy("createdOn", "desc"));

    const _taskQuery = query(ref, ...customQuery);

    return _taskQuery;
  }, [filters]);

  const [data, isLoading] = useCollection(taskQuery);

  const onClickTask = (taskId?: string) => {
    if (!taskId) {
      return;
    }
    setSelectedTaskId?.(taskId);
    const element = document.getElementById("TaskDetailsCard");
    const top = element?.offsetTop ? element.offsetTop + 72 : 0;
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  };

  const taskListData = useMemo(
    () =>
      data?.docs.filter((taskDoc) => {
        const data = taskDoc.data() as Task;
        const { minPrice, maxPrice } = filters || {};
        if (minPrice && data.price < minPrice) {
          return false;
        }
        if (maxPrice && data.price > maxPrice) {
          return false;
        }
        return true;
      }),
    [data, filters]
  );

  if (isLoading) {
    return (
      <Card withBorder>
        <Stack align="center" spacing="xs">
          <Loader />
          <Text>Loading Tasks</Text>
        </Stack>
      </Card>
    );
  }
  return (
    <Stack>
      {!taskListData?.length ? (
        <Stack align="center" justify="center">
          <FaSadCry size={64} />
          <Text>No active Tasks currently available</Text>
        </Stack>
      ) : (
        taskListData?.map((task) => (
          <TaskListItem
            key={task.id}
            selected={selectedTaskId === task.id}
            task={task.data() as Task}
            onClick={(task) => onClickTask(task.id)}
          />
        ))
      )}
    </Stack>
  );
};
