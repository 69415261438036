import { Modal } from "@mantine/core";
import { TaskImageGallery } from "src/components/task/TaskImageGallery";
import { ModalType, useModalState } from "src/state/modal";

export const TaskImageGalleryModal: React.FC = () => {
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.ImageGallery]?.data;

  const currentSlide = data?.imageRefs.findIndex(
    (ref: string) => ref === data?.selected
  );

  return (
    <Modal
      size="lg"
      opened={!!modals[ModalType.ImageGallery]?.opened}
      onClose={() => closeModal(ModalType.ImageGallery)}
    >
      {!!data?.imageRefs.length && (
        <TaskImageGallery
          currentSlide={currentSlide}
          imageRefs={data?.imageRefs}
        />
      )}
    </Modal>
  );
};
