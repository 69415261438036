import { Alert, Button, Input, Stack, Text } from "@mantine/core";
import { confirmPasswordReset } from "firebase/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { auth } from "src/lib/firebase";

interface ResetPasswordValues {
  password: string;
  confirm: string;
}

interface ActionResetPasswordProps {
  actionCode?: string;
}

export const ActionResetPassword: React.FC<ActionResetPasswordProps> = ({
  actionCode,
}) => {
  const { register, handleSubmit } = useForm<ResetPasswordValues>();

  const [complete, setComplete] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const onSubmit = handleSubmit(async (data: ResetPasswordValues) => {
    if (!actionCode) {
      return;
    }
    setSubmitting(true);
    try {
      await confirmPasswordReset(auth, actionCode, data.password);
      setComplete(true);
    } catch (e) {
      setError(String(e));
    }
    setSubmitting(false);
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <Text weight="bold">Reset Password</Text>
        <Stack>
          <Input
            disabled={submitting}
            placeholder="New Password"
            type="password"
            {...register("password")}
          />
          <Input
            disabled={submitting}
            placeholder="Confirm Password"
            type="password"
            {...register("confirm")}
          />
        </Stack>
        {error && (
          <Alert color="red" variant="filled">
            {error}
          </Alert>
        )}
        <Button
          loading={submitting}
          type="submit"
          disabled={complete}
          leftIcon={complete ? <FaCheck /> : undefined}
        >
          {complete ? "Password Reset" : "Reset Password"}
        </Button>
      </Stack>
    </form>
  );
};
