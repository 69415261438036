import { FirestoreCollections, Task } from "@voluntasker/common";
import { DocumentReference, collection, doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "src/lib/firebase";

export const useTask = (taskId?: string) => {
  const taskRef = doc(
    collection(firestore, FirestoreCollections.Tasks),
    taskId || "unknown"
  ) as DocumentReference<Task>;
  return useDocument(taskRef);
};

export const useTaskOffer = (
  taskId: string = "unknown",
  taskOfferId: string = "unknown"
) => {
  const offerRef = doc(
    firestore,
    FirestoreCollections.Tasks,
    taskId || "unknown",
    FirestoreCollections.TaskOffers,
    taskOfferId || "unknown"
  );
  return useDocument(offerRef, {});
};
