import { Modal, Stack, Text } from "@mantine/core";
import { PostUserDetailsValues } from "@voluntasker/common";
import { useEffect, useState } from "react";
import { UserDetailsForm } from "src/components/user/UserDetailsForm";
import { useUserDetails } from "src/hooks/useUserDetails";
import { postUserDetails } from "src/lib/api";
import { ModalType, useModalState } from "src/state/modal";

export const OnboardingVolunteerModal: React.FC = () => {
  const { modals, closeModal } = useModalState();
  const [userDetails] = useUserDetails();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error>();

  const onSubmit = async (values: PostUserDetailsValues) => {
    setIsSubmitting(true);
    try {
      await postUserDetails(values);
    } catch (e) {
      setError(error);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (userDetails?.data()?.onboardingComplete) {
      closeModal(ModalType.OnboardingVolunteer);
    }
  }, [userDetails]);

  return (
    <Modal
      size="lg"
      opened={!!modals[ModalType.OnboardingVolunteer]?.opened}
      onClose={() => closeModal(ModalType.OnboardingVolunteer)}
    >
      <Stack>
        <Text size="lg" fw="bold">
          Volunteer Onboarding
        </Text>
        <UserDetailsForm
          isModal
          onSubmit={onSubmit}
          submitting={isSubmitting}
          error={error}
        />
      </Stack>
    </Modal>
  );
};
