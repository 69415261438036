import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { MantineSize } from "@mantine/core";

export const useBreakpoint = () => {
  const theme = useMantineTheme();

  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isSm = useMediaQuery(
    `(min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.md})`
  );
  const isMd = useMediaQuery(
    `(min-width: ${theme.breakpoints.md}) and (max-width: ${theme.breakpoints.lg})`
  );
  const isLg = useMediaQuery(
    `(min-width: ${theme.breakpoints.lg}) and (max-width: ${theme.breakpoints.xl})`
  );

  if (isXs) {
    return "xs";
  } else if (isSm) {
    return "sm";
  } else if (isMd) {
    return "md";
  } else if (isLg) {
    return "lg";
  } else {
    return "xl";
  }
};

export const getBreakpointValue = <T = any>(
  breakpoint: MantineSize,
  breakpoints: T[]
) => {
  switch (breakpoint) {
    case "xs":
      return breakpoints[0];
    case "sm":
      return breakpoints[1] || breakpoints[0];
    case "md":
      return breakpoints[2] || breakpoints[1] || breakpoints[0];
    case "lg":
      return (
        breakpoints[3] || breakpoints[2] || breakpoints[1] || breakpoints[0]
      );
    case "xl":
      return (
        breakpoints[4] ||
        breakpoints[3] ||
        breakpoints[2] ||
        breakpoints[1] ||
        breakpoints[0]
      );
    default:
      return breakpoints[0];
  }
};

export const useGetBreakpointValue = () => {
  const breakpoint = useBreakpoint();

  return function <T = any>(values: T[]) {
    return getBreakpointValue<T>(breakpoint, values);
  };
};
