import { Skeleton, Text } from "@mantine/core";
import { Profile } from "@voluntasker/common";
import { FaUserCircle } from "react-icons/fa";
import { StorageImage } from "src/components/StorageImage";

interface UserProfileImageProps {
  profile: Profile | undefined;
  loading?: boolean;
  size?: number;
}

export const UserProfileImage: React.FC<UserProfileImageProps> = ({
  size = 48,
  profile,
  loading,
}) => (
  <Skeleton circle animate height={size} visible={loading}>
    {profile?.imageRef ? (
      <StorageImage
        width={size}
        height={size}
        storageRef={profile.imageRef}
        style={{ borderRadius: "50%" }}
      />
    ) : (
      <Text color="aop">
        <FaUserCircle size={size} />
      </Text>
    )}
  </Skeleton>
);
