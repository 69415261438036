import { Outlet } from "react-router-dom";
import { ModalContainer } from "src/components/ModalContainer";
import { theme } from "src/lib/theme";

import { MantineProvider } from "@mantine/core";
import { IntercomProvider } from "react-use-intercom";

export const AppProvider: React.FC = () => (
  <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID}>
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <ModalContainer />
      <Outlet />
    </MantineProvider>
  </IntercomProvider>
);
