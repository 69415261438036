import { Alert, Button, Input, Stack, Textarea } from "@mantine/core";
import { PostTaskMessageValues } from "@voluntasker/common";
import { useForm } from "react-hook-form";
import { FaPaperPlane } from "react-icons/fa";
import { FormComponentProps } from "src/lib/types";

interface MessageFormProps extends FormComponentProps<PostTaskMessageValues> {}

export const MessageForm: React.FC<MessageFormProps> = ({
  onSubmit,
  submitting,
  error,
}) => {
  const { register, handleSubmit, reset } = useForm<PostTaskMessageValues>();

  const _onSubmit = handleSubmit(async (values) => {
    try {
      await onSubmit?.(values);
      reset();
    } catch (e) {}
  });

  return (
    <form onSubmit={_onSubmit}>
      <Stack>
        <Input.Wrapper withAsterisk style={{ width: "100%" }}>
          <Textarea
            disabled={submitting}
            placeholder="Aa"
            {...register("text")}
          />
        </Input.Wrapper>
        {error && <Alert color="red">{String(error)}</Alert>}
        <Stack align="flex-end">
          <Button
            loading={submitting}
            type="submit"
            leftIcon={<FaPaperPlane />}
          >
            Send
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
