import React from "react";
import {
  Header,
  Flex,
  Group,
  Loader,
  Badge,
  Divider,
  Anchor,
  Burger,
} from "@mantine/core";
import { FaRegEnvelope, FaRegUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import LogoPng from "src/assets/img/Logo.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "src/lib/firebase";
import { UserProfileAvatar } from "src/components/user/UserAvatar";
import { useGetBreakpointValue } from "src/hooks/useBreakpoint";
import { ShellNavItems } from "src/components/shell/ShellNavItems";

const ICON_HEIGHT = 40;

interface ShellHeaderProps {
  navbarOpen: boolean;
  setNavbarOpen: (open: boolean) => void;
}

export const ShellHeader: React.FC<ShellHeaderProps> = ({
  navbarOpen,
  setNavbarOpen,
}) => {
  const [user, userLoading] = useAuthState(auth);

  const getBreakpointValue = useGetBreakpointValue();

  return (
    <Header height={72} p="md">
      <Flex align="center" direction="row" justify="space-between">
        <Group spacing="md">
          <Burger
            onClick={() => setNavbarOpen(!navbarOpen)}
            display={getBreakpointValue(["block", "block", "none"])}
            opened={navbarOpen}
          />

          <img src={LogoPng} alt="Voluntasker Logo" height={ICON_HEIGHT} />
          <Badge color="teal" variant="outline" size="xs">
            Beta
          </Badge>
          <Group display={getBreakpointValue(["none", "none", "flex"])}>
            <Divider orientation="vertical" />
            <ShellNavItems />
          </Group>
        </Group>
        <Group spacing="md">
          {user?.uid && (
            <Anchor component={Link} to={"/messages"} size="xl" color="grey">
              <FaRegEnvelope />
            </Anchor>
          )}
          <Anchor
            component={Link}
            to={user?.uid ? "/account" : "signup"}
            style={{ height: ICON_HEIGHT }}
          >
            {userLoading ? (
              <Loader size={ICON_HEIGHT} />
            ) : user?.uid ? (
              <UserProfileAvatar userId={user.uid} />
            ) : (
              <FaRegUserCircle size={ICON_HEIGHT} />
            )}
          </Anchor>
        </Group>
      </Flex>
    </Header>
  );
};
