import { Button } from "@mantine/core";
import { useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useUserDetails } from "src/hooks/useUserDetails";
import { postUserVerification } from "src/lib/api";

interface UserIdentityVerifyButtonProps {
  label?: string;
}

export const UserIdentityVerifyButton: React.FC<
  UserIdentityVerifyButtonProps
> = ({ label }) => {
  const stripe = useStripe();
  const [loading, setLoading] = useState<boolean>(false);
  const [userDetails, userDetailsLoading] = useUserDetails();

  const verifyIdentity = async () => {
    if (!stripe) {
      return;
    }
    setLoading(true);
    try {
      const { data: clientSecret } = await postUserVerification();
      await stripe?.verifyIdentity(clientSecret);
    } catch (e) {
      console.log("err", e);
    }

    setLoading(false);
  };

  if (userDetails?.data()?.identityVerified === "verified") {
    return (
      <Button
        color="teal"
        style={{ pointerEvents: "none" }}
        variant="outline"
        leftIcon={<FaRegCheckCircle />}
      >
        Identity Verified
      </Button>
    );
  }

  if (userDetails?.data()?.identityVerified === "verified") {
    return (
      <Button loading={true} variant="outline">
        Verification in Progress
      </Button>
    );
  }

  return (
    <Button
      loading={loading || userDetailsLoading}
      onClick={verifyIdentity}
      leftIcon={<FaRegCheckCircle />}
    >
      {label || "Verify Identity"}
    </Button>
  );
};
