import { Button, Group, Modal, Skeleton, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useIntercom } from "react-use-intercom";
import { useProfile } from "src/hooks/useProfile";
import { useTask } from "src/hooks/useTask";
import { postTaskCancel, postTaskDispute } from "src/lib/api";
import { auth } from "src/lib/firebase";
import { ModalType, useModalState } from "src/state/modal";

export const TaskCancelConfirmModal: React.FC = () => {
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.TaskCancelConfirm]?.data;
  const [user] = useAuthState(auth);

  const { taskId } = data || {};

  const [task, taskLoading] = useTask(taskId);
  const [cancelProfile, cancelProfileLoading] = useProfile(
    task?.data()?.cancelUserId
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { boot, showMessages } = useIntercom();

  const onCancel = async () => {
    if (!taskId) {
      return;
    }
    setIsSubmitting(true);
    try {
      await postTaskCancel({
        taskId,
      });
      closeModal(ModalType.TaskCancelConfirm);
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const onDispute = async () => {
    if (!taskId) {
      return;
    }
    setIsSubmitting(true);
    try {
      await postTaskDispute({
        taskId,
      });
      closeModal(ModalType.TaskCancelConfirm);
      boot({
        userId: user?.uid,
        name: user?.displayName || undefined,
      });
      showMessages();
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      opened={!!modals[ModalType.TaskCancelConfirm]?.opened}
      onClose={() => closeModal(ModalType.TaskCancelConfirm)}
    >
      <Stack>
        <Text fw="bold">Hi there! 😊</Text>
        <Text>
          We've received a cancellation request from{" "}
          <Skeleton
            visible={taskLoading || cancelProfileLoading}
            display="inline"
            fs="italic"
          >
            {cancelProfile?.data()?.name}
          </Skeleton>
          . They've requested to cancel the task with the following reason:{" "}
          <Skeleton visible={taskLoading} display="inline" fs="italic">
            {task?.data()?.cancelReason}
          </Skeleton>
          .
        </Text>
        <Text>
          We understand this may be disappointing, but we need your approval to
          proceed with the cancellation, refund the payment method, and notify
          the other party. If you decline, we'll move the task to dispute. What
          would you like to do?
        </Text>
        <Text>
          If you approve the cancellation request, we'll proceed with canceling
          the task, refunding the payment method, and notifying the other party.
          If you decline, we'll move the task to dispute.
        </Text>
        <Text>What would you like to do?</Text>
        <Text color="red" size="xs">
          Warning: This action can not be reversed.
        </Text>
        <Group spacing="xs">
          <Button loading={isSubmitting} color="red" onClick={onCancel}>
            Approve Cancellation
          </Button>
          <Button
            disabled={isSubmitting}
            color="dark"
            onClick={onDispute}
            variant="outline"
          >
            Raise Dispute
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
