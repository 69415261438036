import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import {
  FaCommentsDollar,
  FaDollarSign,
  FaHandHoldingUsd,
  FaStripe,
  FaWallet,
} from "react-icons/fa";
import { Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { AdminAllocationTable } from "src/components/admin/AdminAllocationTable";
import { AdminPayoutList } from "src/components/admin/AdminPayoutList";
import { useGlobalStats } from "src/hooks/useGlobalStats";
import { postAdminStripePayout } from "src/lib/api";

export const AdminFinancialsTab: React.FC = () => {
  const [globalStats] = useGlobalStats("latest");
  const [amount, setAmount] = useState<string>("");
  const [withdrawing, setWithdrawing] = useState(false);
  const [withdrawError, setWithdrawError] = useState<string>();

  const theme = useMantineTheme();

  const data = useMemo(() => {
    const data = [];
    if (globalStats?.fundsAvailable) {
      data.push({
        name: "Available",
        value: globalStats?.fundsAvailable / 100,
        fill: theme.colors.aop[5],
      });
    }
    if (globalStats?.fundsCleared) {
      data.push({
        name: "Cleared",
        value: globalStats?.fundsCleared / 100,
        fill: theme.colors.green[5],
      });
    }
    if (globalStats?.fundsDisputed) {
      data.push({
        name: "Disputed",
        value: globalStats?.fundsDisputed / 100,
        fill: theme.colors.red[5],
      });
    }
    return data;
  }, [globalStats]);

  const onWithdraw = useCallback(async () => {
    if (!amount || !globalStats?.fundsCleared) {
      return;
    }
    if (Number(amount) * 100 > globalStats.fundsCleared) {
      setWithdrawError("Amount is greater than available funds");
      return;
    }
    setWithdrawing(true);
    setWithdrawError(undefined);
    let numberAmount = Number(amount);
    try {
      await postAdminStripePayout({ amount: numberAmount });
      setAmount("");
    } catch (e) {
      const err = e as AxiosError;
      setWithdrawError(String(err?.response?.data));
    }
    setWithdrawing(false);
  }, [amount]);

  return (
    <Stack>
      <Grid>
        <Grid.Col xs={12} md={6}>
          <Card
            shadow="lg"
            h={300}
            display="flex"
            sx={{ flexDirection: "column" }}
          >
            <Stack h="100%">
              <Text fw="bold">Funds by Category</Text>
              <Flex sx={{ flex: 1 }}>
                <Stack align="center" justify="center" sx={{ flex: 1 }}>
                  <Badge p="xl" radius="sm" h="auto" w="auto">
                    <FaWallet size={24} />
                  </Badge>
                  <Stack spacing={0}>
                    <Text color="dimmed" ta="center">
                      Available Funds
                    </Text>
                    <Text size="xl" fw="bold" ta="center">
                      $
                      {globalStats?.fundsAvailable
                        ? globalStats?.fundsAvailable / 100
                        : 0}
                    </Text>
                  </Stack>
                </Stack>
                <Divider orientation="vertical" />
                <Stack align="center" justify="center" sx={{ flex: 1 }}>
                  <Badge p="xl" radius="sm" h="auto" w="auto">
                    <FaCommentsDollar size={24} />
                  </Badge>
                  <Stack spacing={0}>
                    <Text color="dimmed" ta="center">
                      Disputed Funds
                    </Text>
                    <Text size="xl" fw="bold" ta="center">
                      $
                      {globalStats?.fundsDisputed
                        ? globalStats?.fundsDisputed / 100
                        : 0}
                    </Text>
                  </Stack>
                </Stack>
                <Divider orientation="vertical" />
                <Stack align="center" justify="center" sx={{ flex: 1 }}>
                  <Badge p="xl" radius="sm" h="auto" w="auto">
                    <FaHandHoldingUsd size={24} />
                  </Badge>
                  <Stack spacing={0}>
                    <Text color="dimmed" ta="center">
                      Clear Funds
                    </Text>
                    <Text size="xl" fw="bold" ta="center">
                      $
                      {globalStats?.fundsCleared
                        ? globalStats?.fundsCleared / 100
                        : 0}
                    </Text>
                  </Stack>
                </Stack>
              </Flex>
            </Stack>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Card
            shadow="lg"
            h={300}
            display="flex"
            sx={{ flexDirection: "column" }}
          >
            <Text fw="bold">Current Activity by Category</Text>
            <Box sx={{ flex: 1 }}>
              <ResponsiveContainer height="100%" width="100%">
                <PieChart>
                  <Pie
                    label={({ value }) => `$${value} AUD`}
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                  />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Card
            shadow="lg"
            h={400}
            display="flex"
            sx={{ flexDirection: "column" }}
          >
            <Stack sx={{ flex: 1 }} justify="space-between">
              <Group>
                <Box bg={theme.colors.teal[1]} p="md">
                  <FaStripe size={56} />
                </Box>
                <Stack>
                  <Text fw="bold">AOP Stripe</Text>
                </Stack>
              </Group>
              <Input.Wrapper>
                <Group>
                  <TextInput
                    value={amount}
                    disabled={withdrawing}
                    icon={<FaDollarSign />}
                    type="number"
                    size="lg"
                    onChange={({ target: { value } }) =>
                      setAmount(value.replace(/[^0-9.]/g, ""))
                    }
                    max={
                      globalStats?.fundsCleared
                        ? globalStats.fundsCleared / 100
                        : 1
                    }
                  />
                  <Button
                    variant="outline"
                    sx={{ borderRadius: 4 }}
                    disabled={withdrawing}
                    onClick={() =>
                      setAmount(String((globalStats?.fundsCleared || 0) / 100))
                    }
                  >
                    MAX
                  </Button>
                </Group>
              </Input.Wrapper>
              {withdrawError && (
                <Alert color="red" variant="filled">
                  {withdrawError}
                </Alert>
              )}
              <Button
                sx={{ borderRadius: 4 }}
                size="lg"
                loading={withdrawing}
                disabled={!amount}
                onClick={onWithdraw}
              >
                Withdraw Money
              </Button>
              <AdminPayoutList />
            </Stack>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Card
            shadow="lg"
            h={400}
            display="flex"
            sx={{ flexDirection: "column" }}
          >
            <AdminAllocationTable />
          </Card>
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
