import React, { useMemo } from "react";
import { Button } from "@mantine/core";
import { Task, TaskStatus } from "@voluntasker/common";
import { ModalType, useModalState } from "src/state/modal";

interface MessageTaskCancelButtonProps {
  userId: string;
  task: Task;
}

export const MessageTaskCancelButton: React.FC<
  MessageTaskCancelButtonProps
> = ({ task, userId }) => {
  const { openModal } = useModalState();
  const buttonDetails = useMemo(() => {
    if (task.status === TaskStatus.CancelRequest) {
      if (task.cancelUserId === userId) {
        return { title: "Cancellation Requested" };
      } else {
        return {
          title: "Cancellation Requested",
          color: "red",
          modal: ModalType.TaskCancelConfirm,
        };
      }
    }
    if (task.status === TaskStatus.Cancelled) {
      return { title: "Cancelled" };
    }
    return { title: "Cancel Task", modal: ModalType.TaskCancel };
  }, [task, userId]);

  return (
    <Button
      disabled={
        (task.status !== TaskStatus.Unassigned &&
          task.status !== TaskStatus.Assigned &&
          task.status !== TaskStatus.CancelRequest) ||
        !!task.releaseRequestedBy ||
        userId === task.cancelUserId
      }
      variant="light"
      color={buttonDetails?.color}
      onClick={() =>
        buttonDetails?.modal &&
        openModal(buttonDetails.modal, { taskId: task?.id })
      }
    >
      {buttonDetails?.title}
    </Button>
  );
};
