import { Badge, Group } from "@mantine/core";
import { UserRole } from "@voluntasker/common";

type AdminRoleColor = {
  [key in UserRole]: string;
};

const adminRoleColor: AdminRoleColor = {
  [UserRole.Admin]: "red",
  [UserRole.CompanyAdmin]: "yellow",
  [UserRole.Poster]: "blue",
  [UserRole.Volunteer]: "green",
};

interface AdminRoleTagsProps {
  roles: UserRole[];
}

export const AdminRoleTags: React.FC<AdminRoleTagsProps> = ({ roles }) => (
  <Group spacing={4}>
    {roles?.map((role) => (
      <Badge key={role} color={adminRoleColor[role]}>
        {role}
      </Badge>
    ))}
  </Group>
);
