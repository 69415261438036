import { Button, Group, Stack } from "@mantine/core";
import { FaUserPlus } from "react-icons/fa";
import { AdminCompaniesTable } from "src/components/admin/AdminCompaniesTable";
import { ModalType, useModalState } from "src/state/modal";

export const AdminCompaniesTab: React.FC = () => {
  const { openModal } = useModalState();

  return (
    <Stack>
      <Group>
        <Button
          leftIcon={<FaUserPlus />}
          onClick={() => openModal(ModalType.AddCompanyUser)}
          sx={{
            borderRadius: 4,
          }}
        >
          Add Company User
        </Button>
      </Group>
      <AdminCompaniesTable />
    </Stack>
  );
};
