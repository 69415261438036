import { Modal, Stack, Text } from "@mantine/core";
import { ModalType, useModalState } from "src/state/modal";

export const TermsModal: React.FC = () => {
  const { modals, closeModal } = useModalState();

  return (
    <Modal
      title="Terms and Conditions"
      opened={!!modals[ModalType.TermsModal]?.opened}
      onClose={() => closeModal(ModalType.TermsModal)}
      size="xl"
    >
      <Stack>
        <Text>
          Terms of Conditions Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Nullam nec efficitur nisl. Nunc tincidunt eros est, id consequat
          orci sagittis nec. Cras semper risus vel diam tempor ultrices. Sed
          imperdiet dolor non ligula varius, eu finibus metus luctus. Sed vitae
          enim erat. Cras et velit congue, volutpat ipsum vel, pharetra sapien.
          Donec lectus arcu, convallis non volutpat in, mattis vel purus.
          Suspendisse scelerisque purus massa, at condimentum sapien ultrices
          eu. Sed euismod orci urna, sit amet viverra sem lacinia quis. Vivamus
          condimentum risus vel justo egestas, sed commodo lorem tempus.
          Maecenas condimentum iaculis ante id lacinia. Praesent ultricies in
          mauris ut tempor. Duis suscipit congue orci sed placerat. Sed sem est,
          sollicitudin ac lacinia venenatis, bibendum eget sapien. Vestibulum
          ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
          curae; Nullam ornare dignissim nulla tristique ultrices.
        </Text>
        <Text>
          Fusce nec vestibulum sapien. Integer vel volutpat sapien. Curabitur
          facilisis eros elit, quis lacinia dolor hendrerit vel. Mauris dictum
          in arcu in sollicitudin. Suspendisse volutpat lorem non commodo
          lacinia. Quisque id neque diam. Nunc sagittis egestas mi, sollicitudin
          fermentum ipsum viverra eget. Ut pharetra accumsan orci eleifend
          tempor. Morbi facilisis egestas vehicula. Integer vitae hendrerit
          tortor, et condimentum arcu. Sed at sem fringilla, eleifend magna non,
          ullamcorper diam. Curabitur tempor lorem non dui elementum, sed
          venenatis arcu posuere. Phasellus elit magna, placerat a ultricies sit
          amet, lacinia ut diam. Sed vehicula sed leo quis aliquet. Nunc
          fermentum vel augue a volutpat.
        </Text>
        <Text>
          Quisque rutrum ante vitae pulvinar euismod. Donec ullamcorper sit amet
          ex at vestibulum. Maecenas lacinia, velit et faucibus aliquet, urna
          enim hendrerit mauris, sed tincidunt nisl diam a dolor. Aenean ut ex
          ac lacus euismod sollicitudin. Proin ex mauris, malesuada ac eleifend
          vel, consequat at diam. Sed placerat dolor eget nulla bibendum
          tristique. Quisque molestie scelerisque ex, sed ultricies nibh mollis
          non. Nam ac odio ante. Quisque vel est aliquet, fermentum metus sit
          amet, pretium est. Fusce congue quam vitae metus vestibulum tempus.
          Donec a sapien urna. Aenean eget metus urna. Maecenas semper dui eget
          ipsum varius feugiat. Maecenas faucibus imperdiet sagittis.
        </Text>
        <Text>
          Fusce lacinia tincidunt aliquet. Fusce sed ornare nunc, sit amet
          efficitur massa. Suspendisse potenti. Aliquam eget nibh dignissim,
          auctor ligula nec, dapibus elit. Ut at pulvinar nibh. Pellentesque in
          pellentesque urna. Nulla non turpis ex. Quisque tempor, lacus sed
          iaculis elementum, nulla nunc efficitur nunc, ac lobortis est felis
          sed orci. Sed et velit eu nunc hendrerit hendrerit eu nec libero.
        </Text>
        <Text>
          Aliquam fringilla justo at dapibus mollis. Duis interdum sem ut elit
          rhoncus sodales. Donec lobortis, sapien eget dictum efficitur, ipsum
          massa malesuada arcu, eu bibendum mi justo in justo. Nam leo eros,
          porttitor non augue sed, varius egestas magna. Etiam porta fringilla
          nisi. Maecenas efficitur diam nulla, in aliquet eros feugiat ac.
          Phasellus sed condimentum nulla, a gravida leo. Vestibulum ante ipsum
          primis in faucibus orci luctus et ultrices posuere cubilia curae; In
          iaculis scelerisque fermentum. Curabitur sit amet ligula id elit
          fermentum ultrices sit amet tristique mauris. Sed facilisis tellus nec
          nunc dapibus, a dignissim justo consequat. Interdum et malesuada fames
          ac ante ipsum primis in faucibus. Fusce eros diam, euismod a erat
          eget, lacinia malesuada sem. Donec eu sapien sed massa aliquet
          ultricies.
        </Text>
        <Text>
          Aenean scelerisque nisl sem, in dapibus libero congue eu. Donec
          egestas risus justo, et luctus lorem tempor in. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus.
          Pellentesque luctus id ante sit amet ultricies. Phasellus lorem metus,
          tincidunt in magna sit amet, sodales lobortis enim. Aliquam
          sollicitudin vitae quam sed venenatis. Sed pulvinar augue nibh, ac
          ultrices elit rhoncus non. Phasellus at turpis consequat, lacinia
          magna vitae, placerat nulla. Nam ac tincidunt est, et auctor mauris.
          Sed aliquet elementum efficitur. Fusce gravida sed tortor eu dapibus.
        </Text>
        <Text>
          Donec feugiat sodales purus, et placerat ante eleifend a. Quisque ac
          eleifend metus, elementum elementum felis. Curabitur ipsum orci,
          cursus nec lorem at, aliquam hendrerit ligula. Nulla tempor volutpat
          eros, vel suscipit dui fringilla dapibus. Nunc nunc orci, placerat nec
          dui vel, varius sagittis metus. Quisque finibus magna lacinia tellus
          ultricies rutrum ac id erat. Praesent ac ex tincidunt, scelerisque
          magna eget, ultricies tortor. Maecenas eu hendrerit purus.
        </Text>
      </Stack>
    </Modal>
  );
};
