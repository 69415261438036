import { FirestoreCollections, User, UserRole } from "@voluntasker/common";
import { CollectionReference, collection, doc } from "firebase/firestore";
import { auth, firestore } from "src/lib/firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useMemo } from "react";

export const useUserDetails = () => {
  const [user] = useAuthState(auth);
  const userRef = doc(
    collection(
      firestore,
      FirestoreCollections.Users
    ) as CollectionReference<User>,
    user?.uid || "unknown"
  );
  return useDocument(userRef);
};

export const useIsVolunteer = () => {
  const [userDetailsDoc, userDetailsLoading] = useUserDetails();

  const isVolunteer = useMemo(
    () => userDetailsDoc?.data()?.roles?.includes(UserRole.Volunteer),
    [userDetailsDoc]
  );

  return [isVolunteer, userDetailsLoading];
};
