import { Button, ButtonProps } from "@mantine/core";
import {
  Task,
  TaskStatus,
  UserRole,
  UserVolunteerStatus,
} from "@voluntasker/common";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTaskOffer } from "src/hooks/useTask";
import { useUserDetails } from "src/hooks/useUserDetails";
import { auth } from "src/lib/firebase";
import { ModalType, useModalState } from "src/state/modal";

interface TaskMakeOfferButtonProps extends ButtonProps {
  task: Task;
}

export const TaskMakeOfferButton: React.FC<TaskMakeOfferButtonProps> = ({
  task,
  ...props
}) => {
  const [user] = useAuthState(auth);
  const [userDetails, userDetailsLoading] = useUserDetails();
  const [offer, offerLoading] = useTaskOffer(task.id, user?.uid);
  const { openModal } = useModalState();

  const onClick = () => {
    if (userDetails?.data()?.onboardingComplete !== true) {
      openModal(ModalType.OnboardingVolunteer);
      return;
    }
    if (userDetails?.data()?.identityVerified !== "verified") {
      openModal(ModalType.Verify);
      return;
    }
    openModal(ModalType.TaskOffer, { taskId: task.id });
  };

  if (!userDetails?.data()?.roles?.includes(UserRole.Volunteer)) {
    return null;
  }

  const getButtonDisabled = () => {
    if (!user?.uid || task.ownerId === user?.uid || !!offer?.data()?.id) {
      return true;
    }
    if (task.status !== TaskStatus.Unassigned) {
      return true;
    }
    if (
      task.wwcRequired &&
      userDetails?.data()?.volunteerStatus !== UserVolunteerStatus.Approved
    ) {
      return true;
    }
    if (userDetails?.data()?.emailVerified !== true) {
      return true;
    }
    return false;
  };

  const getButtonText = () => {
    if (offerLoading) {
      return "Checking";
    }
    if (task.status === TaskStatus.Assigned) {
      return "Task Assigned";
    }
    if (task.status === TaskStatus.Completed) {
      return "Task Completed";
    }
    if (task.status === TaskStatus.Cancelled) {
      return "Task Cancelled";
    }
    if (task.status === TaskStatus.Deleted) {
      return "Task Deleted";
    }
    if (task.ownerId === user?.uid) {
      return "Your Task";
    }
    if (userDetails?.data()?.emailVerified !== true) {
      return "Email Not Verified";
    }

    if (task.wwcRequired && !userDetails?.data()?.wwcNumber) {
      return "WWC Required";
    }
    if (
      task.wwcRequired &&
      userDetails?.data()?.volunteerStatus !== UserVolunteerStatus.Approved
    ) {
      return "WWC Pending Approval";
    }
    if (offer?.data()?.id) {
      return "Offer Sent";
    }
    return "Make an Offer";
  };

  return (
    <Button
      onClick={onClick}
      disabled={getButtonDisabled()}
      loading={offerLoading || userDetailsLoading}
      {...props}
    >
      {getButtonText()}
    </Button>
  );
};
