import { Stack } from "@mantine/core";
import { ShellNavItems } from "src/components/shell/ShellNavItems";

interface ShellNavbarProps {
  navbarOpen: boolean;
}

export const ShellNavbar: React.FC<ShellNavbarProps> = ({ navbarOpen }) => (
  <Stack
    top={72}
    left={navbarOpen ? 0 : "-100%"}
    pos="absolute"
    p="md"
    w="100%"
    h="calc(100dvh - 72px)"
    bg="white"
    style={{
      transition: "200ms linear",
      zIndex: 100,
    }}
  >
    <ShellNavItems />
  </Stack>
);
