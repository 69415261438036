import React, { useState } from "react";
import { Stack, Card, Grid, ScrollArea, Box } from "@mantine/core";
import { TaskList } from "src/components/task/TaskList";
import { TaskFiltersCard } from "src/components/task/TaskFilters";
import { MapBox } from "src/components/MapBox";
import { TaskListFilterValues } from "@voluntasker/common";
import { TaskDetails } from "src/components/task/TaskDetails";

export const TasksPage: React.FC = () => {
  const [filters, setFilters] = useState<TaskListFilterValues>();

  const [selectedTaskId, setSelectedTaskId] = useState<string>();

  return (
    <Stack>
      <Grid>
        <Grid.Col md={3}>
          <TaskFiltersCard onChange={setFilters} />
        </Grid.Col>
        <Grid.Col md="auto">
          <ScrollArea>
            <TaskList
              filters={filters}
              selectedTaskId={selectedTaskId}
              setSelectedTaskId={setSelectedTaskId}
            />
          </ScrollArea>
        </Grid.Col>
        <Grid.Col md={4}>
          <Card
            id="TaskDetailsCard"
            withBorder
            sx={{ height: selectedTaskId ? "auto" : "calc(100dvh - 120px)" }}
            p={0}
          >
            {selectedTaskId ? (
              <Box p="md">
                <TaskDetails taskId={selectedTaskId} cta="view" />
              </Box>
            ) : (
              <MapBox isStatic lat={-33.8688} lng={151.2093} />
            )}
          </Card>
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
