import { Flex, Skeleton, Stack, Text } from "@mantine/core";
import { Thread } from "@voluntasker/common";
import { useAuthState } from "react-firebase-hooks/auth";
import { DateText } from "src/components/DateText";
import { UserProfileImage } from "src/components/user/UserProfileImage";
import { useProfile } from "src/hooks/useProfile";
import { useTask } from "src/hooks/useTask";
import { auth } from "src/lib/firebase";

interface ThreadListItemProps {
  thread: Thread;
  isSelected?: boolean;
  setSelectedThread: (thread: Thread) => void;
}
export const ThreadListItem: React.FC<ThreadListItemProps> = ({
  thread,
  isSelected,
  setSelectedThread,
}) => {
  const [user] = useAuthState(auth);
  const otherUser = thread.userIds.find((userId) => userId !== user?.uid);

  const [profile, profileLoading] = useProfile(otherUser);
  const [task, taskLoading] = useTask(thread.taskId);

  return (
    <Flex
      m="xs"
      p="xs"
      onClick={() => setSelectedThread(thread)}
      sx={(theme) => ({
        cursor: "pointer",
        borderRadius: theme.radius.sm,
        background: isSelected ? theme.colors.gray[1] : undefined,
        "&:hover": {
          background: theme.colors.gray[1],
        },
        "&:active": {
          background: theme.colors.gray[2],
        },
      })}
    >
      <UserProfileImage profile={profile?.data()} loading={profileLoading} />
      <Stack mx="md" sx={{ flex: 1, overflow: "hidden" }}>
        <Text>
          <Skeleton animate visible={taskLoading || !task?.id}>
            <Text size="md" weight="bold">
              {task?.data()?.title || "Unknown Task"}
            </Text>
          </Skeleton>
          <Skeleton animate visible={profileLoading || !profile?.id}>
            <Text size="sm">{profile?.data()?.name || "New User"}</Text>
          </Skeleton>
          <Text size="sm" truncate maw="100%" color="dimmed">
            {thread.lastMessage}
          </Text>
        </Text>
      </Stack>
      <DateText color="dimmed" date={thread.updatedOn} />
    </Flex>
  );
};
