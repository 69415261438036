import { yupResolver } from "@hookform/resolvers/yup";
import {
  Anchor,
  Button,
  Input,
  Modal,
  Select,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { PostTaskOfferValues, postTaskOfferSchema } from "@voluntasker/common";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { allocationCategories } from "src/lib/allocations";
import { postTaskOffer } from "src/lib/api";
import { auth } from "src/lib/firebase";
import { ModalType, useModalState } from "src/state/modal";

export const TaskOfferModal: React.FC = () => {
  const [user, userLoading] = useAuthState(auth);
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.TaskOffer]?.data;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<PostTaskOfferValues>({
    resolver: yupResolver(postTaskOfferSchema),
  });

  const { taskId } = data || {};

  const _onSubmit = handleSubmit(async (values) => {
    if (!user?.uid || !taskId) {
      return;
    }
    setIsSubmitting(true);
    try {
      await postTaskOffer({
        taskId,
        ...values,
      });
      onClose();
    } catch (e) {}
    setIsSubmitting(false);
  });

  const onClose = () => {
    reset();
    closeModal(ModalType.TaskOffer, {});
  };

  return (
    <Modal opened={!!modals[ModalType.TaskOffer]?.opened} onClose={onClose}>
      <form onSubmit={_onSubmit}>
        <Stack>
          <Text fw="bold">Make an Offer: Seal The Deal 💰</Text>
          <Text>
            You've found the perfect task for your skills! Now, let's make it
            official 🤝.
          </Text>

          <Text>
            You've got this! Enter your offer and click 'Make an Offer' to send
            your offer to the task poster.
          </Text>

          <Text>
            You may also add a personal touch to your offer by writing a brief
            message to the task poster, introducing yourself and explaining why
            you're the perfect fit for the job 💼. Good luck!
          </Text>
          <Textarea
            error={errors?.message?.message}
            rows={3}
            placeholder="Add a personalised message..."
            {...register("message")}
          />
          <Input
            error={errors?.price?.message}
            icon={<>$</>}
            placeholder="Enter an offer"
            type="number"
            {...register("price")}
          />
          <Stack spacing={0}>
            <Select
              error={errors?.allocation?.message}
              placeholder="Select your area of impact"
              data={allocationCategories.map((cat) => ({
                value: cat.id,
                label: cat.title,
              }))}
              {...register("allocation")}
              onChange={(value) => {
                value && setValue("allocation", value);
              }}
            />
            <Anchor href="/impact" target="_blank">
              Learn More
            </Anchor>
          </Stack>
          <Button loading={isSubmitting || userLoading} type="submit" size="lg">
            Submit Offer
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};
