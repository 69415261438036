import { Carousel } from "@mantine/carousel";
import { StorageImage } from "src/components/StorageImage";

interface TaskImageGalleryProps {
  currentSlide?: number;
  imageRefs: string[];
}

export const TaskImageGallery: React.FC<TaskImageGalleryProps> = ({
  currentSlide,
  imageRefs,
}) => (
  <Carousel initialSlide={currentSlide}>
    {imageRefs.map((imageRef) => (
      <Carousel.Slide key={imageRef}>
        <StorageImage width="100%" height="100%" storageRef={imageRef} />
      </Carousel.Slide>
    ))}
  </Carousel>
);
