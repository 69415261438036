import { Button, Group, Modal, Stack, Text, Textarea } from "@mantine/core";
import { useState } from "react";
import { postTaskCancel } from "src/lib/api";
import { ModalType, useModalState } from "src/state/modal";

export const TaskCancelModal: React.FC = () => {
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.TaskCancel]?.data;

  const { taskId } = data || {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reason, setReason] = useState<string>();

  const onCancel = async () => {
    if (!taskId) {
      return;
    }
    setIsSubmitting(true);
    try {
      await postTaskCancel({
        taskId,
        reason,
      });
      closeModal(ModalType.TaskCancel);
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };
  return (
    <Modal
      opened={!!modals[ModalType.TaskCancel]?.opened}
      onClose={() => closeModal(ModalType.TaskCancel)}
    >
      <Stack>
        <Text fw="bold">Are you sure you want to cancel this task? </Text>
        <Text>
          Canceling the task will remove it from your active tasks and it will
          no longer be able to be completed. A request to cancel will be sent
          through to the other party for approval before the cancellation is
          completed.
        </Text>
        <Text>
          If you're sure you want to cancel the task, click 'Cancel Task' below.
          If not, click 'Don't Cancel' to go back to the previous page.
        </Text>
        <Text>
          Please note that the task poster's payment method will be refunded if
          the task has not been completed.
        </Text>
        <Textarea
          placeholder="Reason for cancellation"
          onChange={({ target: { value } }) => setReason(value)}
        />
        <Text color="red" size="xs">
          Warning: This action can not be reversed.
        </Text>
        <Group spacing="xs">
          <Button
            disabled={isSubmitting}
            color="dark"
            onClick={() => closeModal(ModalType.TaskAcceptOffer)}
          >
            Don't Cancel
          </Button>
          <Button
            loading={isSubmitting}
            color="red"
            variant="outline"
            onClick={onCancel}
          >
            Cancel Task
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
