import { Button } from "@mantine/core";
import { Link } from "react-router-dom";

interface TaskViewButtonProps {
  taskId: string;
}

export const TaskViewButton: React.FC<TaskViewButtonProps> = ({ taskId }) => {
  return (
    <Button component={Link} to={`/tasks/${taskId}`}>
      View More
    </Button>
  );
};
