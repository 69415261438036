import { Button, Card, Loader, Modal, Stack, Text } from "@mantine/core";
import { useCallback, useState } from "react";
import { TaskDetails } from "src/components/task/TaskDetails";
import { TaskPayment } from "src/components/task/TaskPayment";
import { useProfile } from "src/hooks/useProfile";
import { useTask } from "src/hooks/useTask";
import { postTaskDisputeResolve } from "src/lib/api";
import { ModalType, useModalState } from "src/state/modal";

export const AdminDisputeResolveModal: React.FC = () => {
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.DisputeResolve]?.data;

  const { taskId } = data || {};

  const [task, taskLoading] = useTask(taskId);
  const [disputer, disputerLoading] = useProfile(task?.data()?.disputeRaisedBy);

  const [isResolving, setIsResolving] = useState(false);

  const disputerType =
    task?.data()?.disputeRaisedBy === task?.data()?.createdBy
      ? "Task Poster"
      : "Volunteer";

  const taskData = task?.data();

  const onResolve = useCallback(
    async (refund: boolean = false) => {
      setIsResolving(true);
      try {
        await postTaskDisputeResolve({ taskId, refund });
        closeModal(ModalType.DisputeResolve);
      } catch (e) {}
      setIsResolving(false);
    },
    [taskId]
  );

  return (
    <Modal
      title="Resolve Task Dispute"
      opened={!!modals[ModalType.DisputeResolve]?.opened}
      onClose={() => closeModal(ModalType.DisputeResolve)}
    >
      {taskLoading || disputerLoading ? (
        <Loader />
      ) : (
        <Stack>
          <TaskDetails
            taskId={taskId}
            showMap={false}
            showAbout={false}
            showDetailsRow={false}
          />
          {taskData?.paymentId && (
            <Card withBorder>
              <TaskPayment taskId={taskId} taskPaymentId={taskData.paymentId} />
            </Card>
          )}
          <Stack>
            <Text>
              We have received a dispute claim from {disputer?.data()?.name} who
              is the {disputerType}. As an AOP admin user, you have been
              assigned to resolve this dispute.
            </Text>
            <Text>
              The dispute reason is: "
              {task?.data()?.disputeReason || "not provided"}". Review the
              dispute details, including the task description, completed work,
              and communication between the task poster and volunteer. Consider
              both parties' perspectives and make a fair decision.
            </Text>
            <Text>You have two options to resolve this dispute:</Text>
            <Text>Option 1: Resolve without a refund</Text>
            <Text>
              If you select this option, the dispute will be closed, and the
              task poster will not receive a refund. The task will be marked as
              completed, and the user will not be able to request any further
              changes.
            </Text>
            <Text>Option 2: Resolve by refunding</Text>
            <Text>
              If you select this option, the task poster will receive a full
              refund for the task, and the dispute will be closed. The task will
              be marked as cancelled, and the user will not be able to request
              any further changes.
            </Text>
            <Text>
              Please note that once you have made your selection, it cannot be
              reversed. Therefore, please ensure that you have carefully
              reviewed the dispute details and have selected the appropriate
              resolution option.
            </Text>
            <Text>Which option do you want to choose?</Text>
          </Stack>
          <Stack spacing="xs">
            <Button
              loading={isResolving}
              sx={{ borderRadius: 4 }}
              onClick={() => onResolve(false)}
            >
              Resolve without refund
            </Button>
            <Button
              loading={isResolving}
              sx={{ borderRadius: 4 }}
              onClick={() => onResolve(true)}
            >
              Resolve with refund
            </Button>
            <Button
              disabled={isResolving}
              sx={{ borderRadius: 4 }}
              variant="outline"
              onClick={() => closeModal(ModalType.DisputeResolve)}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Modal>
  );
};
