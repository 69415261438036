import React, { useState } from "react";
import {
  Text,
  Stack,
  Group,
  Skeleton,
  Button,
  Divider,
  Anchor,
  ActionIcon,
  Menu,
} from "@mantine/core";
import { TaskStatus, Thread } from "@voluntasker/common";
import { useTask } from "src/hooks/useTask";
import { useProfile } from "src/hooks/useProfile";
import { UserProfileImage } from "src/components/user/UserProfileImage";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "src/lib/firebase";
import { Link } from "react-router-dom";
import { FaEllipsisH } from "react-icons/fa";
import { ModalType, useModalState } from "src/state/modal";
import { MessageTaskCancelButton } from "src/components/message/MessageTaskCancelButton";
import { postTaskDispute } from "src/lib/api";
import { useIntercom } from "react-use-intercom";

interface MessageTaskDetailsProps {
  thread: Thread;
}

export const MessageTaskDetails: React.FC<MessageTaskDetailsProps> = ({
  thread,
}) => {
  const { openModal } = useModalState();
  const { boot, showMessages } = useIntercom();
  const [user] = useAuthState(auth);
  const [task, taskLoading] = useTask(thread.taskId);
  const [profile, profileLoading] = useProfile(task?.data()?.createdBy);
  const [disputing, setDisputing] = useState(false);

  const onDispute = async () => {
    setDisputing(true);
    try {
      await postTaskDispute({ taskId: thread.taskId });
      boot({
        userId: user?.uid,
        name: user?.displayName || undefined,
      });
      showMessages();
    } catch (e) {}
    setDisputing(false);
  };

  const onContactSupport = async () => {
    boot({
      userId: user?.uid,
      name: user?.displayName || undefined,
    });
    showMessages();
  };

  return (
    <Stack>
      <Group position="apart">
        <Group>
          <UserProfileImage
            profile={profile?.data()}
            loading={profileLoading}
          />
          <Stack spacing={0}>
            <Skeleton visible={taskLoading}>
              <Anchor component={Link} to={`/tasks/${task?.id}`}>
                <Text weight={500} size="xl">
                  {task?.data()?.title}
                </Text>
              </Anchor>
            </Skeleton>
            <Text color="dimmed">{profile?.data()?.name}</Text>
          </Stack>
        </Group>
        <Menu withArrow>
          <Menu.Target>
            <ActionIcon>
              <FaEllipsisH />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item disabled={disputing} onClick={onDispute}>
              Raise Dispute
            </Menu.Item>
            <Menu.Item onClick={onContactSupport}>Contact Support</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      {task?.id && user?.uid === task?.data()?.createdBy && (
        <Group spacing="xs">
          {task.exists() && user?.uid && (
            <MessageTaskCancelButton task={task?.data()} userId={user.uid} />
          )}
          <>
            {task?.data()?.status === TaskStatus.Assigned && (
              <Button
                variant="light"
                disabled={
                  !task?.data()?.releaseRequestedBy ||
                  task?.data()?.status !== TaskStatus.Assigned
                }
                onClick={() =>
                  openModal(ModalType.TaskReleasePayment, { taskId: task?.id })
                }
              >
                {task?.data()?.status === TaskStatus.Completed
                  ? "Payment Released"
                  : "Release Payment"}
              </Button>
            )}
          </>
        </Group>
      )}
      {task?.id &&
        user?.uid === task?.data()?.acceptedOfferUserId &&
        task?.data()?.status === TaskStatus.Assigned && (
          <Group spacing="xs">
            {task.exists() && user?.uid && (
              <MessageTaskCancelButton task={task?.data()} userId={user.uid} />
            )}
            <Button
              variant="light"
              disabled={!!task?.data()?.releaseRequestedBy}
              color="teal"
              onClick={() =>
                openModal(ModalType.TaskRequestPayment, { taskId: task?.id })
              }
            >
              {task?.data()?.releaseRequestedBy
                ? "Payment Requested"
                : "Request Payment"}
            </Button>
          </Group>
        )}
      <Divider />
    </Stack>
  );
};
