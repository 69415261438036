import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Card,
  Group,
  Stack,
  Stepper,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import {
  PostOnboardingValues,
  UserPurpose,
  postOnboardingSchema,
} from "@voluntasker/common";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaBuilding, FaHandHoldingHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { OnboardingCorporateForm } from "src/components/onboarding/OnboardingCompanyForm";
import { OnboardingIndividualForm } from "src/components/onboarding/OnboardingIndividualForm";
import { FormComponentProps } from "src/lib/types";

interface OnboardingFormProps
  extends FormComponentProps<PostOnboardingValues> {}

export const OnboardingForm: React.FC<OnboardingFormProps> = ({
  onSubmit,
  error,
  submitting,
}) => {
  const theme = useMantineTheme();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const form = useForm<PostOnboardingValues>({
    resolver: yupResolver(postOnboardingSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const purpose = watch("purpose");

  const _onSubmit = handleSubmit(async (values) => {
    try {
      await onSubmit?.(values);
      setCurrentStep(2);
    } catch (e) {
      console.error(e);
    }
  });

  const selectPurpose = (purpose: UserPurpose) => {
    setValue("purpose", purpose);
    setCurrentStep(1);
  };

  return (
    <form onSubmit={_onSubmit}>
      <Stepper
        active={currentStep}
        styles={{
          steps: {
            paddingRight: 48,
            paddingTop: 48,
            paddingBottom: 24,
          },
        }}
      >
        <Stepper.Step label="Your Goals">
          <Stack spacing={0} pb={48}>
            <Title>Tell us what you'd like to do</Title>
            <Text color="dimmed">
              Select your role below so that we can tailor your sign up
            </Text>
          </Stack>
          <Controller
            control={control}
            name="purpose"
            render={() => (
              <Group grow>
                <Card
                  withBorder
                  title="one"
                  padding="xs"
                  sx={{
                    borderColor: errors.purpose?.message
                      ? theme.colors.red[6]
                      : undefined,
                  }}
                  onClick={() => selectPurpose(UserPurpose.Individual)}
                >
                  <Stack align="center">
                    <FaHandHoldingHeart size={48} />
                    <Text size="xl" fw={500}>
                      Individual
                    </Text>
                    <Text color="dimmed">Become a volunteer or poster</Text>
                    <Button fullWidth>Select</Button>
                  </Stack>
                </Card>

                <Card
                  withBorder
                  title="one"
                  padding="xs"
                  sx={{
                    borderColor: errors.purpose?.message
                      ? theme.colors.red[6]
                      : undefined,
                  }}
                  onClick={() => selectPurpose(UserPurpose.Corporate)}
                >
                  <Stack align="center">
                    <FaBuilding size={48} />
                    <Text size="xl" fw={500}>
                      Corporate
                    </Text>
                    <Text color="dimmed">Sign your company up</Text>
                    <Button fullWidth>Select</Button>
                  </Stack>
                </Card>
              </Group>
            )}
          />
        </Stepper.Step>

        <Stepper.Step label="About You">
          <Stack>
            {purpose === UserPurpose.Individual && (
              <OnboardingIndividualForm form={form} disabled={submitting} />
            )}
            {purpose === UserPurpose.Corporate && (
              <OnboardingCorporateForm form={form} disabled={submitting} />
            )}
            <Stack pt={48}>
              {error && <Alert color="red">{String(error)}</Alert>}
              <Box>
                <Button type="submit" loading={submitting} size="lg">
                  Next
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stepper.Step>

        <Stepper.Step label="Thankyou">
          <Stack spacing={48}>
            <Stack spacing={0}>
              <Title>Registration Successful!</Title>
              <Text color="dimmed">
                Hey there, changemaker! We're thrilled to have you onboard.
              </Text>
            </Stack>
            <Box>
              <Button component={Link} to="/" size="lg">
                Continue to Dashboard
              </Button>
            </Box>
          </Stack>
        </Stepper.Step>
      </Stepper>
    </form>
  );
};
