import { collection, query, where } from "firebase/firestore";
import { TaskTable } from "src/components/task/TaskTable";
import { firestore } from "src/lib/firebase";

import { ActionIcon, Group, TableProps } from "@mantine/core";
import { Task } from "@voluntasker/common";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";

interface VolunteerTaskTableProps extends TableProps {
  userId: string;
}

export const VolunteerTaskTable: React.FC<VolunteerTaskTableProps> = ({
  userId,
  ...props
}) => {
  const tasksRef = collection(firestore, "tasks");
  const tasksQuery = query(
    tasksRef,
    where("acceptedOfferUserId", "==", userId)
  );

  const [tasks, isLoading, error] = useCollection(tasksQuery);

  return (
    <TaskTable
      tasks={tasks?.docs.map((task) => task.data()) as Task[]}
      isLoading={isLoading}
      error={error ? String(error) : undefined}
      actions={(task) => (
        <Group spacing={"xs"}>
          <Link to={`/tasks/${task.id}`}>
            <ActionIcon color="aop" variant="outline">
              <FaEye />
            </ActionIcon>
          </Link>
        </Group>
      )}
      {...props}
    />
  );
};
