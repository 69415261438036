import { Modal } from "@mantine/core";
import { MessageBox } from "src/components/message/MessageBox";
import { ModalType, useModalState } from "src/state/modal";

export const MessageModal: React.FC = () => {
  const { modals, closeModal } = useModalState();
  const data = modals[ModalType.Messages]?.data;

  const { threadId, primaryUserId } = data || {};

  return (
    <Modal
      opened={!!modals[ModalType.Messages]?.opened}
      onClose={() => closeModal(ModalType.Messages)}
    >
      <MessageBox
        showName
        threadId={threadId}
        showForm={false}
        primaryUserId={primaryUserId}
      />
    </Modal>
  );
};
