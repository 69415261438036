import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  primaryColor: "aop",
  colors: {
    aop: [
      "#f1f0f9",
      "#deddec",
      "#bbb9db",
      "#9592cb",
      "#7670bc",
      "#625bb4",
      "#2c2966",
      "#48429c",
      "#403b8c",
      "#35327c",
    ],
  },
  components: {
    Card: {
      styles: (theme) => ({
        root: {
          borderColor: `${theme.colors.gray[2]} !important`,
        },
      }),
    },
    Button: {
      styles: (theme) => ({
        root: {
          borderRadius: theme.radius.xl,
        },
      }),
    },
    InputWrapper: {
      styles: {
        description: {
          paddingTop: 2,
          paddingBottom: 6,
        },
      },
    },
  },
};
