import ReactGoogleAutocomplete from "react-google-autocomplete";

import { constants } from "src/lib/constants";

import "src/components/LocationAutocomplete/style.scss";
import { Box } from "@mantine/core";

interface LocationAutocompleteProps {
  value?: string;
  error?: string | boolean;
  disabled?: boolean;
  onChange: (places: google.maps.places.PlaceResult) => void;
}

export const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({
  value,
  error,
  disabled,
  onChange,
}) => (
  <Box
    className={
      "LocationAutocomplete" +
      (disabled ? " disabled" : "") +
      (error ? " error" : "")
    }
  >
    <ReactGoogleAutocomplete
      defaultValue={value}
      apiKey={constants.GOOGLE_MAPS_API_KEY}
      onPlaceSelected={onChange}
      options={{
        types: [],
      }}
    />
  </Box>
);
