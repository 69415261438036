import { RouteObject } from "react-router-dom";
import { AllocationPage } from "src/components/AllocationPage";
import { AppProvider } from "src/components/AppProvider";
import { AdminPage } from "src/components/admin/AdminPage";
import { AuthRequiredHoc } from "src/components/auth/AuthRequired";
import { OnboardingPage } from "src/components/onboarding/OnboardingPage";
import { AccountPage } from "src/components/pages/AccountPage";
import { ActionPage } from "src/components/pages/ActionPage";
import { DashboardPage } from "src/components/pages/DashboardPage";
import { MessagesPage } from "src/components/pages/MessagesPage";
import { ResetPasswordPage } from "src/components/pages/ResetPasswordPage";
import { SignInPage } from "src/components/pages/SignInPage";
import { SignOutPage } from "src/components/pages/SignOutPage";
import { SignUpPage } from "src/components/pages/SignUpPage";
import { Shell } from "src/components/shell/Shell";
import { TaskCreateWrapper } from "src/components/task/TaskCreateWrapper";
import { TaskPage } from "src/components/task/TaskPage";
import { TasksPage } from "src/components/task/TasksPage";
import { UserProfilePage } from "src/components/user/UserProfilePage";

export const routes: RouteObject[] = [
  {
    element: <AppProvider />,
    children: [
      {
        path: "/signup",
        element: <SignUpPage />,
      },
      {
        path: "/signin",
        element: <SignInPage />,
      },
      {
        path: "/reset-password",
        element: <ResetPasswordPage />,
      },
      {
        path: "/signout",
        element: <SignOutPage />,
      },
      {
        path: "/action",
        element: <ActionPage />,
      },

      {
        path: "/onboarding",
        element: AuthRequiredHoc(<OnboardingPage />),
      },
      {
        path: "/",
        element: <Shell />,
        children: [
          {
            path: "/dashboard",
            element: AuthRequiredHoc(<DashboardPage />),
          },
          {
            path: "/admin",
            element: AuthRequiredHoc(<AdminPage />),
          },
          {
            path: "/account",
            element: AuthRequiredHoc(<AccountPage />),
          },
          {
            path: "/",
            element: AuthRequiredHoc(<AccountPage />),
          },
          {
            path: "/tasks/create",
            element: AuthRequiredHoc(<TaskCreateWrapper />),
          },
          {
            path: "/messages",
            element: AuthRequiredHoc(<MessagesPage />),
          },
          {
            path: "/tasks",
            element: <TasksPage />,
          },
          {
            path: "/tasks/:id",
            element: <TaskPage />,
          },
          {
            path: "/profile/:id",
            element: <UserProfilePage />,
          },
          {
            path: "/impact",
            element: <AllocationPage />,
          },
        ],
      },
    ],
  },
];
