import { Anchor, Button, Loader, Stack, Tabs, Text } from "@mantine/core";
import { Task, getThreadId } from "@voluntasker/common";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { MessageBox } from "src/components/message/MessageBox";
import { TaskMessageThreads } from "src/components/task/TaskMessageThreads";
import { TaskOffersList } from "src/components/task/TaskOffersList";
import { auth } from "src/lib/firebase";

interface TaskSidebarProps {
  task: Task;
}

export const TaskSidebar: React.FC<TaskSidebarProps> = ({ task }) => {
  const [user, userLoading] = useAuthState(auth);

  if (userLoading) {
    return (
      <Stack align="center">
        <Loader />
      </Stack>
    );
  }
  return (
    <Tabs
      defaultValue="offers"
      h="100%"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Tabs.List mb="xl">
        <Tabs.Tab value="offers">Offers</Tabs.Tab>
        {/* <Tabs.Tab value="messages">Messages</Tabs.Tab> */}
      </Tabs.List>
      <Tabs.Panel value="messages" style={{ flex: 1 }}>
        {!userLoading && !user?.uid && (
          <Stack align="center" justify="center" h="100%">
            <Text size="xl" weight={500}>
              Sign in to leave a message
            </Text>
            <Anchor
              component={Link}
              to={`/signin?returnTo=${window.location.pathname}`}
            >
              <Button>Sign In</Button>
            </Anchor>
          </Stack>
        )}
        {task.id && user?.uid && task?.ownerId !== user?.uid && (
          <MessageBox
            taskId={task.id}
            threadId={getThreadId(task.id, user.uid)}
          />
        )}
        {task.id && user?.uid && task?.ownerId === user?.uid && (
          <TaskMessageThreads taskId={task.id} />
        )}
      </Tabs.Panel>
      <Tabs.Panel value="offers" style={{ flex: 1 }}>
        {!userLoading && !user?.uid && (
          <Stack align="center">
            <Text size="xl" weight={500}>
              Sign in to make an offer
            </Text>
            <Anchor
              component={Link}
              to={`/signin?returnTo=${window.location.pathname}`}
            >
              <Button>Sign In</Button>
            </Anchor>
          </Stack>
        )}
        {task.id && user?.uid && <TaskOffersList task={task} />}
      </Tabs.Panel>
    </Tabs>
  );
};
