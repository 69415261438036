import { Loader, Select, SelectProps } from "@mantine/core";
import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { FaMapMarkerAlt } from "react-icons/fa";
import { firestore } from "src/lib/firebase";

interface LocationData {
  [key: string]: boolean;
}

const prepareLocationData = (data?: LocationData) => {
  // data keys look like AU|Brisbane|Springwood, i need to create an array of these data with sub arrays when the key is split on |
  let returnData = [];
  returnData.push({
    value: "all",
    label: "All",
  });
  const locs = Object.keys(data || {});
  const locData = locs.map((loc) => {
    const parts = loc.split("|");
    const state = parts[0];
    const city = parts[1];
    if (city) {
      return {
        value: city,
        label: `${state} - ${city}`,
      };
    } else {
      return {
        value: state,
        label: state,
      };
    }
  });
  return [...returnData, ...locData];
};

export const TaskFilterSelect: React.FC<Omit<SelectProps, "data">> = (
  props
) => {
  const locationRef = doc(firestore, "locations/AU");
  const [locationData, locationDataLoading] = useDocumentDataOnce(locationRef);
  return (
    <Select
      disabled={locationDataLoading}
      icon={locationDataLoading ? <Loader size={16} /> : <FaMapMarkerAlt />}
      defaultValue="all"
      {...props}
      data={prepareLocationData(locationData)}
    />
  );
};
