import { AdminCompanyUserModal } from "src/components/admin/AdminCompanyUserModal";
import { AdminDisputeResolveModal } from "src/components/admin/AdminDisputeResolveModal";
import { CocModal } from "src/components/auth/CocModal";
import { TermsModal } from "src/components/auth/TermsModal";
import { MessageModal } from "src/components/message/MessageModal";
import { OnboardingVolunteerModal } from "src/components/onboarding/OnboardingVolunteerModal";
import { TaskAcceptOfferModal } from "src/components/task/TaskAcceptOfferModal";
import { TaskCancelConfirmModal } from "src/components/task/TaskCancelConfirmModal";
import { TaskCancelModal } from "src/components/task/TaskCancelModal";
import { TaskDeleteModal } from "src/components/task/TaskDeleteModal";
import { TaskImageGalleryModal } from "src/components/task/TaskImageGalleryModal";
import { TaskOfferModal } from "src/components/task/TaskOfferModal";
import { TaskReleasePaymentModal } from "src/components/task/TaskReleasePaymentModal";
import { TaskRequestPaymentModal } from "src/components/task/TaskRequestPaymentModal";
import { UserVerifyModal } from "src/components/user/UserVerifyModal";

export const ModalContainer: React.FC = () => (
  <>
    <OnboardingVolunteerModal />
    <UserVerifyModal />
    <TaskImageGalleryModal />
    <TaskOfferModal />
    <TaskAcceptOfferModal />
    <TaskCancelModal />
    <TaskCancelConfirmModal />
    <TaskRequestPaymentModal />
    <TaskReleasePaymentModal />
    <TaskDeleteModal />
    <MessageModal />
    <AdminDisputeResolveModal />
    <CocModal />
    <TermsModal />
    <AdminCompanyUserModal />
  </>
);
