import { Card, Group, Stack, Text } from "@mantine/core";
import { UserPaymentMethod } from "@voluntasker/common";
import {
  FaCcAmex,
  FaCcMastercard,
  FaCcStripe,
  FaCcVisa,
  FaCheckCircle,
} from "react-icons/fa";

interface UserPaymentMethodProps {
  selectable?: boolean;
  selected?: boolean;
  userPaymentMethod: UserPaymentMethod;
  onClick?: (paymentMethodId: string) => void | Promise<void>;
}

export const UserPaymentMethodCard: React.FC<UserPaymentMethodProps> = ({
  selectable,
  selected,
  userPaymentMethod,
  onClick,
}) => {
  return (
    <Card
      withBorder
      p="sm"
      sx={(style) => ({
        borderColor: selected
          ? `${style.colors.teal[5]} !important`
          : undefined,
        "&:hover": {
          backgroundColor: selectable ? "#f3f3f3" : undefined,
          cursor: selectable ? "pointer" : "default",
        },
      })}
      onClick={() => onClick?.(userPaymentMethod.paymentMethodId)}
    >
      <Group position="apart">
        <Group>
          {userPaymentMethod.brand === "visa" ? (
            <FaCcVisa size={32} />
          ) : userPaymentMethod.brand === "mastercard" ? (
            <FaCcMastercard size={32} />
          ) : userPaymentMethod.brand === "amex" ? (
            <FaCcAmex size={32} />
          ) : (
            <FaCcStripe size={32} />
          )}

          <Stack spacing={0}>
            <Text size="sm" color="dimmed">
              XXXX-XXXX-XXXX-{userPaymentMethod.last4}
            </Text>
          </Stack>
        </Group>
        {selected && (
          <Text color="teal" lh={0}>
            <FaCheckCircle size={24} />
          </Text>
        )}
      </Group>
    </Card>
  );
};
