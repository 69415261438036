import {
  Box,
  Button,
  Card,
  Grid,
  Loader,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { Thread } from "@voluntasker/common";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { MessageBox } from "src/components/message/MessageBox";
import { ThreadListItem } from "src/components/message/ThreadListItem";
import { auth, firestore } from "src/lib/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { MessageTaskDetails } from "src/components/message/MessageTaskDetails";
import { FaChevronLeft, FaSadCry } from "react-icons/fa";
import { useBreakpoint } from "src/hooks/useBreakpoint";

export const MessagesPage: React.FC = () => {
  const [user] = useAuthState(auth);

  const [selectedThread, setSelectedThread] = useState<Thread>();

  const breakpoint = useBreakpoint();

  const isMobile = useMemo(() => {
    return breakpoint === "xs" || breakpoint === "sm";
  }, [breakpoint]);

  const threadMessagesRef = query(
    collection(firestore, `/threads`),
    where("userIds", "array-contains", user?.uid),
    orderBy("createdOn", "desc")
  );
  const [threads, threadsLoading] = useCollection(threadMessagesRef);

  useEffect(() => {
    if (!isMobile && threads?.docs?.length && !selectedThread) {
      setSelectedThread(threads.docs[0].data() as Thread);
    }
  }, [isMobile, threads]);

  return (
    <Grid
      h={isMobile ? undefined : "calc(100dvh - 120px)"}
      style={{ overflow: "hidden" }}
    >
      <Grid.Col
        md={4}
        h={isMobile ? "calc(100dvh - 120px)" : "100%"}
        display={isMobile && selectedThread ? "none" : undefined}
        style={{ overflow: "hidden", transition: "100ms linear" }}
      >
        <Card
          withBorder
          h={"100%"}
          display="flex"
          style={{ flexDirection: "column", overflow: "hidden" }}
        >
          <Text size="xl" weight="bold">
            Task Messages
          </Text>
          <Stack mt="md" style={{ flex: 1, overflow: "hidden" }}>
            {threadsLoading ? (
              <Loader />
            ) : threads?.docs?.length ? (
              <ScrollArea h="100%">
                <Card.Section>
                  {threads.docs.map((thread) => (
                    <ThreadListItem
                      key={thread.id}
                      thread={thread.data() as Thread}
                      setSelectedThread={setSelectedThread}
                      isSelected={selectedThread?.id === thread.id}
                    />
                  ))}
                </Card.Section>
              </ScrollArea>
            ) : (
              "No threads"
            )}
          </Stack>
        </Card>
      </Grid.Col>

      <Grid.Col
        md={8}
        h={isMobile ? "calc(100dvh - 120px)" : "100%"}
        style={{ flexDirection: "column", overflowY: "hidden" }}
        display={isMobile && !selectedThread ? "none" : "flex"}
      >
        {isMobile && (
          <Box>
            <Button
              mb="xs"
              variant="subtle"
              leftIcon={<FaChevronLeft />}
              onClick={() => setSelectedThread(undefined)}
            >
              Back to messages
            </Button>
          </Box>
        )}
        <Card withBorder style={{ flex: 1 }}>
          {selectedThread?.id ? (
            <Stack h="100%" sx={{ overflow: "hidden" }}>
              <MessageTaskDetails thread={selectedThread} />
              <MessageBox threadId={selectedThread?.id} />
            </Stack>
          ) : threadsLoading ? (
            <Loader />
          ) : !threads?.docs?.length ? (
            <Stack align="center" justify="center">
              <FaSadCry size={32} />
              <Text>No Messages </Text>
            </Stack>
          ) : (
            <></>
          )}
        </Card>
      </Grid.Col>
    </Grid>
  );
};
